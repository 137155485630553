import React from "react";
import { Col, Row } from "reactstrap";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";

const AdvisorsPersonSlider = (props) => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={4} className="pb-5 ">
        <Row className="mx-0 pt-3">
          <Col xs={12} sm={12} md={12} lg={12} xl={4}>
            <Row className="mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="px-0 pt-4" >
                <div className="team-img-container img">
                  {props.item.link ?
                    <a href={`${props.item.link}`} rel="noreferrer" target="_blank" className="team-img-container ">
                      <img src={`${ApiConfig.url}${props.item.photoURL}`} alt="" />
                      <div
                        className=""
                        style={{ border: `15px solid ${props.item.firstClassName}` }}
                      ></div>
                      <div
                        className="second-circle-container"
                        style={{
                          border: `15px solid ${props.item.secondClassName}`,
                        }}
                      ></div>
                    </a>
                    : <div className="team-img-container"> 
                    <a href={`${props.item.link}`} rel="noreferrer" target="_blank" className="team-img-container">

                    <img src={`${ApiConfig.url}${props.item.photoURL}`} alt="" />
                      <div
                        className=""
                        style={{ border: `15px solid ${props.item.firstClassName}` }}
                      ></div>
                      <div
                        className=""
                        style={{
                          border: `15px solid ${props.item.secondClassName}`,
                        }}
                      ></div>
                      </a></div>}
                </div>
               
                <div className="pt-4 team-img-container">
                  <div>
                  <a href={`${props.item.link}`} rel="noreferrer" target="_blank" className="popup-advisor-team-img-container title-black">
                    <h6 className="p-large jostBold mb-5 font-size-20px">
                      {props.item.fullName}
                    </h6>
                    </a>
                   
                     
                  </div>
                </div>
               
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AdvisorsPersonSlider;