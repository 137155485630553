import React from "react";
import PropTypes from "prop-types";

function Loader(props) {
    return (
        <div className={`${props.containerClassName}`}>
            <div className={props.className}></div>
        </div>
    );
}

Loader.propTypes = {
    className: PropTypes.string,
    containerClassName: PropTypes.string,
};

Loader.defaultProps = {
    className: "loader",
    containerClassName: 'circle-loader'
};

export default Loader;
