import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  useWindowSize
} from '@react-hook/window-size'
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import Strip from "../../customComponents/Strip/Strip";
import PersonSlider from "../../customComponents/PersonSlider/PersonSlider";
import AdvisorsPersonSlider from "../../customComponents/PersonSlider/AdvisorsPersonSlider";
import application_contants from "../../contants/application_contants";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import SEOComponent from "../../customComponents/SEO/SEOComponent";
import ExternalLayout from "../../customComponents/ExternalLayout/ExternalLayout";
import { usePeople } from "../../context/PeopleProvider";
import { alertDangerConfirmMessage } from "../../customComponents/ConfirmationMessage/ConfirmationMessage";
import debugLogger from "../../Utils/debugLogger";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TeamMembers = (props) => {
  console.log(props)
  const [peopleState, { getAllPeopleList }] = usePeople();
  const [width] = useWindowSize();
  const [advisorTeam, setAdvisorTeam] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const [postdoctoralTeam, setPostDoctoralTeam] = useState([]);
  const [researchTeam, setReSearchTeam] = useState([]);
  const [managerTeam, setManagerTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPeopleList();
  }, [])

  React.useEffect(() => {
    if (peopleState) {
      let advisorData = [];
      let teamMemberData = [];
      // let postdoctoralData =[];
      // let researchData = [];
      // let managerData = [];
      peopleState.map((item) => {
        if (item.typeId === 1 && item.status === 1) {
          advisorData.push(item)
        } else if (item.typeId === 2 && item.status === 1) {
          teamMemberData.push(item)
        }
        // else if(item.designationId === 3 && item.status === 1){
        //   postdoctoralData.push(item)
        // }else if(item.designationId === 4 && item.status === 1){
        //   researchData.push(item)
        // }else if(item.designationId === 5 && item.status === 1){
        //   managerData.push(item)
        // }
      });
      setAdvisorTeam([...advisorData]);
      setTeamMember([...teamMemberData]);
      // setPostDoctoralTeam([...postdoctoralData]);
      // setReSearchTeam([...researchData]);
      // setManagerTeam([...managerData])
    }
  }, [peopleState]);

 

  const getPeopleList = async () => {
    setIsLoading(true);
    try {
      await getAllPeopleList();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alertDangerConfirmMessage('Error', error);
    }
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      width >= 1025
        ? 4 : width >= 1024 && width <= 1024 ? 3
          : width >= 768 && width <= 1023
            ? 2
            : 1,
    slidesToScroll: 1,
  };

  const settings1 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      width >= 1025
        ? 1 : width >= 1024 && width <= 1024 ? 1
          : width >= 768 && width <= 1024
            ? teamMember.length >= 2 ? 2 : 1
            : 1,
    slidesToScroll: 1,
  };

  const settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      width >= 1025
        ? 1 : width >= 1024 && width <= 1024 ? 1
          : width >= 768 && width <= 1024
            ? postdoctoralTeam.length >= 2 ? 2 : 1
            : 1,
    slidesToScroll: 1,
  };

  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      width >= 1025
        ? 1 : width >= 1024 && width <= 1024 ? 1
          : width >= 768 && width <= 1024
            ? researchTeam.length >= 2 ? 2 : 1
            : 1,
    slidesToScroll: 1,
  };

  const settings4 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      width >= 1025
        ? 1 : width >= 1024 && width <= 1024 ? 1
          : width >= 768 && width <= 1024
            ? managerTeam.length >= 2 ? 2 : 1
            : 1,
    slidesToScroll: 1,
  };
  const clickTest = (item) => {
    console.log('clickTest clicked',item);
    props.history.push('/peopleDetails',{item});
  }

  return (
    <ExternalLayout>
      <RenderPage
        id="tst-landing"
        className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
        containerType="container-fluid"
      >
        <Strip
          id="tst-team"
          className="strip strip-no-padding"
          containerType="container"
        >
          <SEOComponent
            title="TeamMembers"
            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
            description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
            siteUrl="/TeamMembers" />

          <Row className="pb-5 mx-0 pt-5">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="pt-3">
              <Row>
                {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">People</h5> :
                    <h1 className="h1-small section-title align-left">People</h1>}
                </Col> */}
                <Col
                  xs={12} sm={12} md={12} lg={12} xl={12}
                  className="m-auto "
                  id= "teamMember"
                >
                  {teamMember.length > 0 ?
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-4" >
                        {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Team Members</h6> :
                          <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left" 
                          >Team Members</h3>}
                      </Col>
                    </Row> : 
                    <>
                    <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-4" >
                      {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Team Members</h6> :
                        <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left" 
                        >Team Members</h3>}
                    </Col>
                  </Row>
                    <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <h2 className="h5-small section-title align-left pt-4 title-navy-blue-medium">Coming soon ....</h2>
                    </Col> 
                </Row>
                </>}
                  {width <= application_contants.MOBILE_BREAKPOINT ?
                    <Slider {...settings1}>
                      {teamMember.map((item, index) => (
                        <div xs={12} sm={12} md={12} lg={12} xl={12} key={index} className="team">
                          <PersonSlider item={item} clickTest={clickTest} isShow={true}/>
                        </div>
                      ))}
                    </Slider> :
                    <Row>
                      {teamMember.map((item, index) => (
                        <Col xs={12} sm={12} md={12} lg={3} xl={3} key={index} className="team">
                          <PersonSlider item={item} clickTest={clickTest} isShow={true}/>
                        </Col>
                      ))}
                    </Row>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Strip>

      </RenderPage>
    </ExternalLayout>
  );
};


TeamMembers.defaultProps = {
  isClassName: true,
};

TeamMembers.propTypes = {
  isClassName: PropTypes.bool,
};

export default TeamMembers;