import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponents/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponents/SEO/SEOComponent";
import PropTypes from "prop-types";


// import Male Audio Samples
import audiom1 from "../../../assets/sampleDatasets/ChhattMale/Chhatt_book_m.wav";
import audiom2 from "../../../assets/sampleDatasets/ChhattMale/Chhatt_health_m.wav";
import audiom3 from "../../../assets/sampleDatasets/ChhattMale/Chhatt_fina_m.wav";


function Chhatt_Male(){
    return( 
    <div className="container">
        <table>
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenRow"><ReactPlayer   
                        url={audiom1}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenRow"><p className="p-medium-line-spacing "> रमेसर के मेहनत ह सफल होइस, अभी रमेसर ह सहर के मूनिसपल ऑपिस म बड़े बाबू हवय </p></td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddRow"><ReactPlayer   
                        url={audiom2}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddRow"><p className="p-medium-line-spacing">पी.सी.आई ह दू हजार आठ म डॉक्टर ऑफ फार्मेसी पाठ्यक्रम ला भारत म सुरू करे रिहिस</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                    <td className="tableEvenRow"><ReactPlayer   
                        url={audiom3}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenRow"><p className="p-medium-line-spacing">राष्ट्रीय बीमा के भुगतान ह श्रमिक अउ ओखर परवार मन बर बिकट बड़का सहारा बनथे</p></td>
            </tr>
            </tbody>
        </table>


    </div>

    );
}

const Sample_Chhatt_Male = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            > 
                <SEOComponent
                    title="SampleChhattMale"
                    keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/SampleChhattMale" />
                   
                    <Strip
                        id="tst-SampleDatasets"
                        className="strip strip-no-padding px-20"
                        containerType="container"
                    >
                   

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Chhatt Male Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Chhatt_Male />
                            </div>
                    </div>

</Strip>
</RenderPage>
</ExternalLayout>
);
};

export default Sample_Chhatt_Male;