import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ReseachAreaService = {
    getResearchAreaList: async () => {
        const { baseUrl, getResearchAreaList } = ApiConfig;

        const url = baseUrl + getResearchAreaList;
        console.log('url');
        console.log(url)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const researchAreaServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_opening",
            true
        );
        console.log("researchAreaServicePromise",researchAreaServicePromise)
        return researchAreaServicePromise;
    },

    getResearchAreasList: async () => {
        const { baseUrl, getResearchAreasList } = ApiConfig;
        const url = baseUrl + getResearchAreasList;
        console.log(getResearchAreasList)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const researchAreasPromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_openings",
            true
        );
        return researchAreasPromise;
    },
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateResearchAreas } = ApiConfig;
        console.log("insertOrUpdateResearchAreas",insertOrUpdateResearchAreas)
        const url = baseUrl + insertOrUpdateResearchAreas;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        console.log("url",url)
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            reseachTitle: data.reseachTitle,
            title: data.title,
            description: data.description,
            pointersDescription: data.pointersDescription,
            pointersPoints: data.pointersPoints,
            publications: data.publications,
            downloadLinks: data.downloadLinks,
            galleryList: data.galleryList,
        });

        const openingServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_openingService",
            true
        );
        return openingServicePromise;
    },
    enableDisableResearchAreas: async (data) => {
        const { baseUrl, enableDisableResearchAreas } = ApiConfig;
        const url = baseUrl + enableDisableResearchAreas;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableResearchAreasServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableResearchAreas",
            true
        );
        return enableDisableResearchAreasServicePromise;
    },
}

export default ReseachAreaService;