import React, { useState, useEffect } from 'react';
import {makeAllApiRequests}  from './URLLoader';
import {makeVideoRequests}  from './roughvideoload';
import callVideoApi from './ToolsVideoURL';
import loadingGif from '../../../../assets/logo-loading.gif';
import syspingif from '../../../../assets/syspingif.gif';
import syspinlogo from '../../../../assets/Menulogo.png';
import { useWindowSize } from '@react-hook/window-size';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../../../customComponents/RenderPage/RenderPage';
import Strip from '../../../../customComponents/Strip/Strip';
import application_contants from '../../../../contants/application_contants';
import SEOComponent from '../../../../customComponents/SEO/SEOComponent';

const LoadingModal = () => (
  <div className="loading-overlay">
  <div className="loading-modal">
    <div >
      <img className="loading-indicator" src={loadingGif} alt="Loading..." />
      <br/>
      <div className="loading-text">Loading ...</div>
    </div>
  </div>
  </div>
);


const LoadingMod = () => (
  <div className="loading-ovly">
  <div className="loading-mod">
    <div className="loading-content">
      <div className="loading-text h5-medium section-title jostRegular title-navy-blue">Welcome to the SYSPIN Demo </div>
      <div className="loading-text h5-medium section-title jostRegular title-navy-blue">of TTS in Indian Languages</div>
      <img className="loading-indi" src={syspingif} alt="Loading..." />
    </div>
    <br />
    <div className="loading-content">
    <img className="loading-indi loading-indilogo" src={syspinlogo} alt="Loading..."/>
    <br/>
    <div className="loading-text jostRegular title-navy-blue">Kindly wait while the models are loading...</div>
    </div>
  </div>
  </div>
);

const Videorequests = (props) => {
  const [width] = useWindowSize();

  React.useEffect(() => {
      window.scrollTo(0, 0);
  }, []);
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedWordBox, setSelectedWordBox] = useState('');
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('');
    const [text, setText] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [loadingCount, setLoadingCount] = useState(0);
  
    const radioOptions = {
      bnmal: ['vits'],
      bnfem: ['vits'],
      bhmal: ['BB1', 'BB2', 'BB3'],
      bhfem: ['BF1', 'BF2', 'BF3'],
      chmal: ['vits'],
      chfem: ['vits'],
      himal: ['vits'],
      hifem: ['vits', 'glowtts'],
      mgmal: ['AB1', 'AB2', 'AB3'],
      mgfem: ['AF1', 'AF2', 'AF3'],
      mrmal: ['vits', 'glowtts'],
      mrfem: ['vits', 'glowtts'],
      mtmal: ['CB1', 'CB2', 'CB3'],
      mtfem: ['CA1', 'CA2', 'CA3'],
      knmal: ['vits'],
      knfem: ['vits'],
      temal: ['vits', 'glowtts'],
      tefem: ['vits', 'glowtts'],
    };


    const handleLoadingChange = () => {
      setLoadingCount((prevCount) => prevCount + 1);
    };
  
    const renderLoadingComponent = () => {
      if (loadingCount === 0) {
        return <LoadingMod />;
      } else {
        return <LoadingModal />;
      }
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          //await makeAllVideoRequests(); // Assuming makeAllApiRequests is an asynchronous function

          await makeAllApiRequests();
           // Use Promise.all to wait for both asynchronous operations to complete
          await Promise.all([makeVideoRequests()]);
          await Promise.all([makeVideoRequests()]);
          setLoading(false);
          setShowForm(true);
          console.log('showForm after setting:', showForm);
          handleLoadingChange();
          
          
          // Assuming you set the selectedWordBox and selectedGender from some source
          // setSelectedWordBox('bn'); // Replace with actual value
          // setSelectedGender('mal'); // Replace with actual value
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData(); // Call the fetchData function  
      // Scroll to the top when the component mounts
      window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
      console.log('showForm:', showForm);
      if (showForm) {
        window.scrollTo(0, 0);
      }
    }, [showForm]);
    
    const generateRadioButtons = () => {
      const key = `${selectedWordBox}${selectedGender}`;
      const options = radioOptions[key];
  
      if (options && options.length > 0) {
        return (
          <div className="model-buttons-row">
            {options.map((option, index) => (
              <div key={index} className="model-button">
                <input
                  type="radio"
                  name="radioOptions"
                  value={option}
                  checked={selectedRadio === option}
                  onChange={() => setSelectedRadio(option)}
                />
                <label className="selctfield">{option}</label>
              </div>
            ))}
          </div>
        );
      } else {
        return <p>Please choose Language and Gender.</p>;
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const selectedTab = selectedWordBox;
      const selectedCheckbox = selectedGender;

    try {
      setLoading(true);
      const data = await callVideoApi(selectedTab, selectedCheckbox, selectedRadio, text,setLoading, setVideoUrl, setModalVisible);
      const videoUrl = data.videoUrl;
      setVideoUrl(videoUrl);
      setModalVisible(true);
    } catch (error) {
      console.error('Error generating audio:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setVideoUrl(null);
    // Refresh and reload the page
  // window.location.reload();
  setSelectedWordBox('');
  setSelectedGender('');
  setSelectedRadio(''); // Adjust this based on your actual state
  setText('');
  };

  return (
<ExternalLayout>
    <RenderPage
        id="tst-Partners"
        className="strip render-page px-0"
        containerType="container-fluid"
    >
     <SEOComponent
            title="AudioGenerator"
            keywords="SYnthesizing SPeech in INdian languages"
            description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
            siteUrl="/AudioGenerator" />
        <Strip
            id="tst-strip-funding"
            className="strip strip-no-padding px-0"
            containerType="container"
        >
       
            <Row className="mx-0  pt-5">  
                 {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left"> TTS Tools</h5> :
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> TTS Tools</h5>}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
     
    <>
    {loading && renderLoadingComponent() }
    {showForm && (
      <div className="form-container">
        <div className="form-box">
          <form className="form-str" id="customForm" onSubmit={handleSubmit} >
              <div>
                <fieldset>
                  <legend htmlFor="wordBox">Choose a Language:</legend>
                  <select className="selctfield"  id="wordBox" name="wordBox" value={selectedWordBox}
                onChange={(e) => setSelectedWordBox(e.target.value)}>
                      <option className="selctfield" value="...">Select Here ... </option>
                      <option className="selctfield" value='bn'>Bengali</option>
                      <option className="selctfield" value='ch'>Chhattisgarhi</option>
                      <option className="selctfield" value='hi'>Hindi</option>
                      <option className="selctfield" value='mr'>Marathi</option>
                      <option className="selctfield" value='kn'>Kannada</option>
                      <option className="selctfield" value='te'>Telugu</option>
                      {/* <option value="bh">Bhojpuri</option> 
                      <option value="mt">Maithili</option> 
                      <option value="mg">Magadhi</option>  */}
                  </select>
                </fieldset>
              </div>
              <div>
                <fieldset>
                <legend htmlFor="gender">Choose a Gender:</legend>
                  <input type="radio" id="male" name="gender" value="mal" checked={selectedGender === 'mal'} onChange={() => setSelectedGender('mal')} />
                    <label className="selctfield" htmlFor="male">Male</label>
                  <input type="radio" id="female" name="gender" value="fem" checked={selectedGender === 'fem'} onChange={() => setSelectedGender('fem')} />
                    <label className="selctfield" htmlFor="female">Female</label>
                </fieldset>
              </div>
            <fieldset id="radioButtons" className={loading ? 'hidden' : ''}>
            <legend htmlFor="models">Choose a Model:</legend>
            {generateRadioButtons()}
          </fieldset>

          {/* <div>
            <input className="textarea_box" type="text" id="text" name="text" placeholder="Enter your text here..." />
          </div> */}
          <div>
            <input className="textarea_box" type="text" id="text" name="text" value={text}  onChange={(e) => setText(e.target.value)} placeholder="Enter your text here..." />
          </div>

          <div>
          <button className="submit_button" type="submit">Generate Video</button>
          
          </div>
            </form>
{/*             
          <p id="print">{`${selectedWordBox}${selectedGender} ${selectedRadio} ${text}`}</p> */}
          
          {videoUrl && modalVisible && (
          <>
          <div className={`modal-background ${modalVisible ? 'visible' : ''}`} onClick={closeModal}></div>
          <div className="audio-player-modal">
            <video controls autoPlay>
              <source src={videoUrl} type="video/ogg" />
            </video>
            <button onClick={closeModal}>Close (X)</button>
          </div>
          </>
        )}
        </div>
      </div>
    )}
</>

</Col>     
    </Row>
    
    </Strip>
    </RenderPage>
    </ExternalLayout>
    )
    };
    

export default Videorequests;
