import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from "../../contants/application_contants";
import { useWindowSize } from "@react-hook/window-size";
import OpeningCardData from '../../Utils/json/OpeningCardData';
import OpeningService from '../../../api/services/OpeningService';
import { FiberManualRecord } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import debugLogger from '../../Utils/debugLogger'


const OpeningsDetail = (props) => {
    const [width] = useWindowSize();
    // const {card} = props.location.state;
    const [sectionTitle, setSectionTitle] = useState('');
    const [openingTitle, setOpeningTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState('');
    const [detailtitle, setDetailTitle] = useState('');
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('');



    React.useEffect(() => {
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[2]);
        getList();
    }, []);

    

    const getList = async () => {
        let it = [];
        await OpeningService.getOpeningList()
            .then(async (res) => {
                console.log(res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    result.data.list.map((item) => {
                                        if (item.openingTitle.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
                                            it.push(item);
                                            setTitle(item.title)
                                            setOpeningTitle(item.openingTitle)
                                            setCode(item.code)
                                        }
                                    });
                                    debugLogger(it);
                                    debugLogger(result.data);
                                    setData([...it])
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
   

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Openings"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-strip-Openings"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
                    <Row className="mx-0 pt-5 ">
                        <Col xs={12} ms={12} md={12} lg={11} xl={11} className="pt-5">
                            <p className="mb-0 section-title align-left">
                                <Link to={`/openings`} className="p-large custom-link-item jostRegular ">{`Openings >`}</Link>
                                <span className=" pl-2 p-large title-black jostRegular">{`${openingTitle}`} </span>
                            </p>
                        </Col>
                        <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                            {data.length > 0 && data.map((item, index) => (
                                <div key={index}>
                                    <Row className="pb-3">
                                        <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                            <h5 className="h5-medium section-title jostRegular title-black  pt-5 align-left">{item.title}</h5>
                                            <p className="h6-medium section-title jostRegular title-dark-light-grey pt-2 align-left">Position code- {item.code}</p>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Col>
                        <Col xs={12} ms={12} md={12} lg={4} xl={4} className="pt-4">
                            {data.length > 0 && data.map((it, index) => (
                                <Row className="opening-info-container mx-0" key={index}>
                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                        <p className="p-Large mb-2 section-title title-black jostRegular align-left fw-600">Duration</p>
                                        <p className="section-title title-black nunitosansLight align-left">{it.duration}</p>
                                    </Col>
                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-4">
                                        <p className="p-Large mb-2 section-title title-black jostRegular align-left fw-600">Start date</p>
                                        <p className="mb-0 section-title title-black nunitosansLight align-left">{it.startDate}</p>
                                    </Col>
                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-4">
                                        <p className="p-Large mb-2 section-title title-black jostRegular align-left fw-600">Net Salary (per month)</p>
                                        <p className="mb-0 section-title title-black nunitosansLight align-left">{it.netSalary}</p>
                                    </Col>
                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-4">
                                        <p className="p-Large mb-2 section-title title-black jostRegular align-left fw-600">Application procedure</p>
                                        <p className="mb-0 section-title title-black nunitosansLight align-left">{it.preferredDescription}</p>
                                        <a href={`${it.applicationLink}`} target="_blank" rel="noreferrer" className="p-large section-title jostRegular custom-reference-links">{it.applicationLink}</a>
                                        {/* <p className="p-Large section-title title-black jostRegular custom-reference-links align-left">{it.applicationLink}</p> */}
                                    </Col>
                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-4">
                                        <p className="p-Large mb-2 section-title title-black jostRegular align-left fw-600">Contact</p>
                                        <p className="mb-0 section-title title-black nunitosansLight align-left">{it.contactName}</p>
                                        <p className="p-Large section-title title-black jostRegular  align-left">{it.contactEmail}</p>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col xs={12} ms={12} md={12} lg={8} xl={8} className="pt-4">
                            <Row>
                                <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                    {data.length > 0 && data.map((item, index) => (
                                        <div key={index}>
                                            <Row className="pb-5">
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                    <h5 className="h6-medium mb-3 section-title title-black jostBold align-left">Project Details</h5>
                                                    <p className="mb-0 p-large section-title title-black jostLight align-left">{item.projectDetail}</p>
                                                </Col>
                                            </Row>
                                            <Row className="pb-5">
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                    <h5 className="h6-medium mb-3 section-title title-black jostBold align-left">Job Description</h5>
                                                    <p className="mb-0 p-large section-title title-black jostLight align-left">{item.jobDescription}</p>
                                                </Col>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pt-4">
                                                        <JobDescription item={item} />
                                                    </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                    <Eligibility item={item} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                    <Qualifications item={item} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                    <Duration item={item} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default OpeningsDetail;

const JobDescription = (props) => {
    return (
        <div>
            {props.item && props.item.rolePoints ?
                <Row className="mx-0">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="px-0"></Col>
                    <Row>
                        {props.item.rolePoints.split(/[\n\r]/g).map((infoItem, index) => (
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                <div className="d-flex">
                                    <FiberManualRecord className="dot-icon" />
                                    <div>
                                        <p className="mb-0 p-large section-title title-black jostLight align-left">{infoItem}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row> : null}
        </div>

    )
}

const Eligibility = (props) => {
    return (
        <div>
            {props.item && props.item.essentialPoints ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h6-medium section-title jostBold title-black fw-600 align-left pb-1">Eligibility</h5></Col>
                    {props.item.essentialPoints.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 p-large section-title title-black jostLight align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}

const Qualifications = (props) => {
    return (
        <div>
            {props.item && props.item.preferredPoints ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h6-medium section-title title-black jostBold fw-600 align-left pb-1">Qualification</h5></Col>
                    {props.item.preferredPoints.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 p-large section-title title-black jostLight align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                : null}
        </div>
    )
}

const Duration = (props) => {
    return (
        <div>
            {props.item && props.item.essentialDescription ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h6-medium section-title jostBold title-black fw-600 align-left pb-1">Duration</h5></Col>
                    {props.item.essentialDescription.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 p-large section-title title-black jostLight align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                : null}
        </div>
    )
}