import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const BannerService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateBanner } = ApiConfig;

        const url = baseUrl + insertOrUpdateBanner;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id : data.id,
            imgPath : `${data.imgPath}`,
            title: `${data.title}`,
            welcomeTitle:`${data.welcomeTitle}`,
            description: `${data.description}`,
        });

        const bannerServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_bannerService",
            true
        );
        return bannerServicePromise;
    },

    getBannerList: async () => {
        const { baseUrl, getBannerList } = ApiConfig;

        const url = baseUrl + getBannerList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const bannerServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_Banner",
            true
        );
        return bannerServicePromise;
    },

    enableDisableBanner: async (data) => {
        const { baseUrl, enableDisableBanner } = ApiConfig;

        const url = baseUrl + enableDisableBanner;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id : data.id,
            status : `${data.status}`,
        });

        const enableDisableBannerServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableBanner",
            true
        );
        return enableDisableBannerServicePromise;
    },

};

export default BannerService;
