import React from 'react';
import { useWindowWidth } from "@react-hook/window-size";
import { Col, Row } from 'reactstrap';
import application_contants from '../../contants/application_contants';
import ContactUsForm from '../../customComponents/ContactUsForm/ContactUsForm';
import ContactUsMap from '../../customComponents/ContactUsMap/ContactUsMap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import SEOComponent from "../../customComponents/SEO/SEOComponent";


const Contact = () => {
    const width = useWindowWidth()
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Contact"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Contact"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/contact" />

                <Strip
                    id="tst-strip-contact"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
                    <Row className="mx-0 pt-5 pb-5">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-4">
                        {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Contact Us</h6> :
                          <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left" 
                          >Contact Us</h3>}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row className="mobile-no-margin">
                                <Col xs={12} sm={12} md={12} lg={7} xl={7} className="pb-5 mobile-no-padding">
                                    <Row className="mobile-no-margin">
                                        <Col xs={12} sm={12} md={12} lg={11} xl={11} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'mobile-no-padding' : 'mr-auto'}`}>
                                            <div className="map-container">
                                             {/*    <ContactUsMap /> */}
                                             <a href="https://goo.gl/maps/Xx7ZSdG2u8Brjx5R6" target='_blank'><img src={application_contants.spiremap} alt="" /></a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-3">
                                            <div className="d-flex align-items-center">
                                                <div><img src={application_contants.EMAILIMG} alt="" className="contact-icon-email---width" /></div>
                                                <div>
                                                    <p className="p-small section-title  pl-2 align-left jostLight">
                                                        contact.syspin@iisc.ac.in
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="d-flex align-items-start">
                                                <div><img src={application_contants.LOCATIONIMG} alt="" className="contact-icon--width" /></div>
                                                <div>
                                                    <p className="p-small section-title pl-2 align-left jostLight title-black lh-25px"> SPIRE LAB, Room No. EE C 326 <br /> Department of Electrical Engineering <br /> Indian Institute of Science <br />Bangalore-560012</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5} xl={5} className="mobile-no-padding">
                                    <ContactUsForm />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};

export default Contact;