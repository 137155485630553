export const makeAllApiRequests = () => {
    const apiRequests = [
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/ch-mal-vits-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/ch-fem-vits-audio' 
        },
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/hi-mal-vits-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/hi-fem-vits-audio' 
        },
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/hi-fem-glowtts-audio' 
        },
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-mal-vits-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-fem-vits-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-mal-glowtts-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-fem-glowtts-audio' 
        },
        { 
            text: "এটা কি ব্যাঙ্গালোরের দিকে যাওয়ার রাস্তা", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/bn-mal-vits-audio' 
        },            
        { 
            text: "এটা কি ব্যাঙ্গালোরের দিকে যাওয়ার রাস্তা", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/bn-fem-vits-audio' 
        },
        { 
            text: "ಇದು ಬೆಂಗಳೂರಿಗೆ ಹೋಗುವ ದಾರಿ", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/kn-mal-vits-audio' 
        },            
        { 
            text: "ಇದು ಬೆಂಗಳೂರಿಗೆ ಹೋಗುವ ದಾರಿ", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/kn-fem-vits-audio' 
        },
        { 
            text: "ఇది కారులో బెంగళూరు వైపు వెళ్లే మార్గం", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/te-mal-vits-audio' 
        },            
        { 
            text: "ఇది కారులో బెంగళూరు వైపు వెళ్లే మార్గం", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/te-fem-vits-audio' 
        },
        { 
            text: "ఇది కారులో బెంగళూరు వైపు వెళ్లే మార్గం", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/te-mal-glowtts-audio' 
        },            
        { 
            text: "ఇది కారులో బెంగళూరు వైపు వెళ్లే మార్గం", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/te-fem-glowtts-audio' 
        },

    ];
  
    const requests = apiRequests.map(({ url, text }) =>
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      })
        .then((response) => {
          if (response.ok) {
            console.log('Making API requests...');
            return response.json();
          } else {
            throw new Error(`Network response was not ok for URL: ${url}`);
          }
        })
        .then((data) => {
          // Handle the data returned from the API
          //console.log(data);
          // You can add code here to show the form or perform other actions.
        })
        .catch((error) => {
          console.error(`Error for URL ${url}:`, error);
          throw error;
        })
    );
  
    // Use Promise.all to execute all promises concurrently
    return Promise.all(requests);
  };

  export const makeMarathiAudiorequests = () => {
    const mrapiRequests = [                   
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-mal-vits-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-fem-vits-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-mal-glowtts-audio' 
        },            
        { 
            text: "क्या आप आज दोपहर का खाना बना सकती हैं और कुछ खाना खा सकती हैं", 
            url: 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/mr-fem-glowtts-audio' 
        },
        

    ];
  
    const requests = mrapiRequests.map(({ url, text }) =>
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      })
        .then((response) => {
          if (response.ok) {
            console.log('Making API requests...');
            return response.json();
          } else {
            throw new Error(`Network response was not ok for URL: ${url}`);
          }
        })
        .then((data) => {
          // Handle the data returned from the API
          //console.log(data);
          // You can add code here to show the form or perform other actions.
        })
        .catch((error) => {
          console.error(`Error for URL ${url}:`, error);
          throw error;
        })
    );
  
    // Use Promise.all to execute all promises concurrently
    return Promise.all(requests);
  };
  