import React, { useReducer,useContext, useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../../customComponents/ConfirmationMessage/ConfirmationMessage";
import Loader from "../../customComponents/Loader/Loader";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import { useAuth } from "../../context/AuthProvider";
import debugLogger from "../../Utils/debugLogger";
import UserService from '../../../api/services/UserService';



const Login = (props) => {
    const [, { login }] = useAuth();
    const [emailId, setEmailId] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailId = (event) => {
        setEmailId(event.target.value);
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    // const handleSubmit = async () => {
    //     let data = {
    //         emailId: emailId,
    //         password: password
    //     }
    //     setIsLoading(true);
    //     try {
    //         const res = await login(data);
    //         if (res) {
    //             debugLogger('login res', res);
    //             setIsLoading(false);
    //             props.history.push(`/opening-list`)
              
    //         } else {
    //             setIsLoading(false);
    //             alertDangerConfirmMessage(
    //                 `Invalid Credentials, Please try again`
    //             );
    //         }
    //     } catch (error) {
    //         setIsLoading(false);
    //         alertDangerConfirmMessage(
    //             error
    //         );
    //     }
    // };
    const handleSubmit = async () => {
        let data = {
            emailId: emailId,
            password: password,
        }
        console.log("Data",data)
        setIsLoading(true);
        try {
            const res = await login(data);
            if (res) {
                // console.log("ptops",props)
                debugLogger('login res', res);
                setIsLoading(false);
                props.history.push(`/opening-list`)
              
            } else {           
                setIsLoading(false);
                alertDangerConfirmMessage(
                    `Invalid Credentials, Please try again`
                );
                
            }
        } catch (err) {
            console.log("error")
            setIsLoading(false);
        }
        await UserService.login(data)
            .then(async (res) => {
                console.log("data",data)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log()
                            if (result.code === 0) {   
                                debugLogger('login res', res);
                                props.history.push(`/opening-list`)
                                alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);

                            } else if(result.code === 1) {
                                setIsLoading(false);
                                props.history.push(`/`)
                                alertDangerConfirmMessage(`${result.message}`);
                               
                            }
                            
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <RenderPage
            id="login-page"
            className="mt-0"
            containerType="container-fluid"
        >
            <Strip
                id="tst-login"
                className="strip strip-no-padding"
                containerType="container"
            >
                <Row className="d-flex align-items-center justify-content-center mx-0 full-height">
                    <Col xs={12} ms={12} md={12} lg={3} xl={3} className="box-shadow p-4">
                        <Row>
                            <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                <div className="logo-container pb-2"></div>
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                <h6 className="h6-medium section-title py-2 align-center">
                                    Syspin Speech Recognition
                                </h6>
                            </Col>
                        </Row>
                        <Form>
                            <Row>
                                <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                    <FormGroup>
                                        <Label className="common-label">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            value={emailId}
                                            className="common-form-control"
                                            placeholder="email"
                                            onChange={(event) => handleEmailId(event)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                    <FormGroup>
                                        <Label className="common-label">Password</Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            value={password}
                                            className="common-form-control"
                                            placeholder="password"
                                            onChange={(event) => handlePassword(event)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="mt-2">
                                    <Row>
                                        <Col xs={8} ms={8} md={6} lg={10} xl={10} className="m-auto">
                                            <Button
                                                className="custom-theme-btn btn-block custom-btn--small"
                                                onClick={() => handleSubmit()}
                                            >
                                                Submit
                                          </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                        {isLoading ? (
                            <div className="loader-position">
                                <Loader containerClassName="" />
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Strip>
        </RenderPage>
    );
};

export default Login;
