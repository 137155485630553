import React, { useReducer, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import PeopleReducer, {
    initialState,
    UPDATE_PEOPLE,
} from '../reducers/PeopleReducer';

import PeopleService from '../../api/services/PeopleService';
import debugLogger from '../Utils/debugLogger';

const initialContext = initialState;

const PeopleContext = React.createContext(initialContext);

export function PeopleProvider(props) {
    const [peopleState, dispatch] = useReducer(
        PeopleReducer,
        initialState || {},
    );

    const getAllPeopleList = async () => {
        let response = '';
        //   DISPATCH TO UPDATE_CATEOGRY
        await PeopleService.getPeopleList().then(async response => {
            response.text().then(async (res) => {
                try {
                    let result = JSON.parse(res);
                    if (result.code === 0) {
                        if (result.data) {
                            response = result.data ? result.data.peopleList : [];
                            dispatch({
                                type: UPDATE_PEOPLE,
                                data: result.data ? result.data.peopleList : [],
                            });
                            return response;
                        }
                    }
                }
                catch (err) {
                    debugLogger('read all people error: ', err);
                    throw err.error;
                }
            })
        })

    };

    const value = useMemo(
        () => [
            peopleState,
            {
                getAllPeopleList,
            },
        ],
        [peopleState],
    );

    return (
        <PeopleContext.Provider value={value}>
            {props.children}
        </PeopleContext.Provider>
    );
}
const usePeople = () => useContext(PeopleContext);
export { PeopleContext, usePeople };

PeopleProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
