import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RoutesComponent from './RoutesComponent';
import { AuthProvider } from "../context/AuthProvider";
import { PeopleProvider } from "../context/PeopleProvider";
import './App.scss';


const App = (props) => {
  return (
    <div className="App">
      <AuthProvider {...props}>
      <PeopleProvider {...props}>
      <BrowserRouter>
        <RoutesComponent />
      </BrowserRouter>
      </PeopleProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
