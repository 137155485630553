import React, {useState}  from 'react';
//import ReactPlayer from 'react-player';
import { Col, Row } from "reactstrap";

import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Banner from './Component/Banner';
import Information from './Component/Information';
import Datainformation from './Component/DataInformation';
// import application_contants from '../../contants/application_contants';
import Strip from "../../customComponents/Strip/Strip"; 
import SEOComponent from '../../customComponents/SEO/SEOComponent';

const Home = () => {
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-home"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                 <SEOComponent
            title="Home"
            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
            description="Text-to-speech synthesizer in nine Indian languages"
            siteUrl="/Home" />
                <Banner />
                <div>
                    <Strip
                            id="tst-strip-Video"
                            className="strip strip-no-padding bg-white-smoke-medium"
                            containerType="container-fluid mb-9 mt-5">

                            <div className='container-video' xs={12} sm={12} md={12} lg={11} xl={11} >
                                {/*   <ReactPlayer align ="center"
                                        controls="true"
                                        width="70%"
                                        height="70%"
                                        playIcon={application_contants.PlayIcon}
                                        url={application_contants.video}/> */}

                                <iframe align ="center" width="900" height="506" src="https://www.youtube.com/embed/CK2gN_gCzkk?si=mRPW-QPSvihPM-aj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div className="pb-5">
                                <Datainformation/>
                            </div>
                            
                            <div className='container-video' xs={12} sm={12} md={12} lg={11} xl={11} >
                              {/*   <ReactPlayer align ="center"
                                    controls="true"
                                    width="70%"
                                    height="70%"
                                    playIcon={application_contants.PlayIcon}
                                    url={application_contants.video}/> */}
                                    <iframe align ="center" width="900" height="506" src="https://www.youtube.com/embed/Mre9vrX82lM" 
                                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; 
                                    clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowfullscreen></iframe>
                                    
                            </div>
                            <div className="pb-5">
                                <Information />
                            </div>
                    </Strip>
                </div>

                                





            </RenderPage>
        </ExternalLayout>
    )
};

export default Home;
