import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap"
import OpeningService from '../../../api/services/OpeningService';
import TitleComponent from '../TitleComponent/TitleComponent';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import moment from "moment";


const OpeningComponent = (props) => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [otherData, setOtherData] = useState([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, [])

    

    const getList = async () => {
        setIsLoading(true);
        await OpeningService.getOpeningList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);

                            if (result.code === 0) {
                                if (result.data) {
                                    const lists = result.data.list.filter((it) => it.status === 1 && it.typeId === 1);
                                    setData([...lists]);
                                    const otherLists = result.data.list.filter((it) => it.status === 1 && it.typeId === 2);
                                    setOtherData([...otherLists])
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setOtherData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handlePdf = (item) => {
        window.open(
            `${ApiConfig.url}${item.pdfURL}`,
            // 'https://drive.google.com/file/d/1xj-CX02DvmdBp58JXqbH6SfD_v3FCFmk/view?usp=sharing',
             "_blank"
        );
    }

    const handleNavigationOpening = async (card) => {
        console.log('clicked')
        props.history.push('/Openings',{card});
    }

    return (
        <Row className="mx-0">
             {props.isSecond ?
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-0 px-0">
                    {otherData.length > 0 ?
                    <h4 className="h6-medium section-title title-black align-left pb-3 pt-2">{props.secondSectionTitle}</h4>: null}
                    <Row>
                        {otherData.map((item, index) => {
                            return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 5 : 3} xl={width === 1024 ? 5 : 3} className="challenges-card-br pt-4" key={index}>
                                <Row className="mb-3 mx-0">
                                    <Col xs={12} sm={12} md={11} lg={11} xl={11} className={`bg-solitude-blue opening-card opening-info-container`} onClick={() => handlePdf(item)}>
                                        <div >
                                            <p className="p-large section-title jostRegular pt-4  title-black align-center opening-card-position">{item.openingTitle}</p>
                                            {/* <p className="p-small text-secondary section-title pl-3 pt-4 jostRegular title-black align-left opening-card-position">Date :  {moment(item.date).format('d MMMM yyyy')}</p> */}
                                        </div>
                                        {/* <div className="align-right">
                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                        </div> */}
                                    </Col>
                                </Row>

                            </Col>
                        })}
                    </Row>
                </Col> : null}
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2 px-0">
                {!props.isSecond ?
                    <div className="pb-4">
                        <TitleComponent title={props.firstSectionTitle} pathName={'openings'} isViewAll={true} />
                    </div>
                    : <h4 className="h6-medium section-title title-black align-left pb-3 pt-2">{props.firstSectionTitle}</h4>
                }
                {data.length > 0 ?
                 <Row>
                    {data.length > 0 && data.map((item, index) => {
                        return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 5 : 3} xl={width === 1024 ? 5 : 3} className="challenges-card-br" key={index}>
                            <a href={`/openings/${item.openingTitle.toLowerCase()}`}>
                            
                                <Row className="mb-3 mx-0">
                                {/* <p onClick = {() => handleNavigationOpening(card)} className="cursor-pointer"> */}
                                    <Col xs={12} sm={12} md={11} lg={11} xl={11} className={`bg-solitude-blue opening-card opening-info-container`}>
                                        <div>
                                            <p className="p-large section-title jostRegular pt-4  title-black align-center opening-card-position">{item.openingTitle}</p>
                                            {/* <p className="p-small text-secondary section-title pl-3 pt-4 jostRegular title-black align-left opening-card-position">Date :  {moment(item.date).format('d MMMM yyyy')}</p> */}
                                        </div>
                                        {/* <div className="align-right">
                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                        </div> */}
                                    </Col>
                                    {/* </p> */}
                                </Row>
                                </a>
                        </Col>
                    })}
                </Row>
                :
                            <div className="pb-5 mb-5">
                                <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                                    <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium"> Coming Soon...</span>
                                </h5 >
                            </div>
                        }
            </Col>
           
        </Row>
    )
}

export default OpeningComponent;