
import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import DocumentsService from '../../../api/services/DocumentsService';
import application_contants from "../../contants/application_contants";
import { useWindowSize } from "@react-hook/window-size";
import classnames from 'classnames';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import { Link } from 'react-router-dom';
import moment from "moment";
import SEOComponent from '../../customComponents/SEO/SEOComponent';
import PDFViewer from 'mgr-pdf-viewer-react'

const Documents = (props) => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, [])
    const getList = async () => {
        setIsLoading(true);
        await DocumentsService.getDocumentsList()
            .then(async (res) => {
                console.log("openings result", res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("before data list", result)
                            if (result.code === 0) {
                                if (result.data) {
                                    // debugLogger(result.data);
                                    const lists = result.data.list.filter((it) => it.status === 1);
                                    setData([...lists])
                                    console.log("data list", ...result.data.list)
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
    const handlePreview = (url) => {
        window.open(
            `${url}`,
            "_blank"
        );
    }
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-documents"
                className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Documents"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/documents" />
                <Strip
                    id="tst-strip-documents"
                    className="strip strip-no-padding"
                    containerType="container">
                    <Row className="mx-0 pt-5">
                       
                        <Col xs={18} sm={11} md={11} lg={11} xl={11} className="pr-0 pt-2 pb-5 ">
                            <Row>
                                <Col xs={15} sm={15} md={15} lg={15} xl={15} className="pt-5 pb-2">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Documents</h3> :
                                        <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Documents</h3>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-4">
                                    <Row>
                                    {data.map((item,index) =>{
                                          return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="challenges-card-br pb-4 cursor-pointer" onClick={() => handlePreview(`${ApiConfig.url}${item.docUrl}`)}>
                                                    <Row className="mb-3 mx-0 documents-border">
                                                        <Col xs={6} sm={6} md={5} lg={5} xl={5} className="pdf-thumbnail pt-2 pb-2">
                                                            <div className="">
                                                            <PDFViewer
                                                                document={{
                                                                // url: `http://arxiv.org/pdf/quant-ph/0410100.pdf`
                                                                url: `${ApiConfig.url}${item.docUrl}`
                                                                }}
                                                                page={1}
                                                                hideNavbar={true}
                                                                showThumbnail={{ scale: 3 }}
                                                            />
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} XL={7} className="pl-2 pr-2 pt-2 documents-text" >
                                                            <Row>
                                                                <p className="document-section-title nunitoSans-Regular ">{item.title}</p>
                                                            </Row>
                                                            <Row>
                                                                <p className="pt-1 document-sub-section-title nunitoSans-Regular">{item.docTypeTitle}</p>
                                                            </Row>
                                                            <Row>
                                                                <p className="pt-2 document-sub-section-title nunitoSans-Regular document-card-position">{item.date}</p>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                     })}
                                    </Row>
                                </Col>
                                {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-4">
                                    {data.length > 0 ?
                                        <Row>
                                    {data.map((item,index) =>{
                                          return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="openings-card-br pb-4 cursor-pointer" onClick={() => handlePreview(`${ApiConfig.url}${item.docUrl}`)}>
                                                    <Row className="mb-3 mx-0 documents-border">
                                                        <Col xs={6} sm={6} md={5} lg={5} xl={6} className="pdf-thumbnail pt-2 pb-2">
                                                            <div className="">
                                                            <PDFViewer
                                                                document={{
                                                                // url: `http://arxiv.org/pdf/quant-ph/0410100.pdf`
                                                                url: `${ApiConfig.url}${item.docUrl}`
                                                                }}
                                                                page={1}
                                                                hideNavbar={true}
                                                                showThumbnail={{ scale: 3 }}
                                                            />
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} XL={6} className="pl-2 pr-2 pt-2 documents-text" >
                                                            <Row>
                                                                <p className="document-section-title pt-1 pb-2 nunitoSans-Regular ">{item.title}</p>
                                                            </Row>
                                                            <Row>
                                                                <p className="pt-1 document-sub-section-title pb-2 nunitoSans-Regular">{item.docTypeTitle}</p>
                                                            </Row>
                                                            <Row>
                                                                <p className="pt-2 document-sub-section-title nunitoSans-Regular document-card-position">{item.date}</p>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                     })}
                                    </Row>
                                        :
                                        <div className="pb-2 mb-0">
                                            <h5 className="h5-small nunitoSans-SemiBold mb-0 pt-2  section-title align-left">
                                                <span className="h5-small section-title align-left pt-3  title-navy-blue-medium"> Coming Soon ...</span>
                                            </h5 >
                                        </div>
                                    }
                                </Col> */}
                            </Row>
                        </Col>
                    
                    </Row>

                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};
Documents.defaultProps = {
    isClassName: true,
};
Documents.propTypes = {
    isClassName: PropTypes.bool,
};
export default Documents;