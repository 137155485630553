import application_contants from '../../contants/application_contants';

const GalleryData = [
    
        application_contants.bhashiniimg1,application_contants.bhashiniimg2,application_contants.bhashiniimg3,
        application_contants.galleryImg1,application_contants.galleryImg2,application_contants.galleryImg3,application_contants.galleryImg4,application_contants.galleryImg5,application_contants.galleryImg6,application_contants.galleryImg7,
        application_contants.galleryImg8,application_contants.galleryImg9,application_contants.galleryImg10,application_contants.galleryImg11,application_contants.galleryImg12,application_contants.galleryImg13,
        application_contants.galleryImg14,application_contants.galleryImg15
];


export default GalleryData;