const OpeningCardData = [
    {
        sectionTitle: 'Postdoctoral Research Associates',
        link: 'postdoctoral-research-associates',
        detailtitle: 'Postdoctoral research associate position in the project Speech Recognition in Agriculture and Finance for the Poor in India',
        code: 'BMGF_PRA',
        aboutus: 'Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good in the open source domain to spur research and innovation in speech recognition in nine different Indian languages in the area of agriculture and finance. Nine Indian languages considered for this project are Hindi, Bengali, Marathi, Telugu, Bhojpuri, Kannada, Magadhi, Chhattisgarhi, and Maithili. This project is funded by Bill and Melinda Gates Foundation.',
        role: [
            {
                introduction: 'The postdoctoral research associate has to carry out independent research as well as work closely with data collection and technology partner to achieve the project goals - high-quality data in Indian languages and open-sourcing speech recognition models. The research will focus on automatic speech recognition (ASR) approaches for Indian languages using novel deep neural networks (through Kaldi, Pytorch, or other open source machine learning platforms) ranging from feature extraction to lexical, syntactic and language modeling. The postdoctoral research associate will be responsible for thorough data validation and development & dockerizing speech recognition models, which are the key aspects to successfully achieve the project goals. Fellow duties and responsibilities include the following:',
                info: [
                    {
                        param: 'Develop state-of-the-art speech recognition systems (using Kaldi, ESPnet for example) in Indian languages in this project',
                    },
                    {
                        param: 'Dockerize and open-source the speech recognition models',
                    },
                    {
                        param: 'Carry out research in ASR and related topics in Indian languages',
                    },
                    {
                        param: 'Publish research and development in the project in top tier conferences and journals'
                    },
                    {
                        param: 'Interact with data collection partner and monitor their progress'
                    },
                    {
                        param: 'Plan and execute data validation with support of project associates and provide feedback to data collection partner'
                    },
                    {
                        param: ' Interface with data hosting service provider to provide data in a distributable format'
                    }
                ]
            }
        ],
        essentialQualification: [
            {
                param: 'A PhD or equivalent degree in Speech Technology, Computer Science, Signal Processing or other relevant area, skills for doing excellent research in a group, and outstanding research experience (Candidates who have submitted thesis and are awaiting degree may also apply)',
            },
            {
                param: 'A strong background in machine learning, signal processing, statistics, applied mathematics, optimization, linear algebra',
            },
            {
                param: 'A good expertise in hidden Markov models, deep neural networks, and/or statistical language modeling/natural language understanding'
            },
            {
                param: 'Prior experience in speech recognition research and development'
            },
            {
                param: 'Working experience with Kaldi, Pytorch, or other open source machine learning platforms'
            },
            {
                param: 'Experience with handling large speech datasets'
            },
            {
                param: 'Strong publication record in top academic conferences and journals'
            },
            {
                param: 'Outstanding interpersonal skills, strong work ethic, organizational skills and close attention to detail'
            },
            {
                param: 'Ability to solve problems independently and work well as part of a team'
            },
        ],
        preferredQualification: [
            {
                param: 'Experience supervising students, staff, or volunteers'
            },
            {
                param: 'Experience with working in Indian languages - text and speech'
            },
            {
                param: 'Great written and oral communication skills'
            },
            {
                param: 'Strong programming skills and familiarity with Python, C/C++, and with the Linux environment'
            },
        ],
       
        
                duration: 'One and half year',
                startDate: 'As early as possible (from April 1st 2021)',
                salary: '1,20,000 INR',
                applicationdesc: 'Applications will be considered until the position is filled. Please Use the following link to apply',
                link: 'https://forms.gle/uaA6hyAuwywXjYcaA',
                name: 'Prasanta Kumar Ghosh',
                emailId: 'prasantg@iisc.ac.in'
        
    },
   
    
]


export default OpeningCardData;