import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Form, Label, Button, FormGroup, Input, Table } from 'reactstrap';
import PhotoFileUploader from '../../../customComponents/PhotoFileUploader/PhotoFileUploader';
import AddTableInfo from './AddTableInfo/AddTableInfo';
import AddSubstack from './AddSubstack/AddSubstack';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponents/ConfirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponents/Loader/Loader';
import Axios from 'axios';
import ApiConfig from '../../../../api/apiConfig/apiConfig';
import Strip from '../../../customComponents/Strip/Strip';
import FileUploader from '../../../customComponents/FileUploader/FileUploader';
import ChallengersService from '../../../../api/services/ChallengersService';

const AddChallenges = (props) => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [imagesTitle, setImagesTitle] = useState('');
    const [description, setDescription] = useState('');
    const [imagesUrl, setImagesUrl] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [substackDetails, setSubstackDetails] = useState([]);
    const [tableDetails, setTableDetails] = useState([]);
    const [imgFile, setImgFile] = useState('');
    const [imgFileName, setImgFileName] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [imgList, setImgList] = useState([]);
    const [url, setUrl] = useState('');
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setDescription('');
            setTitle('');
            setDate('');
            setToDate('');
            setFromDate('');
            setId('')
            setDetailTitle('');
            setUrl('');
            setIsLoading('');
            setImagesUrl('')
            setImageTitle('')
            setSubstackDetails([])
            setTableDetails([])
        } else {
            setDescription(props.item.description);
            setTitle(props.item.title);
            setDate(props.item.date);
            setToDate(props.item.toDate);
            setFromDate(props.item.fromDate);
            setDetailTitle(props.item.detailTitle);
            setId(props.item.id);
            setUrl(props.item.url);
            setImgFileName(props.item.imagesUrl);
            setImageTitle(props.item.imageTitle);
            setImagesUrl(props.item.imagesUrl ? JSON.parse(props.item.imagesUrl) : [])
            setSubstackDetails(props.item.keyTakeAways ? JSON.parse(props.item.keyTakeAways) : [])
            setTableDetails(props.item.websiteSource ? JSON.parse(props.item.websiteSource) : [])
            
        }
        setIsFileChanged(false);
    }, [isAdd, props]);

    const handleDescription = (e) => {
        setDescription(e.target.value)
    }
    const handleTitle = (e) => {
        setTitle(e.target.value)
    }
    
    const handledate = (e) => {
        setDate(e.target.value)
    }
    const handleToDate = (e) => {
        setToDate(e.target.value)
    }
    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
   

    const handledetailTitle = (e) => {
        setDetailTitle(e.target.value)
    }
    const handleUrl = (e) => {
        setUrl(e.target.value)
    }

    const handleImageTitle = (e) => {
        setImagesTitle(e.target.value)
    }
    // const getThumbnail = async (event) => {
    //     let fileList = [...event.target.files];
    //     let item = [];
    //     let len = fileList.length;
    //     fileList.map(file => {
    //         const reader = new FileReader();
    //         reader.onabort = () => console.log("file reading was aborted");
    //         reader.onerror = () => console.log("file reading has failed");
    //         reader.onloadend = async () => {
    //             const binaryStr = reader.result;
    //             item.push({ base64: binaryStr, file: file });
    //             if (len === item.length) {
    //                 setImagesUrl([...imagesUrl, ...item])
    //                 console.log("imagesUrl")
    //                 console.log(imagesUrl)
    //             }

    //         };
    //         reader.readAsDataURL(file);
    //     });
    // }


    const getThumbnail = async (item) => {
        await setImgFile(item[0].file);
        await setImgFileName(item[0].file.name);
       // setImagesUrl(...imagesUrl,...item[0].file)
        setIsFileChanged(true);
    }

    const handleGalleryImageDelete = (index) => {
        imagesUrl.slice(index, 1);
        setImagesUrl([...imagesUrl]);
    }

    const handleAddImg = () => {
        let item = [];
        let obj = {
            imgFileName: imgFileName,
            imgPath : '/challengers/'+imgFileName,
            file: imgFile,
            imgTitle: imagesTitle,
        }
        item.push(obj);
        setImgList([...imgList, ...item]);
        let imageItem = []
        let fileList = [];
        imgList.map(val => {
              fileList.push(val.file);
        })
        console.log("fileList");
        console.log(fileList)
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                imageItem.push({ base64: binaryStr, file: imgFile });
                if (len === imageItem.length) {
                    setImagesUrl([...imagesUrl, ...imageItem])
                    console.log("imagesUrl")
                    console.log(imagesUrl)
                }

            };
            reader.readAsDataURL(file);
        });
        handleImgCancel();
    }

    const handleImgCancel = () => {
        setImagesTitle('');
        setImgFile('');
        setImgFileName('')
    }
    const handleSubstacks = (item) => {
        setSubstackDetails([...item])
    }
    const handleTable = (item) => {
        setTableDetails([...item])
    }
    const handleImageDelete = index => {
        imgList.splice(index, 1);
        setImagesUrl([...imgList]);
     };

     const handleLocationEdit = async (index) => {
        const item = imagesUrl[index];
        setImagesTitle(item.imgTitle);
        setImgFileName(item.imgFileName);
        console.log("india",item)
        // await setImgGallery(item.imgGallery && item.imgGallery.length > 0 ? item.imgGallery : []);
    };

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let finalGalleryFile = [];
        let finalGalleryArrayList = [];
        let finalGalleryListObj = {};
        if (imgList.length > 0) {
            imgList.map((it) => {
                if (it.file) {
                    finalGalleryFile.push(it.file);
                }
                finalGalleryListObj = {
                    imgPath: it.file ? `/challengers/${it.file.name}` : it.imgPath,
                }
                finalGalleryArrayList.push(finalGalleryListObj);
            })
        }

        let data = {
            title: title,
            websiteSource: JSON.stringify(tableDetails),
            description: description,
            detailTitle: detailTitle,
            date: date,
            url: url,
            toDate: toDate,
            fromDate: fromDate,
            id: id,
            userId: userId,
            // imagesUrl: imgList.length > 0 ? JSON.stringify(imgList) : '[]',
            imagesUrl: imgList.length > 0 ? JSON.stringify(imgList) : JSON.stringify(imagesUrl),

            keyTakeAways: JSON.stringify(substackDetails)
        }
        setIsLoading(true);
        if (finalGalleryFile.length > 0) {
            UploadGallery(finalGalleryFile, data, true, "");
        } else {
            insertOrUpdate(data);
        }
        // insertOrUpdate(data);
        console.log("data", data)
        setIsLoading(false);
    }

    const UploadGallery = async (galleryFiles, insertOrUpdateData, isInsert) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/challengers/");
        await galleryFiles.map((item) => {
            data.append("image", item);
            console.log("item", item)
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        if (data) {
            Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
                .then(async res => {
                    if (res.status === 200) {
                        try {
                            if (res.data.code === 0) {
                                if (isInsert) {
                                    await insertOrUpdate(insertOrUpdateData);
                                }
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${res.data.message}`);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch(err => {
                    console.log("We will be back soon.");
                    return err;
                });
        }
    }

    const insertOrUpdate = async (data) => {
        await ChallengersService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);
                                props.handleRefresh();
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
    return (
        <Strip
            id="tst-add-opening"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                         <div className="modal-header-container bg-theme-blue">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD CHALLENGES" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                            <Row>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>
                                                        <FormGroup row className="mx-0 align-items-start">
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    challenges Title:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="challenges Title"
                                                                    id="challenges Title"
                                                                    name="challenges Title"
                                                                    className="form-control common-form-control"
                                                                    value={title}
                                                                    onChange={(event) => handleTitle(event)}
                                                                //rows={10}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Date:
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    placeholder="date"
                                                                    id="date"
                                                                    name="date"
                                                                    className="form-control common-form-control"
                                                                    value={date}
                                                                    onChange={(event) => handledate(event)}
                                                                    dateFormat='MMMM d,yyyy'
                                                                //rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-start">
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    detailTitle:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="detailTitle"
                                                                    id="detailTitle"
                                                                    name="detailTitle"
                                                                    className="form-control common-form-control"
                                                                    value={detailTitle}
                                                                    onChange={(event) => handledetailTitle(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    description:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="description"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={description}
                                                                    onChange={(event) => handleDescription(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Url:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="url"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={url}
                                                                    onChange={(event) => handleUrl(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                                                <Label className="common-label text-left">
                                                                    From date:
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    placeholder="date"
                                                                    id="fromDate"
                                                                    name="fromDate"
                                                                    className="form-control common-form-control"
                                                                    value={fromDate}
                                                                    onChange={(event) => handleFromDate(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                                                <Label className="common-label text-left">
                                                                    To date:
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    placeholder="date"
                                                                    id="toDate"
                                                                    name="toDate"
                                                                    className="form-control common-form-control"
                                                                    value={toDate}
                                                                    onChange={(event) => handleToDate(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        {/* <FormGroup row className="mx-0 align-items-start">
                                                            {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    keyTakeAways*:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="KeyTakeAways"
                                                                    id="KeyTakeAways"
                                                                    name="KeyTakeAways"
                                                                    className="form-control common-form-control"
                                                                    value={keyTakeAways}
                                                                    onChange={(event) => handlekeyTakeAways(event)}
                                                                    rows={10}
                                                                />
                                                            </Col> */}
                                                            {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    website source*:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="websiteSource"
                                                                    id="websiteSource"
                                                                    name="websiteSource"
                                                                    className="form-control common-form-control"
                                                                    value={websiteSource}
                                                                    onChange={(event) => handlewebsiteSource(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup> */}

                                                        {/* <GalleryImage getThumbnail={(item) => getThumbnail(item)} imagesUrl={imagesUrl} imagetitle={imageTitle} handleImageTitle={(index) => handleImageTitle(index)} handleGalleryImageDelete={(index) => handleGalleryImageDelete(index)} /> */}

                                                    </Form>
                                                </Col>
                                            </Row>
                                            <FormGroup className="mx-0 align-items-center mt-3">
                                                        <AddSubstack substackDetails={substackDetails}  handleSubstacks={handleSubstacks} />
                                            </FormGroup>
                                            <FormGroup className="mx-0 align-items-center mt-3">
                                                <AddTableInfo tableDetails={tableDetails}  handleTable={handleTable} />
                                            </FormGroup>
                                            <div className="pt-2"> 
                                                <h6 className="h5-small external-title title-bright-grey p-2 text-center">
                                                    Add Image Details:
                                                </h6>
                                            </div>
                                            <FormGroup row className="mx-0 align-items-start">
                                          
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormGroup row className="align-items-start">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                            <Label className="common-label text-left">
                                                                title*:
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                placeholder="title"
                                                                id="imgTitle"
                                                                name="imgTitle"
                                                                className="form-control common-form-control"
                                                                value={imagesTitle}
                                                                onChange={(event) => handleImageTitle(event)}

                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                            <Label className="common-label text-left">
                                                                Upload Image :
                                                            </Label>
                                                            <FileUploader
                                                                isImg={true}
                                                                isForm={true}
                                                                getThumbnail={(item) =>
                                                                    getThumbnail(item)
                                                                }
                                                                value={imgFileName}
                                                                isEditable={false}
                                                                labelName="Upload Image"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormGroup row className="align-items-start">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="ml-auto">
                                                            <div className="d-flex align-items-end justify-content-end">
                                                                <div
                                                                    onClick={() =>
                                                                        handleImgCancel()
                                                                    }
                                                                >
                                                                    <Button className="btn custom-danger-btn custom-btn--small br-4px fs-10px">
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    className="ml-3"
                                                                    onClick={() =>
                                                                        handleAddImg()
                                                                    }
                                                                >
                                                                    <Button className="btn custom-theme-btn custom-btn--small br-4px fs-10px">
                                                                        Save
                                                                    </Button>
                                                                  </div>
                                                            </div>
                                                        </Col>
                                                    </FormGroup>
                                                   
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Table bordered responsive>
                                                        <thead className="custom-table-thead">
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>image path</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="custom-table-tbody">
                                                            {imagesUrl.length > 0 && imagesUrl.map((it, index) => (
                                                                <tr key={index}>
                                                                    <td>{it.imgTitle}</td>
                                                                    <td>{it.imgFileName}</td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div
                                                                            onClick={() =>
                                                                                handleImageDelete(index)
                                                                            }
                                                                            >
                                                                                <Button className="btn custom-danger-btn custom-btn--small br-4px fs-10px">
                                                                                    Delete
                                                                                </Button>
                                                                            </div>
                                                                            <div
                                                                                className="ml-2"
                                                                            onClick={() =>
                                                                                handleLocationEdit(index)
                                                                         }
                                                                            >
                                                                                <Button className="btn custom-theme-btn custom-btn--small br-4px fs-10px">
                                                                                    Edit
                                                                                </Button>
                                                                            </div>
                                                                            <div
                                                                                className="ml-2"
                                                                            // onClick={() =>
                                                                            //     handleLocationPosition(index)
                                                                            // }
                                                                            >
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </FormGroup>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 3, size: 6 }}
                                                    lg={{ offset: 3, size: 6 }}
                                                    xl={{ offset: 3, size: 6 }}
                                                >
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button

                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                            // disabled={
                                                            //     !description &&
                                                            //     !photo
                                                            // }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}

                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}

export default AddChallenges;