export async function makeVideoRequests() {
    const text = "ఇది కారులో బెంగళూరు వైపు వెళ్లే మార్గం";
    const audioUrl = await fetchAudioUrl(text);
  
    if (!audioUrl) {
      console.error('Error getting audio URL');
      return;
    }
  
    const video_name = createVideoName(audioUrl);
    const videoUrl = await fetchVideoUrl(audioUrl, video_name);
  
    if (!videoUrl) {
      console.error('Error getting video URL');
      return;
    }
  
    console.log('Full Video URL:', videoUrl, 'Video File loaded');
  }
  
  async function fetchAudioUrl(text) {
    const url = 'https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/te-fem-glowtts-audio';
  
    try {
      const audioResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: text }),
      });
  
      const audioData = await audioResponse.json();
      return audioData.audioUrl;
    } catch (error) {
      console.error('Error fetching audio URL:', error);
      return null;
    }
  }
  
  function createVideoName(audioUrl) {
    const splitAudioUrl = audioUrl.split('.com/');
    return splitAudioUrl[1].replace('.wav', '.mp4');
  }
  
  async function fetchVideoUrl(audioUrl, video_name) {
    const videoApiUrl = 'https://blhzbpoisl.execute-api.ap-south-1.amazonaws.com/Test_stage';
    const genderResult = 'Female';
  
    try {
      const videoResponse = await fetch(videoApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ audio_path: audioUrl, gender: genderResult, outfilename: video_name }),
      });
  
      const videoData = await videoResponse.json();
      return `https://audiosyspin.s3.ap-south-1.amazonaws.com/${video_name}`;
    } catch (error) {
      console.error('Error fetching video URL:', error);
      return null;
    }
  }
  