import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import { useWindowSize } from "@react-hook/window-size";
import application_contants from "../../contants/application_contants";
import moment from "moment";
import PropTypes from "prop-types";
import ChallengersService from '../../../api/services/ChallengersService';
import SEOComponent from '../../customComponents/SEO/SEOComponent';



const Challenges = (props) => {
    const [width] = useWindowSize();
    const [data, setData] = useState([]);
    const [preData, setPreData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const date = moment().format('MMMM , YYYY');

  
    React.useEffect(() => {
        window.scrollTo(0, 0);
        getChallengersList();
    }, []);

    const getChallengersList = async () => {
        setIsLoading(true);
        await ChallengersService.getChallengersList()
          .then(async (res) => {
            if (res.status === 200) {
              try {
                res.text().then(async (res) => {
                  let result = JSON.parse(res);
                  if (result.code === 0) {
                    if (result.data) { 
                      const upCominglists = result.data.list.filter((it) => it.status === 1 && moment().isBefore(it.date));
                      const previouslists = result.data.list.filter((it) => it.status === 1 && moment().isAfter(it.date));
                      setData([...upCominglists])
                      setPreData([...previouslists])
                      setIsLoading(false);
                    } else {
                      setData([]);
                      setPreData([]);
                      setIsLoading(false);
                    }
                  } else {
                    setData([]);
                    setPreData([]);
                    setIsLoading(false);
                  }
                });
              } catch (err) {
                console.log(err);
                setIsLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      };

      const handleNavigationChallenges = async (challengesDetail) => {
        console.log('clicked')
        props.history.push('/ChallengesDetails',{challengesDetail});
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-challenges"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Challenges"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/challenges" />

                <Strip
                    id="tst-strip-challenges"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title jostRegular title-navy-blue align-left">Challenges</h5> :
                                        <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Challenges</h3>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-3">
                                <h5 className="h6-medium section-title align-left title-black fontsize-14px pb-4 pt-3">Upcoming</h5>
                                {/* {data === null ? */}
                                {data.length > 0 ?
                                    <Row>
                                        {data.map((challengesDetail,index) =>{
                                            return  <Col xs={12} sm={12} md={6} lg={width === 1024 ? 5 : 3} xl={width === 1024 ? 5 : 3} key={index} className="challenges-card-br">
                                                       {/* <a href={`/Challenges/${challengesDetail.title.toLowerCase()}`}> */}
                                                        <Row className="mb-3 mx-0">
                                                            <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 opening-card opening-info-container'>
                                                            <div>
                                                            <p onClick = {() => handleNavigationChallenges(challengesDetail)}>

                                                                <div>
                                                                    <p className="p-large section-title jostRegular pl-2 pt-4 title-black align-left opening-card-position">{challengesDetail.title}</p>
                                                                    <p className="p-small text-secondary section-title pl-2 pt-4 jostRegular title-black align-left opening-card-position">Date : {moment(challengesDetail.date).format('D MMMM yyyy')}</p>

                                                                </div>
                                                                </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* </a> */}
                                                </Col>  
                                        })}
                                    </Row> 
                                : 
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                                    <h2 className="h5-small section-title align-left pt-5 title-navy-blue-medium">Coming soon ....</h2>
                                    </Col> 
                                </Row>
                                }
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-3">
                                <h5 className="h6-medium section-title align-left  title-black pb-4 pt-3">Previous</h5>
                                {preData.length > 0 ?
                                    <Row>
                                    {preData.length > 0 && preData.map((challengesDetail,index) =>{
                                          return  <Col xs={12} sm={12} md={6} lg={width === 1024 ? 5 : 3} xl={width === 1024 ? 5 : 3} key={index} className="challenges-card-br">
                                                {/* <a href={`/Challenges/${challengesDetail.title.toLowerCase()}`}> */}
                                                    <Row className="mb-3 mx-0">
                                                        <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 opening-card opening-info-container '>
                                                            <div>
                                                            <p onClick = {() => handleNavigationChallenges(challengesDetail)}>

                                                            <div>
                                                                <p className="p-large section-title jostRegular pl-2 pt-4  title-black align-left opening-card-position">{challengesDetail.title}</p>
                                                                <p className="p-small text-secondary section-title pl-2 pt-4 jostRegular title-black align-left opening-card-position">Date : {moment(challengesDetail.date).format('D MMMM yyyy')}</p>
                                                            </div>
                                                            </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* </a> */}
                                            </Col>
                                    })}
                                    </Row>
                                    : 
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                                    <h2 className="h5-small section-title align-left pt-5 title-navy-blue-medium">Coming soon ....</h2>
                                    </Col> 
                                </Row>
                                }
                                </Col>

                            </Row>
                        </Col>
                    </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

Challenges.defaultProps = {
    isClassName: true,
};

Challenges.propTypes = {
    isClassName: PropTypes.bool,
};

export default Challenges;

