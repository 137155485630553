import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    CategoryOutlined, PeopleAltRounded, ViewAgendaOutlined, PeopleAltOutlined
} from "@material-ui/icons";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import application_contants from "../../contants/application_contants";
import { activeTab } from "../../Utils/activeTab";


const SlideMenu = () => {
    const history = useHistory();
    const location = useLocation();
    console.log(location.pathname);
    
    return (
        
        <Navigation
            activeItemId={activeTab(location.pathname)}
            onSelect={({ itemId }) => {
                history.push(itemId);
            }}
            items={[
                // {
                //     title: "About",
                //     itemId: application_contants.ABOUTUS,
                //     elemBefore: () => <ViewAgendaOutlined />,
                //     //subNav: [],
                // },
                // {
                //     title: "Banner",
                //     itemId: application_contants.BANNER,
                //     elemBefore: () => <CategoryOutlined />,
                //     //subNav: [],
                // },
                {
                    title: "Openings",
                    itemId: application_contants.OPENINGLIST,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "People",
                    itemId: application_contants.PEOPLES,
                    elemBefore: () => <PeopleAltRounded />,
                    //subNav: [],
                },
                {
                    title: "Challenges",
                    itemId: application_contants.CHALLENGESLIST,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Media",
                    itemId: application_contants.MEDIA,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Publication",
                    itemId: application_contants.PUBLICATION,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "ResearchArea",
                    itemId: application_contants.REASEARCHAREA,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Documents",
                    itemId: application_contants.DOCUMENTS,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Individuals",
                    itemId: application_contants.INDIVIDUALS,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Experts",
                    itemId: application_contants.EXPERTS,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Academics",
                    itemId: application_contants.ACADEMICS,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Industry",
                    itemId: application_contants.INDUSTRY,
                    elemBefore: () => <CategoryOutlined />,
                    //subNav: [],
                },
                {
                    title: "Tool",
                    itemId: application_contants.TOOLLIST,
                    elemBefore: () => <PeopleAltOutlined />,
                    //subNav: [],
                },
                
                {
                    title: "Datasets",
                    itemId: application_contants.DATASETS,
                    elemBefore: () => <PeopleAltOutlined />,
                    //subNav: [],
                },
                {
                    title: "Partners",
                    itemId: application_contants.PARTNERLIST,
                    elemBefore: () => <PeopleAltOutlined />,
                    //subNav: [],
                },
               
              
            ]}
        />
       
    );
    
};

export default SlideMenu;
