import React from 'react';
import { Row, Col } from 'reactstrap';
import Strip from '../../../customComponents/Strip/Strip';
import { useWindowSize } from "@react-hook/window-size";
import application_contants from '../../../contants/application_contants';


const Datainformation = () => {
    const [width] = useWindowSize();

    return (

        <Strip
            id="tst-strip-home"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row className="mx-0 pb-5">
                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                    <Row className="mx-0">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {width <= application_contants.MOBILE_BREAKPOINT ?  <h1 className="h6-small section-title title-navy-blue jostRegular align-left py-5">Bringing voice AI to marginalised populations in India</h1> :
                           <h1 className="h3-large align-left  section-title title-navy-blue jostRegular py-5 ">Introducing the SYSPIN Data Set</h1>}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-2">
                            <p className="p-medium section-title jostLight align-left title-dark-grey lh-30px pb-2">
                                A groundbreaking step towards revolutionizing voice technology for millions! As part of a project aimed at enabling large-scale, multi-speaker, multilingual text-to-speech (TTS) models, SYSPIN project from SPIRE Lab, IISc, Bangalore releases TTS corpuses in 9 Indian languages with 2 speakers (male and female) each. In collaboration with Bhashini AI Solutions Pvt Ltd, we recorded over 40 hours of speech per speaker, ensuring the highest quality through rigorous quality checks, including contributions from local and native speakers. The SYSPIN dataset, along with baseline TTS models, is now available for download, ready to empower voice tech innovations in industries like agriculture, healthcare, education, and finance. As part of our mission, to advance multilingual, multi-speaker TTS systems, we organized three exciting challenges under SYSPIN: LIMMITS 23, LIMMITS 24 and LIMMITS 25. SYSPIN is more than just data – it's a foundation for inclusive and accessible voice technologies, shaping the future of digital communication in India.                
	                        </p>
                        </Col>
                        
                        
                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                            <h1 className="h1-small section-title nunitosansItalic py-5 title-light-blue">More details are coming soon…</h1>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        </Strip>

    )
};

export default Datainformation ;
