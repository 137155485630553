import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import DataTable from 'react-data-table-component';
import { Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import OpeningService from '../../../../api/services/OpeningService';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponents/ConfirmationMessage/ConfirmationMessage';
// import DashboardLayout from '../../../customComponents/In';
import DashboardLayout from '../../../customComponents/InternalLayout/DashboardLayout'
import Loader from '../../../customComponents/Loader/Loader';
import MessageConfirmation from '../../../customComponents/MessageConfirmation/MessageConfirmation';
import RenderPage from '../../../customComponents/RenderPage/RenderPage';
import Strip from '../../../customComponents/Strip/Strip';
import debugLogger from '../../../Utils/debugLogger';
import AddOpening from './AddOpening';
import { SearchOutlined } from '@material-ui/icons';
import application_contants from '../../../contants/application_contants';
import { dataTablesMobileStyledTheme, dataTablesStyledTheme } from '../../../Utils/dataTablesStyledTheme';
import TablesColumns from './TablesColumns';

const OpeningList = () => {
    const [width] = useWindowSize();
    const [filterText, setFilterText] = useState();
    const [data, setData] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [item, setItem] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        getList();
    }, []);


    const getList = async () => {
        setIsLoading(true);
        await OpeningService.getOpeningList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    debugLogger(result.data);
                                    setData([...result.data.list])
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };


    const handleOnChange = (event) => {
        setFilterText(event.target.value)
    }

    const handleClear = () => {
        setFilterText("");
    };

    const handleToggle = () => {
        setIsModal(!isModal);
        setIsAdd(true)
    };

    const handleRefresh = async () => {
        setIsModal(!isModal);
        setIsAdd(true);
        await getList();
    }

    const handleCancelBtn = async () => {
        setIsConfirmModal(false)
        setItem('');
    }

    const handleConfirmBtn = async () => {
        setIsConfirmModal(false);
        const userId = await localStorage.getItem('userId')
        let data = {
            id: item.id,
            status: item.status === 1 ? 0 : 1,
            userId: userId,
        };
        console.log("*",data)
        setIsLoading(false);
        await OpeningService.enableDisableOpening(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("res.status",result)
                            if (result.code === 0) {
                                console.log("**",getList())
                                await getList();
                                alertSuccessMessage(`${result.message}`);
                            } else {
                                alertDangerConfirmMessage(`${result.message}`);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleEdit = (item) => {
        console.log(item)
        setIsModal(!isModal);
        setIsAdd(false);
        setItem(item);
    }

    const handleDelete = async (item) => {
        setIsConfirmModal(true)
        setItem(item);
    }


    return (
        <DashboardLayout>
            <RenderPage
                id="opening-page"
                className="mt-3"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-opening"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-3 px-0">
                            <Row className="align-items-center mx-0">
                                <Col xs={7} sm={7} md={4} lg={3} xl={3}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <Button
                                                className="btn custom-theme-btn custom-btn--small fs-14px br-4px"
                                                onClick={() => handleToggle()}
                                            >
                                                Add Opening
                                            </Button>
                                        </div>

                                    </div>
                                </Col>
                                <Col xs={5} sm={5} md={8} lg={9} xl={9}>
                                    <Row className="justify-content-end">
                                        <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                                            <InputGroup className="search__icon-container">
                                                <InputGroupAddon
                                                    addonType="append"
                                                    className="search__icon_append"
                                                >
                                                    <InputGroupText className="cursor-pointer search__icon-position">
                                                        <SearchOutlined
                                                            style={{
                                                                color: `${filterText ? "#fd4b9a" : "#dfdfdf"}`,
                                                                fontSize: "1.5rem",
                                                            }}
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <Input
                                                    placeholder="Search"
                                                    name="filter"
                                                    className="form-control search__form-container"
                                                    value={filterText}
                                                    onChange={(event) => handleOnChange(event)}
                                                />
                                                {filterText ? (
                                                    <p
                                                        className="common-label common-label-small mb-0 reset_label"
                                                        onClick={() => handleClear()}
                                                    >
                                                        Clear
                                                    </p>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="pb-3 pt-3">
                                {!isLoading ? (
                                    <DataTable
                                        title=""
                                        columns={TablesColumns(handleEdit, handleDelete)}
                                        data={data}
                                        pagination
                                        className="data-table"
                                        persistTableHead={false}
                                        customStyles={
                                            width > application_contants.MOBILE_BREAKPOINT
                                                ? dataTablesStyledTheme
                                                : dataTablesMobileStyledTheme
                                        }
                                    // onRowClicked={(data) => this.getHospitalItem(data)}
                                    />
                                ) : (
                                    <div className="pt-32px pb-64px d-flex justify-content-center">
                                        <Loader containerClassName="" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>

                </Strip>
            </RenderPage>
            {isModal ? <AddOpening isModal={isModal} toggle={handleToggle} isAdd={isAdd} item={item} handleRefresh={handleRefresh} /> : null}
            {isConfirmModal ? (
                <MessageConfirmation
                    isModal={isConfirmModal}
                    handleCancelBtn={handleCancelBtn}
                    handleConfirmBtn={handleConfirmBtn}
                    message={`Are you sure you want to ${item.status === 1 ? 'disable' : 'enable'} this user?`}
                />
            ) : null}
        </DashboardLayout>
    )
}

export default OpeningList;