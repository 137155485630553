// Sentaudio.js
import React, { useState } from 'react';
import ReactPlayer from "react-player";


function Sentaudio({ sentence, gender, audioFile }) {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
    // Disable scrolling on the body when the pop-up is open
    document.body.classList.add('overflow-hidden-evltl');
  };

  const closePopup = () => {
    setShowPopup(false);
    // Enable scrolling on the body when the pop-up is closed
    document.body.classList.remove('overflow-hidden-evltl');
  };

  return (
    <div className="sentaudio-container">
      {/* <p>{sentence}</p>
      <p>Gender: {gender}</p> */}
      <button onClick={openPopup}>Play</button>

      {showPopup && (
        <div className="popup-background-evltl">
          <div className="popup-evltl">
            <button className="close-button-evltl" onClick={closePopup}>Close</button>
            <p>{sentence}</p>
            <p>Gender: {gender}</p>
            {audioFile !== "no audio" && (
              <ReactPlayer
                url={audioFile}
                width="100%"
                height="50px"
                playing={false}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload" // Disable download button
                    }
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Sentaudio;
