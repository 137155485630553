import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import { useWindowSize } from '@react-hook/window-size';
import application_contants from "../../contants/application_contants";
import { Link } from 'react-router-dom';
import AcademicsSlider from './AcademicsSlider/AcademicsSlider';
import StudentsSlider from './StudentSlider/StudentsSlider';
import Slider from "react-slick";
import ContributorService from '../../../api/services/ContributorService';


const AcademicDetail = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [place, setPlace] = useState('')
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState([]);
    const [professor, setProfessor] = useState([]);
    const [students, setStudents] = useState([]);
    const [imgUrl, setImgUrl] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [academics, setAcademics] = useState([]);
    const [gallerImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[2]);
        getList();
    }, []);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow:
            width >= 1025
                ? 4 : width >= 1024 && width <= 1024 ? 3
                    : width >= 768 && width <= 1023
                        ? 2
                        : 1,
        slidesToScroll: 1,
    };
    const getList = async () => {
        let it = [];
        await ContributorService.getContributorsList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("result", result)
                            if (result.code === 0) {
                                if (result.data) {
                                    console.log("location", props.location.pathname)
                                    result.data.academicsList.list.map((item) => {
                                        if (item.instituteName.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
                                            setTitle(item.instituteName)
                                            setPlace(item.place)
                                            setUrl(item.url)
                                            setImgUrl(item.imageUrl)
                                            console.log("length", imgUrl)
                                            setProfessor(item.professors ? JSON.parse(item.professors) : [])
                                            setStudents(item.students ? JSON.parse(item.students) : [])
                                            setDescription(item.description)
                                            console.log("academics professors", description)
                                        }
                                    });
                                    // debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    console.log("data", data)
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }

    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...gallerImgList];
        console.log("list", list)
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Partners"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-strip-partners"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >

                    <Row className="mx-0 pt-5">

                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                            <p className="mb-0 section-title align-left">
                                <Link to={`/contributor`} className="custom-link-item">{` Contributors > Academics >`}</Link>
                                <span className="fw-600 pl-2 text-capitalize">{`${title}`} </span>
                            </p>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pt-5">
                            <Row className="mb-0 mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-2">
                                    <div >
                                        <p className="p-medium section-title nunitoSans-semiBold title-black pt-3 align-left">{title}</p>
                                        <p className="h6-small section-title jostRegular align-left title-black pt-3">{place}</p>
                                        <a href={`${url}`} rel="noreferrer" target="_blank" className="h6-small section-title jostLight align-left pt-2 ptitle-black custom-reference-links ">
                          {url}</a>
                                        <p className="p-large title-black section-title jostLight align-left pt-2">{description}</p>
                                        {imgUrl !== '' && imgUrl !== null?
                                                <div className=" gallery-card-img-container pt-3 align-left"> 
                                                <img className='large-img' src={`${ApiConfig.url}${imgUrl}`} alt="" />
                                                </div>
                                                 : null}

                                    </div>
                                </Col>

                                <Col
                                    xs={12} sm={12} md={12} lg={12} xl={12}
                                    className="m-auto pt-4 pb-2"
                                >
                                    <Row>
                                        {professor.length > 0 ?
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-5">
                                                {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h6-small align-left title-black jostBold">Professors</h6> :
                                                    <h3 className="h6-small align-left title-black jostBold">Professors</h3>}
                                            </Col> : null}
                                    </Row>
                                    {width <= application_contants.MOBILE_BREAKPOINT ?
                                        <Slider {...settings}>
                                            {professor.map((item, index) => (
                                                <div xs={12} sm={12} md={12} lg={12} xl={12} className="academics-people">
                                                    <AcademicsSlider item={item} />
                                                </div>
                                            ))}
                                        </Slider> :
                                        <Row>
                                            {professor.map((item, index) => (
                                                <Col xs={12} sm={12} md={12} lg={3} xl={3} className="academics-people">
                                                    <AcademicsSlider item={item} />
                                                </Col>
                                            ))}
                                        </Row>}
                                </Col>
                                <Col
                                    xs={12} sm={12} md={12} lg={12} xl={12}
                                    className="m-auto pt-4 pb-2"
                                >
                                    <Row>
                                        {students.length > 0 ?
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-4">
                                                {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h6-small title-black jostBold align-left">Students</h6> :
                                                    <h3 className="h6-small title-black jostBold align-left">Students</h3>}
                                            </Col> : null}
                                    </Row>
                                    {width <= application_contants.MOBILE_BREAKPOINT ?
                                        <Slider {...settings}>
                                            {students.map((item, index) => (
                                                <div xs={12} sm={12} md={12} lg={4} xl={4} className="academics-people">
                                                    <StudentsSlider item={item} />
                                                </div>
                                            ))}
                                        </Slider>
                                        :
                                        <Row>
                                            {students.map((item, index) => (
                                                <Col xs={12} sm={12} md={12} lg={3} xl={3} className="academics-people">
                                                    <StudentsSlider item={item} />
                                                </Col>
                                            ))}
                                        </Row>}
                                </Col>
                                <Col className='pt-4'></Col>
                            </Row>
                        </Col>
                    </Row>

                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};

export default AcademicDetail;