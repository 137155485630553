//Action Types
export const LOGGED_IN = `auth/LOGGED_IN`;
export const LOGGED_OUT = `auth/LOGGED_OUT`;

export const initialState = {
  isLoading: true,
  isLoggedIn: false,
 // authToken: null,
  isSignout: false,
};

const AuthReducer = (prevState, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...prevState,
        isLoading: false,
        isLoggedIn: true,
        //authToken: action.authToken,
        isSignout: false,
      };
    case LOGGED_OUT:
      return {
        ...prevState,
        isLoading: false,
        isLoggedIn: false,
       // authToken: null,
        isSignout: true,
      };

    default:
      return prevState;
  }
};

export default AuthReducer;
