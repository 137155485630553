import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const UserService = {
    login: async (data) => {
        const { baseUrl, login } = ApiConfig;

        const url = baseUrl + login;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            userName: data.userName,
            emailId: data.emailId,
            password: data.password,
        });

        const loginServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_login",
            true
        );
        return loginServicePromise;
    },

    


};

export default UserService;
