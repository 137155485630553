import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Form,
  Label,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import OpeningService from "../../../../api/services/OpeningService";
import {
  alertDangerConfirmMessage,
  alertSuccessMessage,
} from "../../../customComponents/ConfirmationMessage/ConfirmationMessage";
import Loader from "../../../customComponents/Loader/Loader";
import Strip from "../../../customComponents/Strip/Strip";
import Select from "react-select";
import OpeningsType from "../../../Utils/json/OpeningsType";
import FileUploader from "../../../customComponents/FileUploader/FileUploader";
import Axios from "axios";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";
import { max } from "lodash";

const ResearchOpenings = (props) => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [projectDetail, setProjectDetail] = useState("");
  const [typeId, setTypeId] = useState("");
  const [openingType, setOpeningType] = useState("");
  const [pdfURL, setPdfURL] = useState("");
  const [openingTitle, setOpeningTitle] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [rolePoints, setRolePoints] = useState("");
  const [essentialDescription, setEssentialDescription] = useState("");
  const [essentailPoints, setEssentailPoints] = useState("");
  const [preferredDescription, setPreferredDescription] = useState("");
  const [preferredPoints, setPreferredPoints] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [netSalary, setNetSalary] = useState("");
  const [applicationLink, setApplicationLink] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  const [isFileChanged, setIsFileChanged] = useState(false);
  const { isModal, toggle, isAdd } = props;

  React.useEffect(() => {
    if (isAdd) {
      setDescription("");
      setTitle("");
      setTypeId("");
      setOpeningType("");
      setPdfURL("");
      setId("");
      setOpeningTitle("");
      setDate("");
      setProjectDetail("");
      setCode("");
      setRoleDescription("");
      setRolePoints("");
      setEssentialDescription("");
      setEssentailPoints("");
      setPreferredDescription("");
      setPreferredPoints("");
      setDuration("");
      setStartDate("");
      setNetSalary("");
      setApplicationLink("");
      setContactName("");
      setContactEmail("");
    } else {
      setDescription(props.item.description);
      setTitle(props.item.title);
      setId(props.item.id);
      const type = OpeningsType.find(
        (it) => it.value === parseInt(props.item.typeId)
      );
      setOpeningType(type);
      setTypeId(type);
      setPdfURL(props.item.pdfURL);
      setPdfFileName(props.item.pdfURL);
      setOpeningTitle(props.item.openingTitle);
      setCode(props.item.code);
      setDate(props.item.date);
      setProjectDetail(props.item.projectDetail);
      setRoleDescription(props.item.roleDescription);
      setRolePoints(props.item.rolePoints);
      setEssentialDescription(props.item.essentialDescription);
      setEssentailPoints(props.item.essentialPoints);
      setPreferredDescription(props.item.preferredDescription);
      setPreferredPoints(props.item.preferredPoints);
      setDuration(props.item.duration);
      setStartDate(props.item.startDate);
      setNetSalary(props.item.netSalary);
      setApplicationLink(props.item.applicationLink);
      setContactName(props.item.contactName);
      setContactEmail(props.item.contactEmail);
    }
  }, [isAdd, props]);

  const getPdfFile = async (item) => {
    await setPdfFile(item[0].file);
    await setPdfFileName(item[0].file.name);
    setIsFileChanged(true);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleTypeId = (item) => {
    setTypeId(item);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDate = (e) => {
    setDate(e.target.value);
  };
  const handleProjectDetail = (e) => {
    setProjectDetail(e.target.value);
  };

  const handleOpeningTitle = (e) => {
    setOpeningTitle(e.target.value);
  };

  const handleCode = (e) => {
    setCode(e.target.value);
  };

  const handleRoleDescription = (e) => {
    setRoleDescription(e.target.value);
  };

  const handleRolePoints = (e) => {
    setRolePoints(e.target.value);
  };

  const handleEssentialDescription = (e) => {
    setEssentialDescription(e.target.value);
  };

  const handleEssentailPoints = (e) => {
    setEssentailPoints(e.target.value);
  };

  const handlePreferredDescription = (e) => {
    setPreferredDescription(e.target.value);
  };

  const handlePreferredPoints = (e) => {
    setPreferredPoints(e.target.value);
  };

  const handleDuration = (e) => {
    setDuration(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleNetSalary = (e) => {
    setNetSalary(e.target.value);
  };

  const handleLink = (e) => {
    setApplicationLink(e.target.value);
  };

  const handleContactName = (e) => {
    setContactName(e.target.value);
  };

  const handleContactEmail = (e) => {
    setContactEmail(e.target.value);
  };
  const handleSubmit = async () => {
    const userId = await localStorage.getItem("userId");
    let data = {
      userId: userId,
      id: id,
      openingTitle: openingTitle,
      typeId: typeId ? typeId.value : "",
      pdfURL: isFileChanged ? `/opening/${pdfFileName}` : pdfFileName,
      title: title,
      code: code,
      description: description,
      roleDescription: roleDescription,
      rolePoints: rolePoints,
      essentialDescription: essentialDescription,
      date: date,
      projectDetail: projectDetail,
      essentailPoints: essentailPoints,
      preferredDescription: preferredDescription,
      preferredPoints: preferredPoints,
      duration: duration,
      startDate: startDate,
      netSalary: netSalary,
      applicationLink: applicationLink,
      contactName: contactName,
      contactEmail: contactEmail,
    };
    if (isFileChanged) {
      UploadFiles(pdfFile, data);
    } else {
      insertOrUpdate(data);
    }
  };
  const UploadFiles = async (file, insertOrUpdateData) => {
    const data = await new FormData();
    await data.append("floderPath", "uploads/opening/");
    await data.append("image", file);
    setIsLoading(true);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    console.log("pdf : ", data);
    Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            if (res.data.code === 0) {
              await insertOrUpdate(insertOrUpdateData);
            } else {
              setIsLoading(false);
              alertDangerConfirmMessage(`${res.data.message}`);
            }
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log("We will be back soon.");
        return err;
      });
  };
  const insertOrUpdate = async (data) => {
    await OpeningService.insertOrUpdate(data)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              console.log();
              if (result.code === 0) {
                alertSuccessMessage(`${result.message}`);
                props.handleRefresh();
                setIsLoading(false);
              } else {
                setIsLoading(false);
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Strip
      id="tst-add-opening"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Modal
            isOpen={isModal}
            toggle={toggle}
            className="modal-container modal-xl"
          >
             <div className="modal-header-container bg-theme-blue">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD OPENINGS" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
            <ModalBody className="modal-content-container">
              {typeId.value === 2 ? (
                <Row>
                  <Col
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="m-auto">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-3 pb-5"
                      >
                        <Row className="mt-2">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Openings Type:
                                  </Label>
                                  <Select
                                    value={typeId}
                                    onChange={handleTypeId}
                                    options={OpeningsType}
                                    placeholder={"Select Openings"}
                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                    isSearchable={true}
                                    isClearable
                                    maxMenuHeight={200}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Opening Title*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="Opening Title"
                                    id="opening title"
                                    name="opening title"
                                    className="form-control common-form-control"
                                    value={openingTitle}
                                    onChange={(event) =>
                                      handleOpeningTitle(event)
                                    }
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Upload Report File :
                                  </Label>
                                  <FileUploader
                                    isImg={false}
                                    getPdfFile={(item) => getPdfFile(item)}
                                    value={pdfFileName}
                                    isEditable={false}
                                    labelName="Upload Report File"
                                    maxSize={1024}
                                    minSize={0}
                                    
                                  />
                                </Col>
                              </FormGroup>
                              {/* <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Date*:
                                  </Label>
                                  <Input
                                    type="date"
                                    placeholder="date"
                                    id="opening date"
                                    name="opening date"
                                    className="form-control common-form-control"
                                    value={date}
                                    onChange={(event) => handleDate(event)}
                                    //rows={10}
                                  />
                                </Col>
                              </FormGroup> */}
                            </Form>
                          </Col>
                        </Row>
                        <Row className="no-margin">
                          <Col
                            xs={12}
                            sm={12}
                            md={{ offset: 3, size: 6 }}
                            lg={{ offset: 3, size: 6 }}
                            xl={{ offset: 3, size: 6 }}
                          >
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                  onClick={toggle}
                                >
                                  Cancel
                                </Button>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                  onClick={() => handleSubmit()}
                                  // disabled={
                                  //     !description &&
                                  //     !photo
                                  // }
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    className="m-auto"
                  >
                    <Row className="m-auto">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pt-3 pb-5"
                      >
                        <Row className="mt-2">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Openings Type:
                                  </Label>
                                  <Select
                                    value={typeId}
                                    onChange={handleTypeId}
                                    options={OpeningsType}
                                    placeholder={"Select Openings"}
                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                    isSearchable={true}
                                    isClearable
                                    maxMenuHeight={200}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Opening Title*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="Opening Title"
                                    id="opening title"
                                    name="opening title"
                                    className="form-control common-form-control"
                                    value={openingTitle}
                                    onChange={(event) =>
                                      handleOpeningTitle(event)
                                    }
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Date*:
                                  </Label>
                                  <Input
                                    type="date"
                                    placeholder="date"
                                    id="opening date"
                                    name="opening date"
                                    className="form-control common-form-control"
                                    value={date}
                                    onChange={(event) => handleDate(event)}
                                    //rows={10}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Title*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="title"
                                    id="title"
                                    name="title"
                                    className="form-control common-form-control"
                                    value={title}
                                    onChange={(event) => handleTitle(event)}
                                    rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Position Code*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="text"
                                    id="code"
                                    name="title"
                                    className="form-control common-form-control"
                                    value={code}
                                    onChange={(event) => handleCode(event)}
                                    rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    About Description*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="description"
                                    id="description"
                                    name="description"
                                    className="form-control common-form-control"
                                    value={description}
                                    onChange={(event) =>
                                      handleDescription(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Project Detail*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="date"
                                    id="opening date"
                                    name="opening date"
                                    className="form-control common-form-control"
                                    value={projectDetail}
                                    onChange={(event) =>
                                      handleProjectDetail(event)
                                    }
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    job Description*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="job Description"
                                    id="roleDescription"
                                    name="roleDescription"
                                    className="form-control common-form-control"
                                    value={roleDescription}
                                    onChange={(event) =>
                                      handleRoleDescription(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    job Points*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="job Points"
                                    id="Role Points"
                                    name="Role Points"
                                    className="form-control common-form-control"
                                    value={rolePoints}
                                    onChange={(event) =>
                                      handleRolePoints(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Eligibility*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="Eligibility Points"
                                    id="Essential Points"
                                    name="Essential Points"
                                    className="form-control common-form-control"
                                    value={essentailPoints}
                                    onChange={(event) =>
                                      handleEssentailPoints(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Qualification*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="Qualification  Points"
                                    id="Preferred Points"
                                    name="Preferred Points"
                                    className="form-control common-form-control"
                                    value={preferredPoints}
                                    onChange={(event) =>
                                      handlePreferredPoints(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Duration*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="Duration points"
                                    id="essentialDescription"
                                    name="essentialDescription"
                                    className="form-control common-form-control"
                                    value={essentialDescription}
                                    onChange={(event) =>
                                      handleEssentialDescription(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Duration*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="duration"
                                    id="duration"
                                    name="duration"
                                    className="form-control common-form-control"
                                    value={duration}
                                    onChange={(event) => handleDuration(event)}
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Start Date*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="startDate"
                                    id="startDate"
                                    name="startDate"
                                    className="form-control common-form-control"
                                    value={startDate}
                                    onChange={(event) => handleStartDate(event)}
                                    //rows={10}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Net Salary*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="netSalary"
                                    id="netSalary"
                                    name="netSalary"
                                    className="form-control common-form-control"
                                    value={netSalary}
                                    onChange={(event) => handleNetSalary(event)}
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Application Procedure*:
                                  </Label>
                                  <Input
                                    type="textarea"
                                    placeholder="procedure"
                                    id="preferredDescription"
                                    name="preferredDescription"
                                    className="form-control common-form-control"
                                    value={preferredDescription}
                                    onChange={(event) =>
                                      handlePreferredDescription(event)
                                    }
                                    rows={10}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Application Link*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="link"
                                    id="link"
                                    name="link"
                                    className="form-control common-form-control"
                                    value={applicationLink}
                                    onChange={(event) => handleLink(event)}
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Contact Name*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="contactName"
                                    id="contactName"
                                    name="contactName"
                                    className="form-control common-form-control"
                                    value={contactName}
                                    onChange={(event) =>
                                      handleContactName(event)
                                    }
                                    //rows={10}
                                  />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                  <Label className="common-label text-left">
                                    Contact Email*:
                                  </Label>
                                  <Input
                                    type="text"
                                    placeholder="contactEmail"
                                    id="contactEmail"
                                    name="contactEmail"
                                    className="form-control common-form-control"
                                    value={contactEmail}
                                    onChange={(event) =>
                                      handleContactEmail(event)
                                    }
                                    //rows={10}
                                  />
                                </Col>
                              </FormGroup>
                            </Form>
                          </Col>
                        </Row>
                        <Row className="no-margin">
                          <Col
                            xs={12}
                            sm={12}
                            md={{ offset: 3, size: 6 }}
                            lg={{ offset: 3, size: 6 }}
                            xl={{ offset: 3, size: 6 }}
                          >
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                  onClick={toggle}
                                >
                                  Cancel
                                </Button>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                  className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                  onClick={() => handleSubmit()}
                                  // disabled={
                                  //     !description &&
                                  //     !photo
                                  // }
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {isLoading ? (
                <div className="loader-position">
                  <Loader containerClassName="" />
                </div>
              ) : null}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Strip>
  );
};

export default ResearchOpenings;
