import React, { useState } from 'react';
import { useWindowWidth } from "@react-hook/window-size";
import { Col, Row, Collapse, Card, CardBody, Table } from 'reactstrap';
import application_contants from '../../contants/application_contants';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import AboutData from '../../Utils/json/aboutData';


const About = () => {
    const width = useWindowWidth()
    const [currentIndex, setCurrentIndex] = useState(0);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCurrentIndex = (index) => {
        setCurrentIndex(currentIndex === index ? -1 : index)
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-about"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-strip-about"
                    className="strip strip-no-padding px-0"
                    containerType={`${width <= application_contants.MOBILE_BREAKPOINT ? 'container-fluid' : 'container'}`}
                >
                    <Row className="mx-0 pt-5 pb-5">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'px-0' : ''}`}>
                            {AboutData.length > 0 && AboutData.map((item, index) => (
                                <Row key={index} className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} onClick={() => handleCurrentIndex(index)} className="cursor-pointer px-0">
                                        <CardBody className="p-0">
                                            <div className={`d-flex align-items-center justify-content-between py-3 ${width <= application_contants.MOBILE_BREAKPOINT ? 'px-3' : ''} ${currentIndex === index ? '' : 'br-bt-white-smoke'}`}>
                                                <div>
                                                    <h2 className="h2-small section-title align-left title-navy-blue-medium jostRegular">{item.title}</h2>
                                                </div>
                                                <div>
                                                    <img src={currentIndex === index ? application_contants.UPARROW : application_contants.DOWNARROW} alt="" className="width-32px" />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'px-0' : 'px-0'}`}>
                                        <Collapse isOpen={currentIndex === index}>
                                            <Card className="card-container ">
                                                <CardBody>
                                                    {item.desc.length > 0 && item.desc.map((it, ind) => (
                                                        <div>
                                                            {it.link ?
                                                                <p className={`p-small section-title align-left title-navy-blue-medium jostLight lh-25px ${it.isPadding ? 'pb-3' : ''}`} key={ind}>{it.params} -
                                                                    <span> <a href={`${it.link}`} rel="noreferrer" target="_blank">{it.link}</a></span>
                                                                </p>
                                                                :
                                                                <p className={`p-small section-title align-left title-navy-blue-medium jostLight lh-25px ${it.isPadding ? 'pb-3' : ''}`} key={ind}>{it.params}</p>
                                                            }
                                                            {it.isTable ?
                                                                <div>
                                                                    <p className={`p-small section-title align-left title-navy-blue-medium jostLight lh-25px ${it.isPadding ? 'pb-3' : ''}`} key={ind}>{it.title}</p>
                                                                    <Table className="custom-about-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="p-small section-title align-left title-navy-blue-medium">Language</th>
                                                                                <th className="p-small section-title align-left title-navy-blue-medium">TTS System</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {it.data.length > 0 && it.data.map((t, index) => (
                                                                                <tr>
                                                                                    <td className="p-small section-title align-left title-navy-blue-medium jostLight">{t.lang}</td>
                                                                                    <td className="p-small section-title align-left title-navy-blue-medium jostLight">{t.info}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    ))}
                                                </CardBody>
                                            </Card>
                                        </Collapse>

                                    </Col>
                                </Row>

                            ))}
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};

export default About;