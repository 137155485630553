import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { useWindowSize } from "@react-hook/window-size";
import Strip from "../../customComponents/Strip/Strip";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import ExternalLayout from "../../customComponents/ExternalLayout/ExternalLayout";
import application_contants from "../../contants/application_contants";
import { ApiConfig} from '../../../api/apiConfig/apiConfig';
import PartnerService from "../../../api/services/PartnerService";
import SEOComponent from "../../customComponents/SEO/SEOComponent";

const Funding = (props) => {
  const [width] = useWindowSize();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPartnerList();
  }, [])

  const getPartnerList = async () => {
    setIsLoading(true);
    await PartnerService.getPartnerList()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                if (result.data) {
                  const lists = result.data.list.filter((it) => it.status === 1);
                //   debugLogger("partner", lists);
                  setData([...lists])
                  setIsLoading(false);
                } else {
                  setData([]);
                  setIsLoading(false);
                }
              } else {
                setData([]);
                setIsLoading(false);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <ExternalLayout>
      <RenderPage
        id="tst-funding"
        className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
        containerType="container-fluid"
      >
       <SEOComponent
                    title="Funding"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/funding" />
        <Strip
          id="tst-funding"
          className="strip strip-no-padding"
          containerType="container"
        >
          
          <Row className="mx-0">
            <Col xs={11} sm={11} md={11} lg={12} xl={12} className="m-auto px-0">
              <Row>
                <Col xs={12} sm={12} md={11} lg={12} xl={12} className="pt-5 pb-4">
                  {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title title-navy-blue align-left pb-3">Partners</h5> :
                    <h1 className="h5-medium section-title title-navy-blue align-left pb-3">Partners</h1>}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {data.length > 0 && data.map((item, index) => (
                    <Row key={index}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <Col xs={6} sm={2} md={2} lg={2} xl={6} className="gallery-card-img-container ">
                            <a href={`${item.link}`} target="_blank" rel="noreferrer" className="custom-reference-links">
                              <img src={`${ApiConfig.url}${item.imgList}`} alt="" />
                            </a>
                          </Col>
                          
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4 pb-4">
                        <Row>
                          {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {item.description.split(/[\n\r]/g).map((params, i) => (
                              <p className="mb-0 h6-small jostBold section-title pb-2 align-left" key={i}>{params}</p>
                            ))}
                          </Col> */}
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className="mb-0 p-large jostLight section-title pb-2 align-left">
                              <a href={`${item.link}`} target="_blank" rel="noreferrer" className="custom-reference-links title-blue">
                              {item.description.split(/[\n\r]/g).map((params, i) => (
                              <p className="mb-0 h6-small jostBold section-title pb-2 align-left" key={i}>{params}</p>
                            ))}
                            {/* {item.linkText} */}
                            </a>
                            </p>

                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={11} lg={12} xl={12} className="pt-5 pb-5">
                    <h1 className="h5-medium section-title title-navy-blue align-left ">{item.supportedBy}</h1>
                </Col>

                    </Row>
                  ))}
                </Col>
                
                {/* <h1 className="h5-medium section-title title-navy-blue align-left pb-3">Partners</h1> */}


              </Row>
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    </ExternalLayout>
  );
};

Funding.defaultProps = {
  isClassName: true,
};

Funding.propTypes = {
  isClassName: PropTypes.bool,
};

export default Funding;
