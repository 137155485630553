import React from 'react';
import { Media } from 'reactstrap';

import media_img from '../../../../../assets/Media/mediaimages/IMG-20210930-WA0000.jpg'


console.log(media_img); 

function mediaimg() {
  // Import result is the URL of your image
  return <img src={media_img} width="100%" height="100%" />;
}
export default  mediaimg ;