import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authenticated = (props) => {

    const { authenticated, component, path, exact, ...rest } = props;

    return (
        <Route
            path={path}
            exact={exact}
            render={(props) =>
                authenticated ? (
                    React.createElement(component, {
                        ...props,
                        ...rest,
                        authenticated,
                    })
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

Authenticated.defaultProps = {
    path: "",
    exact: false,
    authenticated: true,
};

Authenticated.propTypes = {
    history: PropTypes.object,
    authenticated: PropTypes.bool,
    component: PropTypes.func.isRequired,
    path: PropTypes.string,
    exact: PropTypes.bool,
};

export default Authenticated;