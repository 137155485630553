const OpeningsType = [
    {
        label: "Research Openings",
        value: 1
    },
    {
        label: "Other Opennings",
        value: 2
    },
];
export default OpeningsType;