const ContributorsType = [
    {
        label: "Academic",
        value: 1
    },
    {
        label: "Industry",
        value: 2
    },
    {
        label: "Individual",
        value: 3
    },
    {
        label: "Experts",
        value: 4
    }
];

export default ContributorsType;