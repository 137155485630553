import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ContactUsService = {
    contactUs: async (data) => {
        const { baseUrl, contactUs } = ApiConfig;

        const url = baseUrl + contactUs;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            fullName: `${data.fullName}`,
            organization: `${data.organization}`,
            emailId: `${data.emailId}`,
            phoneNumber: `${data.phoneNumber}`,
            comment: `${data.comment}`,
        });

        const contactUsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_contactUs",
            true
        );
        return contactUsServicePromise;
    },

};

export default ContactUsService;
