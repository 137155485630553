import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const PublicationService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertORUpdatePublication } = ApiConfig;

        const url = baseUrl + insertORUpdatePublication;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
  
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: `${data.title}`,
            author: `${data.author}`,
            description: `${data.description}`,
            date : `${data.date}`,
            link : `${data.link}`,
        });

        const publicationServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_publicationService",
            true
        );
        return publicationServicePromise;
    },

    getPublicationList: async () => {
        const { baseUrl, getPublicationList } = ApiConfig;

        const url = baseUrl + getPublicationList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const publicationServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_PublicationService",
            true
        );
        console.log("publicationServicePromise",publicationServicePromise)
        return publicationServicePromise;
    },

    enableDisablePublication: async (data) => {
        const { baseUrl, enableDisablePublication } = ApiConfig;
        const url = baseUrl + enableDisablePublication;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisablePublicationServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisablePublication",
            true
        );
        return enableDisablePublicationServicePromise;
    },

};

export default PublicationService;
