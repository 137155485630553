import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ContributorService = {
    insertOrUpdateForAcademics: async (data) => {
        const { baseUrl, insertORUpdateContributorsAcademics } = ApiConfig;

        const url = baseUrl + insertORUpdateContributorsAcademics;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId : data.userId,
            instituteName: data.instituteName,
            id : data.id,
            place : data.place,
            url : data.url,
            description : data.description,
            professors: `${data.professors}`,
            imageUrl: `${data.imageUrl}`,
            students: `${data.students}`
        });

        const contributorsAcademicsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_contributorsAcademicsService",
            true
        );
        return  contributorsAcademicsServicePromise;
    },
    insertOrUpdateForIndustry: async (data) => {
        const { baseUrl, insertORUpdateContributorsIndustry } = ApiConfig;

        const url = baseUrl + insertORUpdateContributorsIndustry;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId : data.userId,
            instituteName: data.instituteName,
            id : data.id,
            place : data.place,
            url : data.url,
            description : data.description,
            imageUrl: `${data.imageUrl}`,
            industryPeople: data.industryPeople,
        });

        const contributorsIndustryServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_contributorsAcademicsService",
            true
        );
        return  contributorsIndustryServicePromise;
    },
    insertOrUpdateForIndividual: async (data) => {
        const { baseUrl, insertORUpdateContributorsIndividual } = ApiConfig;

        const url = baseUrl + insertORUpdateContributorsIndividual;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId : data.userId,
            id : data.id,
            language: data.language,
            name: data.name,
            imageUrl: data.imageUrl,
            designation: data.designation,
            description: data.description,
            url: data.url,
            // individualpeople : `${data.individualpeople}`,
        });

        const contributorsIndividualServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_contributorsAcademicsService",
            true
        );
        return  contributorsIndividualServicePromise;
    },
    insertOrUpdateForExperts: async (data) => {
        const { baseUrl, insertORUpdateContributorsExperts } = ApiConfig;

        const url = baseUrl + insertORUpdateContributorsExperts;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId : data.userId,
            id : data.id,
            language: data.language,
            name: data.name,
            imageUrl: data.imageUrl,
            designation: data.designation,
            discription: data.discription,
            url: data.url,
            // individualpeople : `${data.individualpeople}`,
        });

        const contributorsExpertsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_contributorsExpertsService",
            true
        );
        return  contributorsExpertsServicePromise;
    },
    getContributorsList: async () => {
        const { baseUrl, getContributorsList } = ApiConfig;

        const url = baseUrl + getContributorsList;
        console.log('url');
        console.log(url)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const getContributorsListPromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_opening",
            true
        );
        console.log("getContributorsListPromise",getContributorsListPromise)
        return getContributorsListPromise;
    },

    enableDisableContributorsAcademics: async (data) => {
        const { baseUrl, enableDisableContributorsAcademics } = ApiConfig;
        const url = baseUrl + enableDisableContributorsAcademics;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableContributorsAcademicsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableContributorsAcademics",
            true
        );
        return enableDisableContributorsAcademicsServicePromise;
    },
    enableDisableContributorsIndividual: async (data) => {
        const { baseUrl, enableDisableContributorsIndividual } = ApiConfig;
        const url = baseUrl + enableDisableContributorsIndividual;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableContributorsIndividualServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableContributorsIndividual",
            true
        );
        return enableDisableContributorsIndividualServicePromise;
    },
    enableDisableContributorsExperts: async (data) => {
        const { baseUrl, enableDisableContributorsExperts } = ApiConfig;
        const url = baseUrl + enableDisableContributorsExperts;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableContributorsExpertsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableContributorsExperts",
            true
        );
        return enableDisableContributorsExpertsServicePromise;
    },
    enableDisableContributorsIndustry: async (data) => {
        const { baseUrl, enableDisableContributorsIndustry } = ApiConfig;
        const url = baseUrl + enableDisableContributorsIndustry;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableContributorsIndustryServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableContributorsIndustry",
            true
        );
        return enableDisableContributorsIndustryServicePromise;
    },

}

export default ContributorService;