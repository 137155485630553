import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from "../../contants/application_contants";
import { useWindowSize } from "@react-hook/window-size";
import OpeningService from '../../../api/services/OpeningService';
import OpeningComponent from '../../customComponents/OpeningComponent/OpeningComponent'
import SEOComponent from '../../customComponents/SEO/SEOComponent';

const Openings = (props) => {
    const [width] = useWindowSize();

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-openings"
                className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                 <SEOComponent
                    title="openings"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/openings" />
                <Strip
                    id="tst-strip-openings"
                    className="strip strip-no-padding"
                    containerType="container">
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Openings</h5> :
                                        <h1 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Openings</h1>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-5">
                                    <OpeningComponent firstSectionTitle={`Research Openings`} secondSectionTitle={`General Openings`} isSecond={true}/>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout >
    );
};

Openings.defaultProps = {
    isClassName: true,
};

Openings.propTypes = {
    isClassName: PropTypes.bool,
};
export default Openings;