import React, { useState } from 'react';
import Select from "react-select";
import Axios from "axios";
import { ModalBody, Row, Col, Modal, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import FileUploader from '../../../customComponents/FileUploader/FileUploader';
import Loader from '../../../customComponents/Loader/Loader';
import Strip from '../../../customComponents/Strip/Strip';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponents/ConfirmationMessage/ConfirmationMessage';
import PartnerService from '../../../../api/services/PartnerService';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from '@material-ui/icons/Delete';

const AddPartner = (props) => {
    const [photo, setPhoto] = useState('');
    const [photoFileName, setPhotoFileName] = useState('');
    const [photoFile, setPhotoFile] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState('');
    const [linkText, setLinkText] = useState('');
    const [fileType, setFileType] = useState('image/jpeg, image/jpg, image/png');
    const [galleryList, setGalleryList] = useState([]);
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setDescription('');
            setPhotoFileName('');
            setId('');
            setPhoto('')
            setLink('');
            setLinkText('');
        } else {
            setDescription(props.partnerItem.description);
            setPhotoFileName(props.partnerItem.imgPath);
            setId(props.partnerItem.id)
            setPhoto(props.partnerItem.imgPath)
            setLink(props.partnerItem.link)
            setLinkText(props.partnerItem.linkText)
        }
        setIsFileChanged(false);
    }, [isAdd, props]);


    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleLink = (event) => {
        setLink(event.target.value);
    }

    const handleLinkText = (event) => {
        setLinkText(event.target.value);
    }

    const getGalleryList = (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setGalleryList([...galleryList, ...item])
                }
            };
            reader.readAsDataURL(file);
        });
    }

    const getThumbnail = (item) => {
        setPhoto(item[0].base64);
        setPhotoFile(item[0].file);
        setPhotoFileName(item[0].file.name);
        setIsFileChanged(true);
    };

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            imgList: isFileChanged ? `/partner/${photoFileName}` : photoFileName,
            description: description,
            userId: userId,
            id: id,
            link: link,
            linkText: linkText
        };

        if (isFileChanged) {
            UploadImage(photoFile, data)
        } else {
            insertOrUpdate(data);
        }
    }
    console.log("galleryList",galleryList)
    const handleGalleryImageDelete = (index) => {
        galleryList.splice(index, 1);
        setGalleryList([...galleryList]);
    }

    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/partner/");
        await data.append("image", file);
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        console.log('photos : ', data);
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };


    const insertOrUpdate = async (data) => {
        await PartnerService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-people"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-dark-pink">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                            <Row>
                                <Col xs={10} sm={10} md={10} lg={6} xl={6} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>

                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Description*:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="description"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={description}
                                                                    onChange={(event) => handleDescription(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Link*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="link"
                                                                    id="link"
                                                                    name="link"
                                                                    className="form-control common-form-control"
                                                                    value={link}
                                                                    onChange={(event) => handleLink(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    LinkText*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="linkText"
                                                                    id="linkText"
                                                                    name="linkText"
                                                                    className="form-control common-form-control"
                                                                    value={linkText}
                                                                    onChange={(event) => handleLinkText(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Row>
                                                        <Label className="common-label text-left">
                                                                Upload Image:
                                                                </Label>
                                                                </Row>
                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                                className="d-flex align-items-center px-5"
                                                            >
                                                                
                                                                {!photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={false}
                                                                    //isDefault={isDefaultThumbnail}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={`${isFileChanged ? `${photo}` : `${ApiConfig.url}${photo}`}`}
                                                                        alt={""}
                                                                    />
                                                                )}
                                                                {photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={true}
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </FormGroup>
                                                        {/* <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Upload Gallery Images* :
                                                                </Label>
                                                                <div>
                                                                </div>
                                                                <div
                                                                    className={`position-relative`}
                                                                >
                                                                    <div className="file-form-container">
                                                                        <input type="file" value={""} placeholder="Upload File" multiple={true} accept={`${fileType}`} className="form-control common-form-control" onChange={getGalleryList} />
                                                                        <p className="file-text">{galleryList.length > 0 ? `${galleryList.length} uploaded` : 'upload Files'}</p>
                                                                    </div>

                                                                    <div className="pdf-thumbnail-container">
                                                                        <CloudUploadIcon
                                                                            style={{
                                                                                color: "#fff",
                                                                                fontSize: "2rem",
                                                                                borderRadius: 4,
                                                                                padding: 2,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </FormGroup> */}
                                                        {galleryList.length > 0 && fileType !== 'application/pdf' ?
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            {galleryList.map((it, index) => (
                                                                <Col xs={6} sm={6} md={3} lg={3} xl={3} key={index} className="mb-2">
                                                                    <div className="position-relative">
                                                                        <img src={it.imgPath ? `${ApiConfig.URL}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                                                        <div className="trash-icon-container" onClick={() => handleGalleryImageDelete(index)}>
                                                                            <DeleteIcon style={{
                                                                                color: "#fff",
                                                                                fontSize: "1rem",

                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </FormGroup>
                                                        : null}
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 4, size: 6 }}
                                                    lg={{ offset: 4, size: 6 }}
                                                    xl={{ offset: 4, size: 6 }}
                                                >
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                          </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button

                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                                disabled={
                                                                    !description &&
                                                                    !photo
                                                                }
                                                            >
                                                                Submit
                                                         </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}

export default AddPartner;