
const TermsAndConditionsData =[
    {
        desc: `These terms and conditions (Terms) govern the use of https://respin.iisc.ac.in/ (Website) and constitute a binding and enforceable contract between us (defined below) and you (defined below). These Terms may be updated by us from time to time and the Terms, as amended, shall apply to you. You may always review the most current version of the Terms on the Website. Please do not use the Website if you do not agree to these Terms.`,
        terms: [
            {
                title: `GENERAL TERMS`,
                desc: ``,
                conditions: [
                    {
                        params: `The Website is owned by the Indian Institute of Science, Bengaluru, with its office located at CV Raman Road, Bengaluru, Karnataka - 560012 ('we' or 'us').`,
                    },
                    {
                        params: `Within these Terms, 'user' or 'you' shall mean any person or entity accessing or using the Website.`,
                    },
                    {
                        params: `You agree and acknowledge that by accessing the Website, you (i) are at least 18 years, and (ii) have read, understood, and are bound by the Terms.`,
                    },
                    {
                        params: `The Terms are subject to revision at any time, as determined by us, and all changes are effective immediately upon being posted on the Website. It is your responsibility to review these Terms periodically for any updates or changes.`,
                    },
                    {
                        params: `You agree to not use the Website for any purpose that is prohibited by these Terms or law. Expect as otherwise permitted by these Terms, you are not allowed to copy, modify, distribute, reproduce, publish, license, transfer, or otherwise use in any manner, in whole or in part, this Website or the information and materials on this Website.`,
                    }
                ]
            },
            {
                title: `PURPOSE OF THE WEBSITE`,
                desc: `The Website contains information relevant to some of the projects we are working on. No information available on or through the Website should be construed as a commercial offer, nor does the availability of such information create any professional relationship between you and us.`,
                conditions: []
            },
            {
                title: `USER INFORMATION`,
                desc: ``,
                conditions: [
                    {
                        params: `The Website allows you to share your personal information with us (User Information). Please note that your User Information will only be used by us to engage and interact with you.`,
                    },
                    {
                        params: `The User Information collected is as follows:`,
                        isSubParams: true,
                        subParams: [
                            {
                                params: `First name`,
                            },
                            {
                                params: `Last name`,
                            },
                            {
                                params: `Organisation`,
                            },
                            {
                                params: `Email address`,
                            },
                            {
                                params: `Phone number`,
                            },
                            {
                                params: `From the above, point (c) and (e) are not mandatory fields i.e., you can provide the information at your discretion.`
                            }
                        ]
                    },
                    {
                        params: `User Information shall not be shared with third parties, except as may be required under applicable laws. Further, User Information shall be shared with employees only on a need-to-know basis.`,
                    },
                    {
                        params: `You represent and warrant that your User Information is true, complete, accurate in all respects. You should immediately contact us at the details provided below if your User Information undergoes any change.`,
                    }
                ]
            },
            {
                title: `ACCURACY OF INFORMATION`,
                desc: `We make no representation or warranty about the validity, accuracy, correctness, reliability of any information provided on or through the Website. We hereby disclaim all implied representations, warranties, or guarantees as to the accuracy, validity, reliability or completeness of any such information and material on the Website.`,
                conditions: [],
            },
            {
                title: `PROPRIETARY RIGHTS`,
                desc: ``,
                conditions: [
                    {
                        params: `The Website contains copyrighted material, trademarks, and other proprietary information that belongs to us or our licensors, and you agree not to use or otherwise reproduce separately, our proprietary rights from the textual content associated with them without obtaining our prior written consent. You agree to not remove, obscure, or otherwise alter any proprietary notices appearing on any content on the Website, including copyright, trademark, and other intellectual property notices.`
                    },
                    {
                        params: `You agree that you shall not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by us from accessing the Website (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (for example, by masking your IP address or using a proxy IP address). Any use of the Website other than as specifically authorised by us is strictly prohibited.`
                    }
                ],
            },
            {
                title: `THIRD PARTY WEBSITES OR SERVICES`,
                desc: `The Website may provide or facilitate, or third parties may provide, links or access to other sites, services, and resources (Third Party Services). We have no control over such Third Party Services and we are not responsible for and do not endorse such Third Party Services. You further acknowledge and agree that we will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, events, goods, or services available on or through any Third Party Services. Any dealings you have with third parties while using the Website are between you and the third party, and you agree that we shall not be liable for any loss or claim that you may have against any such third party.`,
                conditions: []
            },
            {
                title: `WEBSITE ACCESS`,
                desc: ``,
                conditions: [
                    {
                        params: `By using the Website, you agree not to undertake certain activities which include but are not limited to:`,
                        isSubParams: true,
                        subParams: [
                            {
                                params: `Infringing any proprietary rights, including but not limited to copyrights, patents, trademarks or trade secrets, of any party;`,
                            },
                            {
                                params: `Except as may be provided hereunder, copying, displaying, distributing, modifying, publishing, reproducing, storing, transmitting, posting, translating, creating any derivative works, renting, or licensing the Website or any portion thereof;`,
                            },
                            {
                                params: `Downloading or copying any kind of information for the benefit of another individual, vendor or any other third party;`,
                            },
                            {
                                params: `Uploading, posting, or transmitting any information through the Website you do not have a right to make available (such as intellectual property of another party);`,
                            },
                            {
                                params: `Uploading, posting, or transmitting any material that contains software or any virus or any other computer code, files or programmes designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;`,
                            },
                            {
                                params: `Undertaking any action that imposes or may impose, in our sole discretion, an unreasonable or disproportionately large load on our infrastructure;`,
                            },
                            {
                                params: `Use of data mining, robots, or similar data gathering and extraction tools;`,
                            },
                            {
                                params: `Making any back-up or archival copies of the Website or any part thereof;`,
                            },
                            {
                                params: `Bypassing any measures used by us to prevent or restrict access to the Website;`,
                            },
                            {
                                params: `Accessing, monitoring or copying any information on the Website using any robot, spider, scraper, or other automated means of any manual process for any purpose without our express written permission;`,
                            },
                            {
                                params: `Violating the restrictions in any robot exclusion headers on the Website or bypassing or circumventing other measures employed to prevent or limit access to the Website;`,
                            },
                            {
                                params: `Deep-linking to any portion of the Website (including, without limitation, the purchase path for any service) for any purpose without our express written permission; or`,
                            },
                            {
                                params: `'Framing', 'mirroring', or otherwise incorporating any part of the Website into any other website without our prior written authorisation.`,
                            },
                        ]
                    }
                ]
            },
            {
                title: `INDEMNITY AND RELEASE`,
                desc: `You agree to release, indemnify, and hold us and our affiliates and its officers, employees, directors and agents harmless from any and all losses, damages, expenses, including reasonable attorneys' fees, rights, claims, actions of any kind and injury arising out of or relating to these Terms or your use of the Website.`,
                conditions: []
            },
            {
                title: `LIMITATION OF LIABILITY`,
                desc: ``,
                conditions: [
                    {
                        params:`To the fullest extent permitted by applicable law, you hereby expressly agree to defend, indemnify, and hold harmless us, our affiliates, officers, agents, and successors and assigns against any and all claims, liabilities, damages, losses, costs and expenses, including reasonable attorney's fees, caused by or arising out of claims based upon any breach by you of these Terms or applicable law.`,
                    },
                    {
                        params: `In no event shall we be liable to compensate you or any third party for any direct, indirect, special, incidental, consequential, or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not you have been advised of the possibility of such damages, based on any theory of liability, including breach of contract or warranty, negligence, or other tortuous action, or any other claim arising out of or in connection with your use of or access to the Website.`,
                    }
                ]
            },
            {
                title: `TERMINATION`,
                desc: ``,
                conditions: [
                    {
                        params: `You agree that we may, in our sole discretion, suspend or terminate your use of the Website for any reason, including without limitation, if we believe that you have violated or acted inconsistently with the letter or spirit of these Terms. We may also at our sole discretion and at any time discontinue the provision of the Website, or any part thereof, with or without notice. You agree that we will not be liable to you or any third party for termination of your access to the Website.`,
                    },
                    {
                        params: `Upon termination, these Terms shall terminate, except for those clauses that are intended to survive expiry or termination.`,
                    }
                ]
            },
            {
                title: `NO WAIVER AND SEVERABILITY`,
                desc: `You agree that no action of ours, other than an express written waiver or amendment, may be interpreted as a waiver or amendment of any of these Terms. In the event any clause of these Terms is found to be unenforceable, then whenever possible, this will not affect any other clause, and each shall remain in full force and effect.`,
                conditions: []
            },
            {
                title: `GOVERNING LAW AND DISPUTE RESOLUTION`,
                desc: `You agree that the laws of India shall govern these Terms, and that any dispute arising out of or relating to these Terms shall be referred to and finally resolved by a sole arbitrator mutually appointed by us and you. The arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996 or any statutory amendments or re-enactments for the time being in force. The venue of the arbitration shall be Bengaluru. The language of the arbitration shall be English. Subject to the above, the courts at Bengaluru shall have exclusive jurisdiction over any dispute arising out of these Terms.`,
                conditions: []
            },
            {
                title: `COOKIES`,
                desc: `No cookies are used in this Website:`,
                conditions: [
                    // {
                    //     params: `We use cookies, pixels and other technologies (collectively 'Cookies') to improve your experience and analyse website traffic. To that end, we may share your site usage data with third parties analytics partners.`,
                    // },
                    // {
                    //     params: `No cookies are used in this Website:`,
                    //     isSubParams: true,
                    //     subParams: [
                    //         {
                    //             params: `[Trilegal Note: Please insert the relevant Cookies which are used on the Website.]`
                    //         }
                    //     ]
                    // }
                ]
            },
            {
                title: `COMMUNICATION`,
                desc: `You may communicate with us, including contacting us for any grievance that you experience in connection with the Website, at -`,
                conditions: [
                    {
                        params: `Name: SPIRE Lab, EE Dept., IISc`
                    },
                    {
                        params: `Email: contact.syspin@iisc.ac.in`,
                    }
                ]
            }
        ]
    }
];


export default TermsAndConditionsData