import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Form, Label, Button, FormGroup, Input } from 'reactstrap';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponents/ConfirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponents/Loader/Loader';
import Strip from '../../../customComponents/Strip/Strip';
import MediaService from '../../../../api/services/MediaService';
import MediaLinkDetails from './MediaLinkDetails'

const AddMedia = (props) => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [mediaDetails, setMediaDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setTitle('');
            setMediaDetails([]);
        } else {
            setId(props.item.id);
            setTitle(props.item.title)
            setMediaDetails(props.item.mediaList ? JSON.parse(props.item.mediaList) : [])
        }
    }, [isAdd, props]);

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleMediaDetails = (item) => {
        setMediaDetails([...item])
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            userId: userId,
            id: id,
            title: title,
            mediaList: JSON.stringify(mediaDetails),
        }
        await MediaService.insertOrUpdate(data)
            .then(async (res) => {
                console.log("data",data)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log()
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-opening"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-theme-blue">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                                <Row>
                                    <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                        <Row className="m-auto">
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="pt-3 pb-5"
                                            >
                                                <Row className="mt-2">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Form>
                                                            <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Media Title*:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="link"
                                                                        id="link"
                                                                        name="link"
                                                                        className="form-control common-form-control"
                                                                        value={title}
                                                                        onChange={(event) => handleTitle(event)}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup className="align-items-center mt-3">
                                                                <MediaLinkDetails handleMediaDetails={handleMediaDetails} mediaDetails={mediaDetails} />
                                                            </FormGroup>
                                                      </Form>
                                                    </Col>
                                                </Row>
                                                <Row className="no-margin">
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={{ offset: 3, size: 6 }}
                                                        lg={{ offset: 3, size: 6 }}
                                                        xl={{ offset: 3, size: 6 }}
                                                    >
                                                        <Row className="pt-4">
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Button
                                                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                    onClick={toggle}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Button

                                                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                    onClick={() => handleSubmit()}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}

                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}
export default AddMedia;