import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponents/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponents/SEO/SEOComponent";
import PropTypes from "prop-types";


// import Male Audio Samples
import audiom1 from "../../../assets/sampleDatasets/BengaliMale/Bengali_agri_m.wav";
import audiom2 from "../../../assets/sampleDatasets/BengaliMale/Bengali_book_m.wav";
import audiom3 from "../../../assets/sampleDatasets/BengaliMale/Bengali_fin_m.wav";


function Bengali_Male(){
    return( 
    <div className="container">
        <table>
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenRow"><ReactPlayer   
                        url={audiom1}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenRow"><p className="p-medium-line-spacing "> এখনও গ্রামগঞ্জ, শহরতলি এমনকী শহরেও অনেক মেয়ে মাসিকের সময় কাপড়ের ব্যবহার করেন। এটা একেবারেই স্বাস্থ্যকর নয়। তাই সবসময় কাপড়ের পরিবর্তে হাইজেনিক প্যাড ব্যবহার করুন। </p></td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddRow"><ReactPlayer   
                        url={audiom2}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddRow"><p className="p-medium-line-spacing">কথা রামায়ণ ছাড়া চন্দ্রাবতীর লেখা বহু গীতিকবিতা একদিন উত্তর ও পূর্ববঙ্গে লোকের মুখে মুখে ফিরত।
</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                    <td className="tableEvenRow"><ReactPlayer   
                        url={audiom3}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenRow"><p className="p-medium-line-spacing">পুঁজিবাদী অর্থ ব্যবস্থায় অর্থ হস্তান্তর প্রক্রিয়া এমনভাবে প্রণীত ও বিন্যাস্ত যাতে সব অর্থ সম্পদ ধনীদের হাতেই পুঞ্জীভূত হয়।</p></td>
            </tr>
            </tbody>
        </table>


    </div>

    );
}

const Sample_Bengali_Male = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            > 
                <SEOComponent
                    title="SampleBengaliMale"
                    keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/SampleBengaliMale" />
                   
                    <Strip
                        id="tst-SampleDatasets"
                        className="strip strip-no-padding px-20"
                        containerType="container"
                    >
                   

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Bengali Male Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Bengali_Male />
                            </div>
                    </div>

</Strip>
</RenderPage>
</ExternalLayout>
);
};

export default Sample_Bengali_Male;