import React, { useCallback, useState } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useDropzone } from "react-dropzone";

const FileUploader = (props) => {
  const [fileFormat, setFileFormat] = useState(
    "image/jpeg, image/jpg, image/png"
  );

  React.useEffect(() => {
    if (props.isImg) {
      setFileFormat("image/jpeg, image/jpg, image/png");
    } else {
      setFileFormat("application/pdf");
    }
  }, [props]);

  const onDrop = useCallback((acceptedFiles) => {
    console.log("AcceptedFiles.........", acceptedFiles);

    let item = [];
    acceptedFiles.forEach((file) => {
      console.log("Initial file.........", file);
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onloadend = () => {
        const binaryStr = reader.result;
        // const binaryStr1 = reader.result;
        // file.uri = binaryStr1;
        item.push({ base64: binaryStr, file: file });
        if (props.isImg) {
          props.getThumbnail(item);
        } else {
          props.getPdfFile(item);
        }
        //setFiles([...files, ...item]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileFormat,
    multiple: false,
    onDrop,
  });

  return (
    <section className="container-fuild">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} disabled={props.isDefault ? true : false} />
        {!props.isEditable ? (
          <div>
            {props.isImg ? (
              <div
                className={`position-relative ${props.isDefault ? "cursor-none" : "cursor-pointer "
                  }`}
              >
                {props.isForm ? <Input value={props.value} placeholder="Upload File" className="form-control common-form-control" />
                  :
                  <div>
                    <CloudUploadIcon
                      style={{
                        color: "#dfdfdf",
                        fontSize: "8rem",
                        border: "1px solid #dedede",
                        borderRadius: 4,
                        padding: 24,
                      }}
                    //onClick={() => handlePdfBitlyUrl(shortPdfUrl)}
                    />
                    <div className="img-thumbnail-container">
                      <p className="p-small mb-0 internal-section--title title-light-dim-grey align-center">
                        Upload Photo
                      </p>
                    </div></div>}
              </div>
            ) : (
              <div className={`position-relative ${props.isDefault ? "cursor-none" : "cursor-pointer "
                }`}>
                <div>
                  <PictureAsPdfIcon
                    style={{
                      color: "#DC0D45",
                      fontSize: "2rem",
                      border: "1px solid #dedede",
                      borderRadius: 4,
                      padding: 4,
                      position: "absolute",
                      marginLeft: "95%",

                    }}
                  //onClick={() => handlePdfBitlyUrl(shortPdfUrl)}
                  />

                </div>
                <Input value={props.value} placeholder="Upload images" className="form-control common-form-control" />

                {/* <div className="img-thumbnail-container">
                  <p className="p-small mb-0 internal-section--title title-light-dim-grey align-center">
                    Upload PDF
                  </p>
                </div> */}
              </div>
            )}
          </div>
        ) : (
          <div
            className="edit-img-file"
          //onClick={() => handleImgFile(item)}
          >
            <EditIcon
              style={{
                color: "#fff",
                fontSize: "0.85rem",
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default FileUploader;
