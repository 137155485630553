// apiCaller.js

async function callVideoApi(selectedTab, selectedCheckbox, selectedRadio, text, setLoading, setVideoUrl, setModalVisible) {
    setLoading(true); // Show loading indicator
    const url = `https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/${selectedTab}-${selectedCheckbox}-${selectedRadio}-audio`;
  
    try {
      
      const audioResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text: text })
    });
    const audioData = await audioResponse.json();
    const audioUrl = audioData.audioUrl;    

    // Split the audioUrl at the `.com` domain
    const splitAudioUrl = audioUrl.split('.com/');
    // replace .wav by .mp4
    const video_name  = splitAudioUrl[1].replace('.wav','.mp4');
    // const video_name  == splitAudioUrl // 'test_sumit_webpage_now.mp4'
        
    // Call the Video API to get VideoURL using AudioURL and text
    const videoApiUrl = 'https://blhzbpoisl.execute-api.ap-south-1.amazonaws.com/Test_stage';
    console.log('audioUrl',audioUrl);
    // if selectedCheckbox value is mal give male else female
    const genderResult = selectedCheckbox === 'mal' ? 'Male' : 'Female';

    const videoResponse = await fetch(videoApiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        //body: JSON.stringify({ audio_path: splitAudioUrl[1], text: text })
        body: JSON.stringify({ audio_path: audioUrl, gender: genderResult, outfilename: video_name })
    });

    const videoData = await videoResponse.json();
    console.log('video API response',videoData);
    const videoUrl = "https://audiosyspin.s3.ap-south-1.amazonaws.com/" //video_name ;  //videoData.videoUrl;
    const fullVideoUrl = videoUrl + video_name;
    
      setVideoUrl(fullVideoUrl);
    } catch (error) {
      console.error('Error generating audio:', error);
    } finally {
      setLoading(false); // Hide loading indicator on success or error
      setModalVisible(true);
    }
}  
  export default callVideoApi;
  

