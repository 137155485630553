import debugLogger from "./debugLogger";

export const asyncStoreGet = async key => {
    let data = '';
    try {
        data = await localStorage.getItem(key);
    } catch (error) {
        // Error getting data
        data = '';
        debugLogger('AsyncStoreGet error:', error.message);
    }
    return data;
};


export const asyncStoreSave = async (key, value) => {
    try {
        await localStorage.setItem(key, value);
    } catch (error) {
        // Error saving data
        debugLogger('AsyncStoreSave error:', error.message);
    }
};


export const asyncStoreClear = async () => {
    try {
        await localStorage.clear();
    } catch (error) {
        // Error deleting data
        debugLogger('AsyncStoreClear error:', error.message);
    }
};
