import React from 'react';
import { Row, Col } from 'reactstrap';
import application_contants from '../../../contants/application_contants';
import Strip from '../../../customComponents/Strip/Strip';
import { useWindowWidth } from "@react-hook/window-size";
import LineColor from '../../../customComponents/LineColor/LineColor';


const Banner = () => {
    const width = useWindowWidth();

    return (
        <Strip
            id="tst-strip-banner"
            className="strip strip-no-padding px-0"
            containerType="container-fluid"
        >
            <Row className="mx-0">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                    <div className="position-relative">
                        <img src={width <= application_contants.MOBILE_BREAKPOINT ? application_contants.MOBILEPATH : application_contants.BGPATHIMG} alt="" />
                        <div className="banner-container">
                            <div>
                                <div>
                                    <img src={application_contants.LOGOIMG} alt="" className="logo" />
                                    <div className="title-container">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <p className="p-large jostRegular section-title title-white align-center"><br></br>Welcome to the project on <br></br> SYnthesizing SPeech in INdian languages (SYSPIN)</p>:
                                    <p className="h6-small section-title title-white py-2">Welcome to the project on SYnthesizing SPeech in INdian languages (SYSPIN)</p>}
                                        {width <= application_contants.MOBILE_BREAKPOINT ?
                                            <h1 className="h2-small section-title title-white pb-2 title">Text-to-speech synthesizer in nine Indian languages</h1>
                                            :
                                            <h1 className="h1-medium section-title title-white pb-2 title">Text-to-speech synthesizer in nine Indian languages</h1>

                                        }
                                    </div>
                                </div>
                                <div className="dots-container pt-2">
                                    <img src={application_contants.CIRCLEPATHIMG} alt="" />
                                </div>
                                <div className="lng-container">
                                    <p className="jostRegular h6-small section-title title-white">Hindi | Bengali | Marathi | Telugu | Bhojpuri | Kannada | Magadhi | Chhattisgarhi | Maithili</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Strip>

    )
};

export default Banner;