import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Form, Label, Button, FormGroup, Input } from 'reactstrap';
import { alertDangerConfirmMessage, alertSuccessMessage} from '../../../customComponents/ConfirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponents/Loader/Loader';
import Strip from '../../../customComponents/Strip/Strip';
import ContributorService from '../../../../api/services/ContributorService';
import Axios from 'axios';
import ApiConfig from '../../../../api/apiConfig/apiConfig';
import FileUploader from '../../../customComponents/FileUploader/FileUploader';

const AddIndividual = (props) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const [photo, setPhoto] = useState('');
    const [photoFileName, setPhotoFileName] = useState('');
    const [photoFile, setPhotoFile] = useState('');
    // const [professorPhoto, setProfessorPhoto] = useState(');
    const [professorDetails, setProfessorDetails] = useState([]);
    const [studentsDetail, setStudentsDetail] = useState([]);
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { isModal, toggle, isAdd } = props;
    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setName('');
            setDesignation('');
            setDescription('');
            setUrl('');
            setPhotoFileName('');
            setId('');
            setPhoto('')
            setProfessorDetails([])
            setStudentsDetail([])
        } else {
            setId(props.item.id);
            setName(props.item.name)
            setDesignation(props.item.designation)
            setDescription(props.item.description)
            setUrl(props.item.url);
            setPhoto(props.item.imageUrl)
            setPhotoFileName(props.item.imageUrl);
            setId(props.item.id)
            setProfessorDetails(props.item.professors ? JSON.parse(props.item.professors) : [])
            setStudentsDetail(props.item.students ? JSON.parse(props.item.students) : [])
            
        }
    }, [isAdd, props]);
    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleDesignation = (e) => {
        setDesignation(e.target.value)
    }
    const handleDescription = (e) => {
        setDescription(e.target.value)
    }
    const handleUrl = (e) => {
        setUrl(e.target.value)
    }
    const getThumbnail = (item) => {
        setPhoto(item[0].base64);
        setPhotoFile(item[0].file);
        setPhotoFileName(item[0].file.name);
        setIsFileChanged(true);
    };
    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let finalFile = [];
        let data = {
            userId: userId,
            id: id,
            name: name,
            imageUrl: isFileChanged ? `/contributors/${photoFileName}` : photoFileName,
            designation: designation,
            description:description,
            url:url,
            professors: JSON.stringify(professorDetails),
            students: JSON.stringify(studentsDetail)
        }
        if (isFileChanged) {
            UploadImage(photoFile, data)
        } else {
            insertOrUpdate(data);
        }
    }
    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/contributors/");
        await data.append("image", file);
        // await file.map((item) => {
        //     data.append("image", item);
        // });
        console.log('photos : ', data);
        setIsLoading(true);
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };
    const insertOrUpdate = async (data) => {
    await ContributorService.insertOrUpdateForIndividual(data)
    .then(async (res) => {
        if (res.status === 200) {
            try {
                res.text().then(async (res) => {
                    let result = JSON.parse(res);
                    if (result.code === 0) {
                        alertSuccessMessage(`${result.message}`);
                        props.handleRefresh();
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        alertDangerConfirmMessage(`${result.message}`);
                    }
                });
            } catch (err) {
                console.log(err);
                setIsLoading(false);
            }
        }
    })
    .catch((err) => {
        console.log(err);
        setIsLoading(false);
    });
    }
    return (
        <Strip
            id="tst-add-opening"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-theme-blue">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                                <Row>
                                    <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                        <Row className="m-auto">
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="pt-3 pb-5"
                                            >
                                                <Row className="mt-2">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Form>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Row>
                                                        <Label className="common-label text-left">
                                                                Institute Image:
                                                                </Label>
                                                                </Row>
                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                                className="d-flex align-items-center px-5"
                                                            >
                                                                
                                                                {!photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={false}
                                                                    //isDefault={isDefaultThumbnail}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={`${isFileChanged ? `${photo}` : `${ApiConfig.url}${photo}`}`}
                                                                        alt={""}
                                                                    />
                                                                )}
                                                                {photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={true}
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </FormGroup>
                                                            <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Name*:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Institute Name"
                                                                        id="name"
                                                                        name="name"
                                                                        className="form-control common-form-control"
                                                                        value={name}
                                                                        onChange={(event) => handleName(event)}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Designation*:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Designation"
                                                                        id="designation"
                                                                        name="designation"
                                                                        className="form-control common-form-control"
                                                                        value={designation}
                                                                        onChange={(event) => handleDesignation(event)}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row className="mx-0 align-items-start">
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                    Description*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="Description"
                                                                        id="description"
                                                                        name="description"
                                                                        className="form-control common-form-control"
                                                                        value={description}
                                                                        onChange={(event) => handleDescription(event)}
                                                                    //rows={10}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Link:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="link"
                                                                        id="link"
                                                                        name="link"
                                                                        className="form-control common-form-control"
                                                                        value={url}
                                                                        onChange={(event) => handleUrl(event)}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                           </Form>
                                                    </Col>
                                                </Row>
                                                <Row className="no-margin">
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={{ offset: 3, size: 6 }}
                                                        lg={{ offset: 3, size: 6 }}
                                                        xl={{ offset: 3, size: 6 }}
                                                    >
                                                        <Row className="pt-4">
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Button
                                                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                    onClick={toggle}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Button
                                                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                    onClick={() => handleSubmit()}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Strip>
    )
}
export default AddIndividual;