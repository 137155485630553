import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import { useWindowSize } from "@react-hook/window-size";
import MediaService from '../../../api/services/MediaService';
import application_contants from "../../contants/application_contants";
import MediaComponent from '../../customComponents/MediaComponent/MediaComponent';
import SEOComponent from '../../customComponents/SEO/SEOComponent';

const Media = () => {
    const [width] = useWindowSize();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, []);

    const getList = async () => {
        setIsLoading(true);
        await MediaService.getMediaList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    // debugLogger(result.data);
                                    const lists = result.data.list.filter((it) => it.status === 1);
                                    setData([...lists])
                                    console.log("data list",...result.data.list)
                                    // console.log(result.data.docUrl)
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-media"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Media"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/media" />
                <Strip
                    id="tst-strip-media"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
                    {/* <Row className="mx-0  pt-5">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                        <h2 className="h2-small section-title align-left pt-5 title-navy-blue-medium">Coming soon ....</h2>
                        </Col>   
                    </Row> */}
                      <Row className="mx-0 pt-5">
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4 px-0">
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-4 px-0">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h2 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Media</h2> :
                                            <h2 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Media</h2>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className=" pt-3 px-0">
                                        {data.length ? 
                                        <Row className="mx-0 pb-2">
                                            { data.map((item, index) => {
                                                return <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} className="px-0">
                                                    <Row className=" mx-0">
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 ">
                                                        <p className="h6-medium jostRegular align-left media-card-position pt-3">{item.title}</p>
                                                        <MediaComponent data={item.title} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            })}
                                        </Row>
                                        :
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 5 : 3} xl={width === 1024 ? 5 : 3} key="abc" className="previous-challenges-card-br pb-3">
                                        <span className=" section-title align-left title-navy-blue-medium nunitoSans-Light">Coming soon ....</span>
                                        </Col>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default Media;