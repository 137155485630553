import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row , Button} from "reactstrap";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import PeopleDetails from "../../customPages/PeopleDetails/PeopleDetails";


const PersonSlider = (props) => {
  const { clickTest } = props;
  let history = useHistory();
  console.log('props : ',props);

  // const peopleDetails = (item) => {
  //   console.log('/peopleDetails')
  //   props.history.push('/peopleDetails',item)
  // }
  const  PeopleDetails = async (item) => {
    console.log('clicked',item)
    props.history.push('/peopleDetails',{item});
}

 
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 team-mobile-position ">
        <Row className="mx-0 pt-4">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-4"
              >
                <div className="team-img-container team">
                  {/* {props.item.link ? */}
                  <p   onClick={() => clickTest(props.item)}  className="team-img-container cursor-pointer">
                      <img src={`${ApiConfig.url}${props.item.photoURL}`} alt="" />
                      <div
                        // className="first-circle-container"
                        style={{ border: `15px solid ${props.item.firstClassName}` }}
                      ></div>
                      <div
                        className="second-circle-container"
                        style={{
                          border: `15px solid ${props.item.secondClassName}`,
                        }}
                      ></div>
                    </p>
                </div>
                <p   onClick={() => clickTest(props.item)}  className="team-img-container cursor-pointer">
                <div className="pt-4 team-img-container ">
                  <div>
                    <h6 className="p-large jostBold mb-0 team-img-container line-height-20px font-size-20px">
                      {props.item.fullName}
                    </h6>
                    <h6 className="p-small jostRegular mb-0 team-img-container ">
                      {props.item.name}
                    </h6>
                  
                    {/* {props.isShow ?
                     
                     <p   onClick={() => clickTest(props.item)} className=" p-small custom-reference-links1 nunitosansLight team-img-container mb-0 line-height-20px  pt-1">
                  View Profile
                                                     </p>
                      : null} */}
                      
                  </div>
                </div>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default PersonSlider;