import application_contants from "../contants/application_contants";

function activeTab(path) {
  if (path.split("/")[1] === application_contants.ABOUTUS.split("/")[1]) {
    return application_contants.ABOUTUS;
  } else if (path.split("/")[1] === application_contants.PEOPLES.split("/")[1]) {
    return application_contants.PEOPLES;
  } else if (path.split("/")[1] === application_contants.BANNERLIST.split("/")[1]) {
    return application_contants.BANNERLIST;
  } else if (path.split("/")[1] === application_contants.OPENINGLIST.split("/")[1]) {
    return application_contants.OPENINGLIST;
  } else if (path.split("/")[1] === application_contants.REASEARCHAREA.split("/")[1]) {
    return application_contants.REASEARCHAREA;
  }else if (path.split("/")[1] === application_contants.CHALLENGESLIST.split("/")[1]) {
    return application_contants.CHALLENGESLIST;
  }else if (path.split("/")[1] === application_contants.CONTRIBUTORS.split("/")[1]) {
    return application_contants.CONTRIBUTORS;
  }
  else {
    return path;
  }
}

function offSetItem(width) {
  if (width <= 767) {
    return 550;
  }
  if (width >= 768 && width <= 1024) {
    return 550;
  } else if (width >= 1025 && width <= 1365) {
    return 550;
  }
  if (width >= 1366 && width <= 3840) {
    return 650;
  }
  return 650;
}
export { activeTab, offSetItem };
