import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
  FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from '../../contants/application_contants';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import SEOComponent from '../../customComponents/SEO/SEOComponent';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import { usePeople } from "../../context/PeopleProvider";
import { alertDangerConfirmMessage } from "../../customComponents/ConfirmationMessage/ConfirmationMessage";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";

const PeopleDetails = (props) => {
const {item} = props.location.state;
console.log('PeopleDetails : ',item);

  const [sectionTitle, setSectionTitle] = useState('');
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [fullName, setFullName] = useState('');
  const [data, setData] = useState([]);
  const [peopleState, { getAllPeopleList }] = usePeople();
  const [width] = useWindowSize();
  const [advisorTeam, setAdvisorTeam] = useState([]);
  // const [advisorTeam, setAdvisorTeam] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const [postdoctoralTeam, setPostDoctoralTeam] = useState([]);
  const [researchTeam, setReSearchTeam] = useState([]);
  const [managerTeam, setManagerTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <ExternalLayout>
      <RenderPage
        id="tst-people"
        className={`render px-0 render-page`}
        containerType="container-fluid"
      >
        <div>
          <Strip
            id="tst-people"
            className="strip strip-no-padding"
            containerType="container"
          >
            <SEOComponent
              title={sectionTitle}
              description={sectionTitle}
              keywords="Speech Recognition, respin, SPIRE LAB, Navana"
              siteUrl={`/peopleDetails/${sectionTitle}`} />
            <Row className="mx-0 pt-5">
            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                            <p className="mb-0 section-title align-left">
                                <Link to={`/teamMembers`} className="custom-link-item">{` People > Team Member >`}</Link>
                                <span className="h6-large pl-2 text-capitalize">{item.fullName} </span>
                            </p>

                        </Col>
                <Row className="mx-3 pt-5">
                  <Col
                    xs={12}
                    ms={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="team-mobile-position pb-5 "
                  >
                    <div className="team-img-container team">
                      <div className="team-img-container team"> <img src={`${ApiConfig.url}${item.photoURL}`} alt="" />
                        <div
                          //  className="first-circle-container"
                          style={{ border: `1px solid ${item.firstClassName}` }}
                        ></div>
                        <div
                           className="second-circle-container"
                          style={{
                            border: `1px solid ${item.secondClassName}`,
                          }}
                        ></div></div>
                    </div>
                  </Col>
                </Row>

              <Col xs={12} ms={12} md={12} lg={12} xl={12} className="mx-3 p-0">
               
                  <div>
                    <Row className="pr-1 pb-5">
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                        <h6 className="p-large mb-0 nunitoSans-SemiBold align-left">{item.fullName}</h6>
                      </Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                        <p className=" mb-1 h6-large jostRegular align-left">{item.name}</p>
                      </Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                        <p className="p-large mb-0 jostLight align-left">{item.description}</p>
                      </Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                      <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                        <a href={`${item.link}`} rel="noreferrer" target="_blank" className="p-small section-title mb-0 line-height-20px custom-reference-links text-underline pt-1 font-size-20px">
                          View Profile</a>
                      </Col>
                    </Row>
                  </div>
              </Col>

              {/* </Col> */}
            </Row>
          </Strip>
          {/* <SocialIcon /> */}
        </div>
      </RenderPage>
    </ExternalLayout>
  )
}
export default PeopleDetails;