import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from '../../contants/application_contants';
import { useWindowSize } from "@react-hook/window-size";
import {
    FiberManualRecord
} from "@material-ui/icons";
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import { Link } from 'react-router-dom';
import ReseachAreaService from '../../../api/services/ResearchAreaService';
import ImageViewer from '../../customComponents/ImageViewer/ImageViewer';


const AreaDetail = (props) => {
    const {item} = props.location.state;
    console.log('item', item);
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [gallerImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);


    React.useEffect(() => {
        window.scrollTo(0, 0);
        setGalleryImgList(item.galleryList ? JSON.parse(item.galleryList) : [])
    }, []);
   
    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...gallerImgList];
        console.log("list",list)
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                       
                        <Row className="mx-0 pt-5">
                            <Col xs={12} ms={12} md={12} lg={11} xl={12} className="m-auto">
                                <p className="mb-0 section-title nunitoSans-Light  pt-3 align-left">
                                    <Link to={`/Areas`} className="custom-link-item">{`Research > Areas >`}</Link>
                                    <span className="fw-600 pl-2 text-capitalize">{`${item.title}`} </span>
                                </p>
                                  
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                <Row>
                                    <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                                {/* {data.description === null && data.publications === null ? */}
                                                <div>
                                                {item.description ?
                                                <Row className="pt-4">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="p-medium section-title jostRegular align-left ">{item.reseachTitle}</h5></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="p-large section-title  jostLight pb-2 pt-2 align-left">{item.description}</p>
                                                    </Col>
                                                </Row>
                                                : null}
                                                {item.pointersPoints ?
                                                <Row>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3 pt-3">
                                                        <PointersItem item={item} />
                                                    </Col>
                                                </Row>
                                                : null }
                                                {item.publications ?
                                                <Row className ="pb-3">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                    <h5 className="h6-small jostBold section-title align-left ">Publications</h5></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="p-large section-title jostMedium pt-2 align-left">{item.publications}</p>
                                                    </Col>   
                                                </Row>
                                                : null}    
                                                <Row className ="pb-4">
                                                        {gallerImgList.length > 0 && gallerImgList.map((it, index) => (
                                                           <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                           <Row key={index} className="mx-0">
                                                                 <div className="bg-zircon-grey challenges-card-img-container cursor-pointer">
                                                                <img src={`${ApiConfig.url}${it.imgPath}`} alt="" className="img-item"/>
                                                                </div>
                                                                <p className="mb-0 section-title pt-2 pb-2 align-left">{it.imgTitle}</p>
                                                            </Row>
                                                         
                                                            
                                                            </Col>    
                                                        ))}
                                                 </Row> 
                                                    {item.downloadLinks ?
                                                <Row className ="pb-3">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="">
                                                        <DownloadLinks item={item} />
                                                    </Col>
                                                </Row>
                                                : null }
                                                </div>
                                    </Col>
                                </Row>
                            </Col>
                           </Row>
                    </Strip>
                    {/* <SocialIcon /> */}
                    {isModal ?
                    <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                    : null}
            </RenderPage>
        </ExternalLayout>
    )
}

export default AreaDetail;


const PointersItem = (props) => {
    return (
        <div>
            {props.item && props.item.pointersPoints ?
                <Row className="mx-0">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="px-0">
                    {/* <h5 className="h6-small section-title jostBold align-left pb-1">Pointers</h5> */}
                    </Col>
                    <Row>
                        <Col xs={12} ms={12} md={12} lg={12} xl={12}><p className="h6-small section-title jostBold align-left pb-3">{props.item.pointersDescription}{props.item.pointersDescription ? '' : ''}</p></Col>
                        {props.item.pointersPoints.split(/[\n]/g).map((infoItem, index) => (
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                <div className="d-flex">
                                    <FiberManualRecord className="dot-icon1" />
                                    <div>
                                        <p className="mb-0 section-title pb-3 align-left">{infoItem}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row> : null}
        </div>

    )
}

const DownloadLinks = (props) => {
    return (
        <div>
            {props.item && props.item.downloadLinks ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h6-small section-title jostBold align-left ">Download Links</h5></Col>
                    {props.item.downloadLinks.split(/[\n]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                            <a href={`${it}`} rel="noreferrer" target="_blank" className="h6-small media-section-title jostRegular pt-2 custom-reference-links align-left media-card-position text-underline">{it}</a>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}
                                               