import React from "react";
import PropTypes from "prop-types";

const BodyWrapper = ({ children }) => {
    return (
        <div className="relative min-h-screen">
            <main className="w-full min-h-screen">{children}</main>
        </div>
    );
};

BodyWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default BodyWrapper;
