import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from '../../contants/application_contants';
import { useWindowSize } from "@react-hook/window-size";
import ResearchAreaService from '../../../api/services/ResearchAreaService';
import { Link } from 'react-router-dom';
import SEOComponent from '../../customComponents/SEO/SEOComponent';


const Areas = (props) => {
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);


    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, []);

    const handleNavigationAreas = async (item) => {
        console.log('clicked')
        props.history.push('/Areas/AreasDetail',{item});
    }

    const getList = async () => {
        setIsLoading(true);
        await ResearchAreaService.getResearchAreaList()
            .then(async (res) => {
                console.log("openings result", res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("before data list", result)
                            if (result.code === 0) {
                                if (result.data) {
                                    // debugLogger(result.data);
                                    const lists = result.data.list.filter((it) => it.status === 1);
                                    setData([...lists])
                                    console.log("data list", ...result.data.list)
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Areas"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Areas"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/areas" />
                    
                <Strip
                    id="tst-strip-Areas"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >

                    {/* <Row className="mx-0  pt-5">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                        <h2 className="h2-small section-title align-left pt-5 title-navy-blue-medium">Coming soon ....</h2>

                        </Col>
                        
                    </Row> */}
                    <Row className="pt-5 mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5">
                            {width <= application_contants.MOBILE_BREAKPOINT ? <h2 className="h5-medium section-title jostRegular title-navy-blue align-left">Areas</h2> :
                                <h2 className="h5-medium section-title title-navy-blue jostRegular align-left">Areas</h2>}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                            {width >= application_contants.MOBILE_BREAKPOINT ?
                                <div className="pb-5 pt-5">
                                    {data.length > 0 ?
                                        <Row className="mx-0 pt-2 pb-2">
                                            {data.length > 0 && data.map((item, index) => {
                                                return <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 py-3 pb-4 challenges-card-br" key={index} >
                                                    {/* <a href={`/Areas/${item.title.toLowerCase()}`}> */}
                                                    <p onClick = {() => handleNavigationAreas(item)} className="cursor-pointer">

                                                        <Row className="mb-0 mx-0">
                                                            <Row xs={12} sm={12} md={12} lg={12} xl={12} className={` p-3 pb-3 publications-card`}>

                                                                <Col>
                                                                    <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                        <p className="p-medium section-title nunitoSans-semiBold title-black pt-1 align-left  publications-card-position">{item.title}</p>
                                                                    </Row>
                                                                    <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                        <p className="p-large section-title jostLight align-left title-black  pt-2 publications-card-position">{item.description}</p>
                                                                    </Row>

                                                                </Col>
                                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} className="align-right pt-5">
                                                                    <Row >
                                                                        <img src={application_contants.RIGHTARROW} alt="" className="right-icon--width" />
                                                                    </Row>
                                                                </Col>

                                                            </Row>

                                                        </Row>
                                                        </p>

                                                    {/* </a> */}
                                                </Col>
                                            })}
                                        </Row>
                                        :
                                        <div className="pb-5 mb-5">
                                            <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-2 section-title align-left">
                                                <span className="h5-small section-title align-left pt-4 title-navy-blue-medium"> Coming Soon...</span>
                                            </h5 >
                                        </div>
                                    }
                                </div>
                                :
                                <div className="pb-5">
                                    {data.length > 0 ?

                                        <Row className="px-4 pt-2 pb-2">
                                            {data.length > 0 && data.map((item, index) => {
                                                return <Col xs={12} sm={12} md={3} lg={12} xl={12} className="px-0 py-3 challenges-card-br" key={index} >
                                                    {/* <a href={`/Areas/${item.title.toLowerCase()}`}> */}
                                                    <p onClick = {() => handleNavigationAreas(item)} className="cursor-pointer">
                                                    <Row className="mb-0 mx-0">
                                                        <Row xs={12} sm={12} md={12} lg={12} xl={12} className={`p-3 pb-3 publications-card`}>
                                                            <Col>
                                                                <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <p className="p-medium section-title nunitoSans-semiBold title-black pt-1 align-left  publications-card-position">{item.title}</p>
                                                                </Row>
                                                                <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <p className="p-large section-title jostLight align-left title-black  pt-2 publications-card-position">{item.description}</p>
                                                                </Row>

                                                            </Col>
                                                            <Col xs={2} sm={2} md={2} lg={2} xl={2} className="align-right pt-5">
                                                                <Row >
                                                                    <img src={application_contants.RIGHTARROW} alt="" className="right-icon--width" />
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                    </Row>
                                                    </p>
                                                    {/* </a> */}
                                                </Col>
                                            })}
                                        </Row>
                                        :
                                        <div className="pb-5 mb-5">
                                            <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                                                <span className="h5-small section-title align-left pt-4 title-navy-blue-medium pl-2"> Coming Soon...</span>
                                            </h5 >
                                        </div>
                                    }
                                </div>
                            }
                        </Col>
                    </Row>

                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};

export default Areas;