import React from 'react';
import ColorLineData from '../../Utils/json/colorLineData';

const LineColor = () => {
    return (
        <div>
            <div className="d-flex align-items-center">
                {ColorLineData.length > 0 && ColorLineData.map((item, index) => (
                    <div className="common-line" style={{ backgroundColor: `${item.code}` }} key={index}></div>
                ))}
            </div>
        </div>
    )
};

export default LineColor;
