import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const DatasetsService = {
    getdatasetsList: async () => {
        const { baseUrl, getdatasetsList } = ApiConfig;

        const url = baseUrl + getdatasetsList;
        console.log('url');
        console.log(url)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const datasetsServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_tools",
            true
        );
        console.log("datasetsServicePromise",datasetsServicePromise)
        return datasetsServicePromise;
    },

    insertOrUpdate: async (data) => {
        const { baseUrl, insertORUpdatedatasets } = ApiConfig;
        console.log("insertORUpdatedatasets",insertORUpdatedatasets)
        const url = baseUrl + insertORUpdatedatasets;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        console.log("url",url)
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: data.title,
            detailTitle: data.detailTitle,
            description: data.description,
            time: data.time,
            language: data.language,
            linguality: data.linguality,
            catalogue: data.catalogue,
            datasize1: data.datasize1,
            datasize2: data.datasize2,
            datasize3: data.datasize3,
            annotation: data.annotation,
            sampling: data.sampling,
            validation: data.validation,
            datacreator: data.datacreator,
            publishingyear: data.publishingyear,
            researchpurpose: data.researchpurpose,
            publications: data.publications,
            download: data.download,
            samplefiles: data.samplefiles,
            feedback: data.feedback,
        });

        const datasetsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_datasetsService",
            true
        );
        return datasetsServicePromise;
    },
    enableDisabledatasets: async (data) => {
        const { baseUrl, enableDisabledatasets } = ApiConfig;
        const url = baseUrl + enableDisabledatasets;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisabledatasetsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisabledatasets",
            true
        );
        return enableDisabledatasetsServicePromise;
    },
}

export default DatasetsService ;