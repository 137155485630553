// import React, { useState } from 'react';
// import { Col, Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
// import RenderPage from '../../customComponents/RenderPage/RenderPage';
// import Strip from '../../customComponents/Strip/Strip';
// import application_contants from "../../contants/application_contants";
// import { useWindowSize } from "@react-hook/window-size";
// import { FiberManualRecord } from "@material-ui/icons";
// import { ApiConfig } from '../../../api/apiConfig/apiConfig';
// import moment from "moment";
// import ImageViewer from '../../customComponents/ImageViewer/ImageViewer';
// import SEOComponent from '../../customComponents/SEO/SEOComponent';
// import { useHistory } from "react-router-dom";
// import ChallengersService from '../../../api/services/ChallengersService';
// import debugLogger from '../../Utils/debugLogger';



// const ChallengesDetails = (props) => {
//     const [sectionTitle, setSectionTitle] = useState('');
//     const [title, setTitle] = useState('');
//     const [code, setCode] = useState('');
//     const [data, setData] = useState([]);
//     const [width] = useWindowSize();
//     const [galleryImgList, setGalleryImgList] = useState([]);
//     const [isModal, setIsModal] = useState(false);
//     const [imageViewerList, setImageViewerList] = useState([]);
//     const [substacks, setSubstacks] = useState([]);
//     const [description, setDescription] = useState('');
//     const [url, setUrl] = useState('');
//     const [fromDate, setFromDate] = useState('');
//     const [toDate, setToDate] = useState('');
//     const [tableInfo, setTableInfo] = useState('');
//     const date = moment().format('DD MMMM , YYYY');

//     React.useEffect(() => {
//         setData([]);
//         window.scrollTo(0, 0);
//         setSectionTitle(props.location.pathname.split('/')[3]);
//         getList();

//     }, []);

//     const getList = async () => {
//         let it = [];
//         await ChallengersService.getChallengersList()
//             .then(async (res) => {
//                 if (res.status === 200) {
//                     try {
//                         res.text().then(async (res) => {
//                             let result = JSON.parse(res);
//                             console.log("result", result)
//                             if (result.code === 0) {
//                                 if (result.data) {
//                                     result.data.list.map((item) => {
//                                         console.log(props.location.pathname.split('/')[2])
//                                         if (item.title.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
//                                             it.push(item);
//                                             setTitle(item.detailTitle)
//                                             setCode(item.code)
//                                             setDescription(item.description)
//                                             // let substackItem = item.keyTakeAways
//                                             setUrl(item.url)
//                                             setFromDate(item.date)
//                                             setToDate(item.toDate)
//                                             setSubstacks(item.keyTakeAways ? JSON.parse(item.keyTakeAways) : [])
//                                             console.log("item", item.keyTakeAways ? JSON.parse(item.keyTakeAways) : [])
//                                             setTableInfo(item.websiteSource ? JSON.parse(item.websiteSource) : [])
//                                         }
//                                     });
//                                     debugLogger(it);
//                                     //debugLogger(result.data);
//                                     setData([...it])
//                                     //setIsLoading(false);
//                                 } else {
//                                     setData([]);
//                                     // setIsLoading(false);
//                                 }
//                             } else {
//                                 setData([]);
//                                 // setIsLoading(false);
//                             }
//                         });
//                     } catch (err) {
//                         console.log(err);
//                         //setIsLoading(false);
//                     }
//                 }
//             })
//             .catch((err) => {
//                 console.log(err);
//                 //setIsLoading(false);
//             });
//     }
//     const handleToggle = () => {
//         setIsModal(!isModal);
//     }

//     const handleToggleIndex = (index) => {
//         const list = [...galleryImgList];
//         console.log("list", list)
//         list.splice(0, 0, list.splice(index, 1)[0]);
//         setImageViewerList([...list]);
//         setIsModal(!isModal);
//     }
 
//     return (
//         <ExternalLayout>
//             <RenderPage
//                 id="tst-opening"
//                 className={`render px-0 render-page`}
//                 containerType="container-fluid"
//             >
//                 <div>
//                     <Strip
//                         id="tst-opening"
//                         className="strip strip-no-padding"
//                         containerType="container"
//                     >
//                         <SEOComponent
//                             title={sectionTitle}
//                             description={sectionTitle}
//                             keywords="Speech Recognition, respin, SPIRE LAB, Navana"
//                             siteUrl={`//${sectionTitle}`} />
//                         <Row className="mx-0">

//                             <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto">
//                                 <p className="mb-0 section-title align-left">
//                                     <Link to={`/challenges`} className="custom-link-item">{` Resources > Challenges >`}</Link>
//                                     <span className="fw-600 pl-2 text-uppercase">{`${sectionTitle}`} </span>
//                                 </p>
//                                 {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px pt-4 align-left">
//                                     {title ? title : ''}
//                                 </h5> :
//                                     <h2 className="h2-small section-title align-left pb-2 line-height-48px">
//                                         {title ? title : ''}
//                                     </h2>
//                                 }
//                             </Col>
//                             <Col xs={12} ms={12} md={12} lg={11} xl={11}>
//                                 <Row>
//                                     <Col xs={12} ms={12} md={12} lg={11} xl={11}>
//                                         <div >
//                                             {/* {data.description === null && data.publications === null ? */}
//                                             <div>
//                                                 <Row className="pb-4">
//                                                     <Col xs={12} ms={12} md={12} lg={12} xl={12}>
//                                                         <p className="mb-0 section-title align-left">Date : {moment(fromDate).format('DD')} - {moment(toDate).format('DD MMMM yyyy')}</p>
//                                                         <p className="mb-0 section-title align-left">{description}</p>
//                                                         <p className="mb-0 section-title align-left nunitoSans-Bold">For more details, <a href={`${url}`} rel="noreferrer" target="_blank" className="p-small section-title nunitoSans-Light custom-reference-links align-left">
//                                                             Click here</a></p>
//                                                     </Col>
//                                                 </Row>
//                                                 <Row className="pb-4">
//                                                     {substacks.length > 0 && substacks.map((item, index) => (
//                                                         <Col xs={12} ms={12} md={12} lg={12} xl={12}>
//                                                             <Row key={index} className="mx-0">
//                                                                 <h6 className="h6-small mb-0  align-left ">{`${item.substackName}:   `}  </h6>
//                                                                 <p className="mb-0 section-title align-left ml-2">{item.substackTitle}</p>
//                                                             </Row>
//                                                             <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
//                                                                 <div className="d-flex">
//                                                                     <FiberManualRecord className="dot-icon" />
//                                                                     <div>
//                                                                         <p className="mb-0 section-title align-left pb-4">{item.substackDescription}</p>
//                                                                     </div>
//                                                                 </div>
//                                                             </Col>
//                                                         </Col>
//                                                     ))}
//                                                 </Row>
//                                                 <Col xs={12} ms={12} md={12} lg={12} xl={12}
//                                                 >
//                                                     {tableInfo.length > 0 ?
//                                                         <table className="column_design ">
//                                                             <caption className="h4-small table-heading ">Expert talk speaker</caption>

//                                                             <thead>
//                                                                 <tr className=" bg-white ">
//                                                                     <td className="single_column_design">
//                                                                         <div className="align-center row-header-width">S.No</div>
//                                                                     </td>
//                                                                     <td className="single_column_design">
//                                                                         <div className="row-header-width">Speaker</div>
//                                                                     </td>
//                                                                     <td className="single_column_design">
//                                                                         <div className="row-header-width">Organization</div>
//                                                                     </td>
//                                                                     <td className="single_column_design">
//                                                                         <div className="row-header-width">Topic</div>
//                                                                     </td>
//                                                                 </tr>
//                                                             </thead>

//                                                             <tbody>
//                                                                 {tableInfo.map((item, index) => (
//                                                                     <tr className="column_design bg-white ">
//                                                                         <td className="align-center single_column_design">
//                                                                             <div>{item.slno}</div>
//                                                                         </td>
//                                                                         <td className="single_column_design">
//                                                                             <div>{item.speaker}</div>
//                                                                         </td>
//                                                                         <td className="single_column_design">
//                                                                             <div>{item.organization}</div>
//                                                                         </td>
//                                                                         <td className="single_column_design">
//                                                                             <div>{item.topic}</div>
//                                                                         </td>
//                                                                     </tr>
//                                                                 ))}
//                                                             </tbody>

//                                                         </table>
//                                                         : ' '}
//                                                 </Col>
                                               
//                                                 <Row className="pb-5">
//                                                     {galleryImgList.length > 0 && galleryImgList.map((item, index) => (
//                                                         <Col xs={12} ms={12} md={12} lg={12} xl={12}>
//                                                             <Row key={index} className="mx-0">
//                                                                 <div className="bg-zircon-grey gallery-card-img-container cursor-pointer">
//                                                                     <img src={`${ApiConfig.url}${item.imgPath}`} alt="" className="img-item" />
//                                                                 </div>
//                                                                 <p className="mb-0 section-title align-left">{item.imgTitle}</p>
//                                                             </Row>

//                                                         </Col>
//                                                     ))}
//                                                 </Row>
//                                             </div>
//                                             {/* : null} */}
//                                         </div>

//                                     </Col>
//                                 </Row>
//                             </Col>
//                         </Row>
//                     </Strip>
//                 </div>
//                 {isModal ?
//                     <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
//                     : null}
//             </RenderPage>
//         </ExternalLayout>
//     )
// }

// export default ChallengesDetails;

import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from "../../contants/application_contants";
import { useWindowSize } from "@react-hook/window-size";
import { FiberManualRecord } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import moment from "moment";
import ImageViewer from '../../customComponents/ImageViewer/ImageViewer';
import SEOComponent from '../../customComponents/SEO/SEOComponent';
import { useHistory } from "react-router-dom";



const ChallengesDetail = (props) => {
    const {challengesDetail} = props.location.state;
    console.log('ChallengesDetail : ',challengesDetail);

    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [gallerImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [substacks,setSubstacks] = useState([]);
    const [description,setDescription] = useState('');
    const [url,setUrl] = useState('');
    const [fromDate,setFromDate] = useState('');
    const [toDate,setToDate] = useState('');
    const [tableInfo,setTableInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const [imagesUrl, setImagesUrl] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [substackDetails, setSubstackDetails] = useState([]);
    const [tableDetails, setTableDetails] = useState([]);
    const [preData, setPreData] = useState([]);


    React.useEffect(() => {
        window.scrollTo(0, 0);
        setSubstacks(challengesDetail.keyTakeAways ? JSON.parse(challengesDetail.keyTakeAways) : [])
        setTableInfo(challengesDetail.websiteSource ? JSON.parse(challengesDetail.websiteSource) : [])
        setGalleryImgList(challengesDetail.imagesUrl ? JSON.parse(challengesDetail.imagesUrl) : [])
    }, []);

    // React.useEffect(() => {
    //     // if(challengesDetail) {
    //             setSubstacks(challengesDetail.keyTakeAways ? JSON.parse(challengesDetail.keyTakeAways) : [])
    //             setTableInfo(challengesDetail.websiteSource ? JSON.parse(challengesDetail.websiteSource) : [])
    //             setGalleryImgList(challengesDetail.imagesUrl ? JSON.parse(challengesDetail.imagesUrl) : [])
    //     // }
    //   }, [challengesDetail]);


    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...gallerImgList];
        console.log("list",list)
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
   
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-challenges"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                <div>
                    <Strip
                        id="tst-challenges"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`//${sectionTitle}`} />
                        <Row className="mx-0 pt-5">
                            
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-3">
                            <p className="mb-0 section-title align-left">
                                    <Link to={`/challenges`} className="custom-link-item">{` Resources > Challenges >`}</Link>
                                    <span className="fw-600 pl-2 ">{`${challengesDetail.title}`} </span>
                                </p>
                                {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title pt-4 align-left">
                                    {challengesDetail.detailTitle ? challengesDetail.detailTitle : ''}
                                </h5> :
                                    <h2 className="h5-medium pt-4 section-title align-left pb-2 line-height-48px">
                                        {challengesDetail.detailTitle ? challengesDetail.detailTitle : ''}
                                    </h2>
                                }
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                <Row>
                                    <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                            <div >
                                                {/* {data.description === null && data.publications === null ? */}
                                                <div>
                                                <Row className="pb-4">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                    {challengesDetail.fromDate && challengesDetail.toDate ?
                                                    <p className="mb-0 section-title pt-1 pb-1 align-left">Date : {moment(challengesDetail.fromDate).format('DD MMMM YYYY')} - {moment(challengesDetail.toDate).format('DD MMMM YYYY')}</p>     
                                                    : null }

                                                        <p className="p-large mb-0 section-title pt-1 jostLight align-left">{challengesDetail.description}</p>
                                                        {challengesDetail.url ?
                                                            <p>For more details, <a href={`${challengesDetail.url}`} rel="noreferrer" target="_blank" className="p-small section-title jostLight pt-1 custom-reference-links align-left">
                                                        Click here</a>
                                                        </p>
                                                        : null }
                                                        <div> {/* ==============Extra baggage===========*/ }

                                                            {/* <p> For Images, <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/limmits.php"} rel="noreferrer" target="_blank" 
                                                            className="p-small section-title jostLight pt-1 custom-reference-links align-left"> Click here</a></p> */}
                                                        </div> {/* ==============Extra baggage===========*/ }

                                                        
                                                    </Col>
                                                </Row>
                                                <Row className ="pb-3">
                                                        {substacks.length > 0 && substacks.map((item, index) => (
                                                           <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                            <Row key={index} className="mx-0">
                                                            <h6 className="h6-small mb-0 jostMedium align-left pb-2 ">{`${item.substackName}:   `}  </h6>
                                                                <p className="mb-0 section-title jostMedium align-left pt-1 ml-2">{item.substackTitle}</p>
                                                            </Row>
                                                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                                                <div className="d-flex">
                                                                    <FiberManualRecord className="dot-icon1 " />
                                                                    {/* <div> */}
                                                                        <p className="mb-0 p-large JostLight align-left pb-4">{item.substackDescription}</p>
                                                                    {/* </div> */}
                                                                </div>
                                                            </Col>
                                                            </Col>    
                                                        ))}
                                                 </Row>
                                                 <Row className ="pb-4">
                                                        {gallerImgList.length > 0 && gallerImgList.map((item, index) => (
                                                           <Col xs={12} ms={12} md={12} lg={12} xl={6}>
                                                           <Row key={index} className="mx-0">
                                                                 <div className="bg-zircon-grey challenges-card-img-container cursor-pointer">
                                                                <img src={`${ApiConfig.url}${item.imgPath}`} alt="" className="img-item"/>
                                                                </div>
                                                                <p className="mb-0 section-title pt-2 pb-2 align-left">{item.imgTitle}</p>
                                                            </Row>
                                                            
                                                            </Col>    
                                                        ))}
                                                 </Row> 
                                                 <Row>
                                                 <Col xs={12} ms={12} md={12} lg={12} xl={12}
                                               className="mb-5 pt-2" >
                                                    {tableInfo.length > 0 ?
                                                        <table className="column_design ">
                                                           

                                                            <thead>
                                                            {/* <table className="h4-small column_design">Expert Talk Speakers</table> */}
                                                                <tr className=" bg-white ">
                                                                    <td className="single_column_design">
                                                                        <div className="align-center row-header-width">S.No</div>
                                                                    </td>
                                                                    <td className="single_column_design">
                                                                        <div className="row-header-width">Speaker</div>
                                                                    </td>
                                                                    <td className="single_column_design">
                                                                        <div className="row-header-width">Organization</div>
                                                                    </td>
                                                                    <td className="single_column_design">
                                                                        <div className="row-header-width">Topic</div>
                                                                    </td>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {tableInfo.map((item, index) => (
                                                                    <tr className="column_design bg-white ">
                                                                        <td className="align-center single_column_design">
                                                                            <div>{item.slno}</div>
                                                                        </td>
                                                                        <td className="single_column_design">
                                                                            <div>{item.speaker}</div>
                                                                        </td>
                                                                        <td className="single_column_design">
                                                                            <div>{item.organization}</div>
                                                                        </td>
                                                                        <td className="single_column_design">
                                                                            <div>{item.topic}</div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>

                                                        </table>
                                                        : ' '}
                                                </Col>  
                                                </Row>
                                                                                         
                                                </div>
                                                {/* : null} */}
                                            </div>

                                    </Col>
                                </Row>
                            </Col>
                           </Row>
                    </Strip>
                    </div>
                    {/* <SocialIcon /> */}
                    {isModal ?
                    <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                    : null}
            </RenderPage>
        </ExternalLayout>
    )
}

export default ChallengesDetail;



