import React from "react"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

const LoadingContainer = () => <div className="map-loader">Loading...</div>

const MapContainer = (props) => {
    return (
        <Map
            google={props.google}
            zoom={11}
            initialCenter={{
                lat: 13.0149413,
                lng: 77.5613545,
            }}
            className="maps-position"
        >
            <Marker name={"Syspin"} title={"Syspin"} />
        </Map>
    )
}


const ContactUsMap = GoogleApiWrapper({
    apiKey: "AIzaSyDoAXngmYoYJDzPyzW13GBJEDx6BSQ4O88",
    LoadingContainer: LoadingContainer,
})(MapContainer)

export default ContactUsMap
