import React from "react";
import memoizeOne from "memoize-one";
import { DeleteOutlineOutlined, CreateOutlined } from "@material-ui/icons";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const TablesColumns = memoizeOne((handleEdit, handleDelete) => [
    {
        name: "Title",
        selector: "title",
        grow: 0.4,
        sortable: false,
        cell: (row) => <div>{row.title}</div>,
    },
    {
        name: "Document type",
        selector: "docType",
        sortable: false,
        cell: (row) => <div>{`${row.docTypeTitle !== null && row.docTypeTitle.length > 50 ? `${row.docTypeTitle.substr(0, 50)}...` : row.docTypeTitle}`}</div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        grow: 0.3,
        cell: (row) => <div>{`${row.status === 1 ? 'Active' : 'InActive'}`}</div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        grow: 0.3,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex">
                <div onClick={() => handleDelete(row)}>
                    <DeleteOutlineOutlined style={{ color: "#ec0707" }} />
                </div>
                <div onClick={() => handleEdit(row)}>
                    <CreateOutlined style={{ color: "#00BCD4" }} />
                </div>
            </div>
        ),
    },

]);




export default TablesColumns;
