import React, { useState } from 'react'
import { Row, Col, FormGroup, Label, Button, Input, Table } from 'reactstrap';


const AddTableInfo = (props) => {
    const { tableDetails } = props;
    const [slno,setSlno] = useState("");
    const [speaker, setSpeaker] = useState("");
    const [organization, setOrganization] = useState("");
    const [topic,setTopic] = useState("");
    const [countDetails, setCountDetails] = useState([]);
    const [countId, setCountId] = useState(0);
    const [isCountEdit, setIsCountEdit] = useState(false);
    const [countEditIndex, setCountEditIndex] = useState(0);
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);

    React.useEffect(() => {
        setCountDetails([...tableDetails])
    }, [slno]);

    const handleSlno = event => {
        setSlno(event.target.value);
    };

    const handleSpeaker = event => {
        setSpeaker(event.target.value);
    };

    const handleOrganization = event => {
        setOrganization(event.target.value);
    };

    const handleTopic = event => {
        setTopic(event.target.value)
    }

    const handleTableCancel = () => {
        setSpeaker("");
        setOrganization("");
        setTopic("");
    };

    const handleTableSave = () => {
        let data;
        if (isCountEdit) {
            const item = countDetails[countEditIndex];
            item.slno = slno;
            item.speaker = speaker;
            item.organization = organization;
            item.topic = topic;
            setCountDetails([...countDetails]);
            props.handleTable(countDetails);
        } else {
            data = [
                {
                    slno: slno,
                    speaker: speaker,
                    organization: organization,
                    topic: topic,
                }
            ];
            setCountDetails([...data, ...countDetails]);
            let array = [...data, ...countDetails]
            props.handleTable(array);
        }
        handleTableCancel();
        setIsCountEdit(false);
    };


    const handleTableRowDelete = index => {
        countDetails.splice(index, 1);
        setCountDetails([...countDetails]);
        props.handleTable(countDetails);
    };

    const handleTableRowEdit = index => {
        const item = countDetails[index];
        setSlno(item.slno);
        setSpeaker(item.speaker);
        setOrganization(item.organization);
        setTopic(item.topic)
        setIsCountEdit(true);
        setCountEditIndex(parseInt(index));
    };



    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h6 className="h6-small external-title align-center title-bright-grey bg-zircon-grey p-2">
                    Add Table Details :
                </h6>
            </Col>
            <Col
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="m-auto mt-4"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Sl No :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Sl no"
                                    id="slno"
                                    name="slno"
                                    className="form-control common-form-control"
                                    value={slno}
                                    onChange={event => handleSlno(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Speaker :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Speaker"
                                    id="speaker"
                                    name="speaker"
                                    className="form-control common-form-control"
                                    value={speaker}
                                    onChange={event => handleSpeaker(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Organization :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Organization"
                                    id="organization"
                                    name="organization"
                                    className="form-control common-form-control"
                                    value={organization}
                                    onChange={event =>
                                        handleOrganization(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Topic :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Topic"
                                    id="topic"
                                    name="topic"
                                    className="form-control common-form-control"
                                    value={topic}
                                    onChange={event =>
                                        handleTopic(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                      
                    </Col>
                    <Col
                        xs={10}
                        sm={10}
                        md={8}
                        lg={6}
                        xl={6}
                        className="ml-auto mb-3"
                    >
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                    onClick={handleTableCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                    onClick={handleTableSave}
                                    disabled={speaker && organization && topic ? false : true}
                                >
                                    Save
                                </Button>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Table bordered responsive>
                                    <thead className="custom-table-thead">
                                        <tr>
                                            <th>Sl no</th>
                                            <th>Speaker</th>
                                            <th>Organization</th>
                                            <th>Topic</th>
                                            <th>Action</th>


                                        </tr>
                                    </thead>
                                    <tbody className="custom-table-tbody">
                                        {tableDetails.map((it, index) => (
                                            <tr key={index}>
                                                <td>{it.slno}</td>
                                                <td>{it.speaker}</td>
                                                <td>{it.organization}</td>
                                                <td>{it.topic}</td>

                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div
                                                            onClick={() =>
                                                                handleTableRowDelete(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                Delete
                                                            </Button>
                                                        </div>
                                                        <div
                                                            className="ml-12px"
                                                            onClick={() =>
                                                                handleTableRowEdit(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Edit
                                                            </Button>
                                                        </div>
                                                        {/* <div
                                                            className="ml-12px"
                                                            onClick={() =>
                                                                handlePosition(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Position
                                                            </Button>
                                                        </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
         </Row>
    )
}


export default AddTableInfo;