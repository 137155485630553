import React, { useState } from "react";
import PropTypes from "prop-types";
import ExternalLayout from "../../customComponents/ExternalLayout/ExternalLayout";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import application_contants from "../../contants/application_contants";
import { useWindowSize } from "@react-hook/window-size";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  Label,
  Input,
  yy,
} from "reactstrap";
import ContributorService from "../../../api/services/ContributorService";
import ContributorsType from "../../Utils/json/ContributorsType";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import AcademicsSlider from "./AcademicsSlider/AcademicsSlider";
import Individual from "./Individual";
import Experts from "./Experts";
import SEOComponent from "../../customComponents/SEO/SEOComponent";

const Contributors = (props) => {
  const [width] = useWindowSize();
  const [activeTab, setActiveTab] = useState(0);
  const [professor, setProfessor] = useState([]);
  const [data, setData] = useState([]);
  const [academics, setAcademics] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [individualpeople, setIndividualpeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [individual, setIndividual] = useState([]);
  const [idvPeople, setIdvPeople] = useState([]);
  const [experts, setExperts] = useState([]);


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    getList();
  }, []);

  const handleIndividual = (item) => {
    setIndividual([...item]);
  }

  const handleExperts = (item) => {
    setExperts([...item])
}

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:
      width >= 1025
        ? 4
        : width >= 1024 && width <= 1024
        ? 3
        : width >= 768 && width <= 1023
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const getList = async () => {
    setIsLoading(true);
    await ContributorService.getContributorsList()
        .then(async (res) => {
            console.log("openings result",res)
            if (res.status === 200) {
                try {
                    res.text().then(async (res) => {
                        let result = JSON.parse(res);
                        console.log("before contributors data list",result)
                        if (result.code === 0) {
                            if (result.data) {
                                const lists = result.data.academicsList.list.filter((it) => it.status === 1);
                                setAcademics([...lists])
                                const lists2 = result.data.industryList.list.filter((it) => it.status === 1);
                                setIndustry([...lists2])
                                const lists3 = result.data.individualList.list.filter((it) => it.status === 1);
                                setIndividual([...lists3])
                                const lists4 = result.data.expertsList.list.filter((it) => it.status === 1);
                                setExperts([...lists4])
                                
                                setIsLoading(false);
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        } else {
                            setData([]);
                            setIsLoading(false);
                        }
                    });
                } catch (err) {
                    console.log(err);
                    setIsLoading(false);
                }
            }
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
}
 
  return (
    <ExternalLayout>
      <RenderPage
        id="tst-contributors"
        className={`render px-0 ${
          props.isClassName ? "render-page " : "min-height-auto"
        }`}
        containerType="container-fluid"
      >
       <SEOComponent
                    title="Contributors"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/contributors" />
        <Strip
          id="tst-contributors"
          className="strip strip-no-padding"
          containerType="container"
        >
          <Row className="mx-0 pt-5">
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={9}
              xl={11}
              className=" px-0"
            >
              {width <= application_contants.MOBILE_BREAKPOINT ? (
                <h5 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">
                  {" "}
                  Contributors
                </h5>
              ) : (
                <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3">
                  {" "}
                  Contributors
                </h5>
              )}
              <Nav tabs className="tab-container1 mx-0 pt-3">
                {ContributorsType.map((it, index) => (
                  <NavItem className="tab-nav-item1 " key={index}>
                    <NavLink
                      className={`tab-nav-link1  ${classnames({
                        active: activeTab === index,
                      })}`}
                      onClick={() => {
                        toggle(index);
                      }}
                    >
                      {it.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={0} className="">
                  <Row className="pt-4 mx-0">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="px-0 "
                    >
                      {width >= application_contants.MOBILE_BREAKPOINT ? (
                        <div className="">
                          {/* <Row className="mx-0 pb-3">
                                                            <Col xs={6} sm={6} md={4} lg={width === 1024 ? 5 : 4} xl={width >= 1900 ? 2 : 4} className="px-1 card-reports" >
                                                            <Input
                                                                        type="text"
                                                                        placeholder="Search"
                                                                        id="title"
                                                                        name="title"
                                                                        className="form-control common-form-control search-input-field"
                                                                    />
                                                            </Col>
                                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 2} xl={width >= 1900 ? 2 : 1} className="px-0 card-reports" >
                                                                    <button
                                                                        className="search-btn"
                                                                        >
                                                                        Search
                                                                        </button>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 py-1 card-reports" >
                                                                <p className='contribute-sort'>Latest to oldest</p>
                                                            </Col>
                                                    </Row>  */}
                          {academics.length > 0 ? (
                            <Row className="mx-0 pt-5 pb-2">
                              {academics.length > 0 &&
                                academics.map((item, index) => {
                                  return (
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="px-0 py-3 challenges-card-br"
                                      key={index}
                                    >
                                      <a
                                        href={`/Academics/${item.instituteName.toLowerCase()}`}
                                      >
                                        <Row className="mb-0 mx-0">
                                          <Row
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={`px-0  contributors-info-container`}
                                          >
                                            <Col>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="h6-medium section-title nunitoSans-semiBold title-black pt-1 pl-3 align-left contributors-card-position">
                                                  {item.instituteName}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="h6-small section-title jostRegular align-left title-black pt-2 pl-3 contributors-card-position">
                                                  {item.place}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                
                                                <a href={`${item.url}`} rel="noreferrer" target="_blank" className="h6-small section-title jostLight align-left title-black custom-reference-links pt-2 pl-3 contributors-card-position">
                          {item.url}</a>
                                              </Row>
                                            </Col>
                                            <Col
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={2}
                                              xl={2}
                                              className="align-right pt-5"
                                            >
                                              <Row>
                                                <img
                                                  src={
                                                    application_contants.RIGHTARROW
                                                  }
                                                  alt=""
                                                  className="right-icon--width"
                                                />
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </a>
                                    </Col>
                                  );
                                })}
                            </Row>
                          ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                                
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="pb-5">
                         
                          {academics.length > 0 ? (
                            <Row className="mx-0 pt-3 ">
                              {academics.length > 0 &&
                                academics.map((item, index) => {
                                  return (
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      className="px-0 pt-5 challenges-card-br"
                                      key={index}
                                    >
                                      <a
                                        href={`/Academics/${item.instituteName.toLowerCase()}`}
                                      >
                                        <Row className="mb-0 mx-0">
                                          <Row
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={`bg-solitude-grey contributors-info-container`}
                                          >
                                            <Col>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="p-medium section-title nunitoSans-semiBold title-black pt-1 align-left  pl-3 p-2 pb-2 contributors-card-position">
                                                  {item.instituteName}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="h6-small section-title jostRegular align-left title-black pt-2 pl-3 contributors-card-position">
                                                  {item.place}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <a href={`${item.url}`} rel="noreferrer" target="_blank" className="h6-small section-title jostLight align-left title-black custom-reference-links pt-2 pl-3 contributors-card-position">
                          {item.url}</a>
                                              </Row>
                                            </Col>
                                            <Col
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={2}
                                              xl={2}
                                              className="align-right pt-5"
                                            >
                                              <Row>
                                                <img
                                                  src={
                                                    application_contants.RIGHTARROW
                                                  }
                                                  alt=""
                                                  className="right-icon--width"
                                                />
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </a>
                                    </Col>
                                  );
                                })}
                            </Row>
                          ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                                
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={1} className="">
                  <Row className="pt-4 mx-0">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="px-0 m-auto"
                    >
                      {width >= application_contants.MOBILE_BREAKPOINT ? (
                        <div className="pb-5">
                          {/* <Row className="mx-0 pb-3">
                                                            <Col xs={6} sm={6} md={4} lg={width === 1024 ? 5 : 4} xl={width >= 1900 ? 2 : 4} className="px-1 card-reports" >
                                                            <Input
                                                                        type="text"
                                                                        placeholder="Search"
                                                                        id="title"
                                                                        name="title"
                                                                        className="form-control common-form-control search-input-field"
                                                                    />
                                                            </Col>
                                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 2} xl={width >= 1900 ? 2 : 1} className="px-0 card-reports" >
                                                                    <button
                                                                        className="search-btn"
                                                                        >
                                                                        Search
                                                                        </button>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 py-1 card-reports" >
                                                                <p className='contribute-sort'>Latest to oldest</p>
                                                            </Col>
                                                    </Row>  */}
                          {industry.length > 0 ? (
                            <Row className="mx-0 pt-5 pb-2">
                              {industry.length > 0 &&
                                industry.map((item, index) => {
                                  return (
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={12}
                                      xl={12}
                                      className="px-0 py-1 challenges-card-br"
                                      key={index}
                                    >
                                      <a
                                        href={`/Industry/${item.instituteName.toLowerCase()}`}
                                      >
                                        <Row className="mb-0 py-3 mx-0">
                                          <Row
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={` contributors-info-container`}
                                          >
                                            <Col>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="p-medium section-title nunitoSans-semiBold title-black pt-1 align-left  contributors-card-position">
                                                  {item.instituteName}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="h6-small section-title jostRegular align-left title-black pt-2 contributors-card-position">
                                                  {item.place}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                 <a href={`${item.url}`} rel="noreferrer" target="_blank" className="h6-small section-title jostLight align-left title-black custom-reference-links pt-2 contributors-card-position">
                          {item.url}</a>
                                              </Row>
                                            </Col>
                                            <Col
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={2}
                                              xl={2}
                                              className="align-right pt-5"
                                            >
                                              <Row>
                                                <img
                                                  src={
                                                    application_contants.RIGHTARROW
                                                  }
                                                  alt=""
                                                  className="right-icon--width"
                                                />
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </a>
                                    </Col>
                                  );
                                })}
                            </Row>
                          ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                               
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="pb-5">
                          {/* <Row className="mx-0 pb-3">
                                                            <Col xs={6} sm={6} md={4} lg={width === 1024 ? 5 : 4} xl={width >= 1900 ? 2 : 4} className="px-1 card-reports" >
                                                            <Input
                                                                        type="text"
                                                                        placeholder="Search"
                                                                        id="title"
                                                                        name="title"
                                                                        className="form-control common-form-control search-input-field"
                                                                    />
                                                            </Col>
                                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 2} xl={width >= 1900 ? 2 : 1} className="px-0 card-reports" >
                                                                    <button
                                                                        className="search-btn"
                                                                        >
                                                                        Search
                                                                        </button>
                                                            </Col>
                                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 3} xl={width >= 1900 ? 2 : 3} className="px-0 py-1 card-reports" >
                                                                <p className='contribute-sort'>Latest to oldest</p>
                                                            </Col>
                                                    </Row>  */}
                          {industry.length > 0 ? (
                            <Row className="mx-0 pt-2 pb-2">
                              {industry.length > 0 &&
                                industry.map((item, index) => {
                                  return (
                                    <Col
                                      xs={12}
                                      sm={12}
                                      md={3}
                                      lg={12}
                                      xl={12}
                                      className="px-0 py-1 challenges-card-br"
                                      key={index}
                                    >
                                      <a
                                        href={`/Industry/${item.instituteName.toLowerCase()}`}
                                      >
                                        <Row className="mb-0 mx-0">
                                          <Row
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={`bg-solitude-grey p-3 contributors-info-container`}
                                          >
                                            <Col>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="p-medium section-title nunitoSans-semiBold title-black pt-1 align-left  contributors-card-position">
                                                  {item.instituteName}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <p className="h6-small section-title jostRegular align-left title-black pt-2 contributors-card-position">
                                                  {item.place}
                                                </p>
                                              </Row>
                                              <Row
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                              >
                                                <a href={`${item.url}`} rel="noreferrer" target="_blank" className="h6-small section-title jostLight align-left title-black custom-reference-links pt-2 contributors-card-position">
                          {item.url}</a>
                                              </Row>
                                            </Col>
                                            <Col
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={2}
                                              xl={2}
                                              className="align-right pt-5"
                                            >
                                              <Row>
                                                <img
                                                  src={
                                                    application_contants.RIGHTARROW
                                                  }
                                                  alt=""
                                                  className="right-icon--width"
                                                />
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </a>
                                    </Col>
                                  );
                                })}
                            </Row>
                          ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                               
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2} className="">
                                        <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                                {width >= application_contants.MOBILE_BREAKPOINT ?
                                                    <Row className="mx-0 pb-5">
                                                    {individual.length > 0 ? (
                                                            <Row className="mx-0 pt-4 pb-4 ">
                                                                 {individual.length > 0  && individual.map((item,index) => (
                                                                    <Col xs={12} sm={12} md={12} lg={4} xl={3} className=" pt-4 pb-5">                                                                                           
                                                                     <div className="team-img-container team">
                                                                     </div>
                                                                                            
                                                                                    <Individual item={item} />
                                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                            ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                               
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                                                    </Row> 
                                                
                                                    :
                                                    
                                                      <Row className="mx-0 pt-4 pb-2 ">
                                                      {individual.length > 0  && individual.map((item,index) => (
                                                         <Col xs={12} sm={12} md={12} lg={8} xl={6} className="pt-4">                     
                                                         <div className="team-img-container team">
                                                                                
                                                                             </div> 
                                                                        
                                                                         <Individual item={item} />
                                                                         </Col>
                                                     ))}
                                                 </Row>
                                                }
                                            </Col>
                                        </Row> 
                                    </TabPane>
                                     <TabPane tabId={3} className="">
                                        <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                                {width >= application_contants.MOBILE_BREAKPOINT ?
                                                    <Row className="mx-0 pb-5">
                                                           {experts.length > 0 ? (
                                                            <Row className="mx-0 pt-4 pb-4 ">
                                                                 {experts.length > 0  && experts.map((item,index) => (
                                                                    <Col xs={12} sm={12} md={12} lg={4} xl={3} className=" pt-4 pb-5">                                                                                           
                                                                     <div className="team-img-container team">
                                                                     </div>
                                                                                            
                                                                                    <Experts item={item} />
                                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                            ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                               
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                                                    </Row> 
                                                
                                                    :
                                                    
                                                      <Row className="mx-0 pt-4 pb-2 ">
                                                      {experts.length > 0  && experts.map((item,index) => (
                                                         <Col xs={12} sm={12} md={12} lg={8} xl={6} className="pt-4">                     
                                                         <div className="team-img-container team">
                                                                                
                                                                             </div> 
                                                                        
                                                                         <Experts item={item} />
                                                                         </Col>
                                                     ))}
                                                 </Row>
                                                }
                                            </Col>
                                        </Row> 
                                    </TabPane>

                                    {/* 
                                    <TabPane tabId={4} className="">
                                        <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                                {width >= application_contants.MOBILE_BREAKPOINT ?
                                                    <Row className="mx-0 pb-5">
                                                           {interns.length > 0 ? (
                                                            <Row className="mx-0 pt-4 pb-4 ">
                                                                 {interns.length > 0  && interns.map((item,index) => (
                                                                    <Col xs={12} sm={12} md={12} lg={4} xl={3} className=" pt-4 pb-5">                                                                                           
                                                                     <div className="team-img-container team">
                                                                     </div>
                                                                                            
                                                                                    <Interns item={item} />
                                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                            ) : (
                            <div className="pb-5 mb-5">
                              <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-4 section-title align-left">
                               
                                <span className="h5-small section-title align-left pt-5 pl-2 title-navy-blue-medium">
                                  {" "}
                                  Coming Soon ...
                                </span>
                              </h5>
                            </div>
                          )}
                                                    </Row> 
                                                
                                                    :
                                                    
                                                      <Row className="mx-0 pt-4 pb-2 ">
                                                      {Interns.length > 0  && Interns.map((item,index) => (
                                                         <Col xs={12} sm={12} md={12} lg={8} xl={6} className="pt-4">                     
                                                         <div className="team-img-container team">
                                                                                
                                                                             </div> 
                                                                        
                                                                         <Interns item={item} />
                                                                         </Col>
                                                     ))}
                                                 </Row>
                                                }
                                            </Col>
                                        </Row> 
                                    </TabPane>  */}
              </TabContent>
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    </ExternalLayout>
  );
};
Contributors.defaultProps = {
  isClassName: true,
};
Contributors.propTypes = {
  isClassName: PropTypes.bool,
};
export default Contributors;
