import React, { useState, useEffect } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import {makeMarathiAudiorequests} from '../Home/Component/Tools/URLLoader';
import {makeVideoRequests}  from '../Home/Component/Tools/roughvideoload';
import callVideoApi from '../Home/Component/Tools/ToolsVideoURL';
import callApi from '../Home/Component/Tools/ToolsURL';
import loadingGif from '../../assets/logo-loading.gif';
import syspingif from '../../assets/syspingif.gif';
import syspinlogo from '../../assets/Menulogo.png';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import application_contants from '../../contants/application_contants';
import SEOComponent from '../../customComponents/SEO/SEOComponent';

const LoadingModal = () => (
  <div className="loading-overlay">
  <div className="loading-modal">
    <div >
      <img className="loading-indicator" src={loadingGif} alt="Loading..." />
      <br/>
      <div className="loading-text">Loading ...</div>
    </div>
  </div>
  </div>
);
const LoadingMod = () => (
  <div className="loading-ovly">
  <div className="loading-mod">
    <div className="loading-content">
      <div className="loading-text h5-medium section-title jostRegular title-navy-blue">Welcome to the SYSPIN Demo </div>
      <div className="loading-text h5-medium section-title jostRegular title-navy-blue">of TTS in Indian Languages</div>
      <img className="loading-indi" src={syspingif} alt="Loading..." />
    </div>
    <br />
    <div className="loading-content">
    <img className="loading-indi loading-indilogo" src={syspinlogo} alt="Loading..."/>
    <br/>
    <div className="loading-text jostRegular title-navy-blue">Kindly wait while the models are loading...</div>
    </div>
  </div>
  </div>
);

const Marathitools = (props) => {
  const [width] = useWindowSize();

  React.useEffect(() => {
      window.scrollTo(0, 0);
  }, []);
    const [selectedGender, setSelectedGender] = useState('');
    // const [selectedWordBox, setSelectedWordBox] = useState('');
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('');
    const [text, setText] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [loadingCount, setLoadingCount] = useState(0);
  
    const radioOptions = {
      mal: ['glowtts', 'vits'],
      fem: ['glowtts', 'vits'],
    };


    const handleLoadingChange = () => {
      setLoadingCount((prevCount) => prevCount + 1);
    };
  
    const renderLoadingComponent = () => {
      if (loadingCount === 0) {
        return <LoadingMod />;
      } else {
        return <LoadingModal />;
      }
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          await makeMarathiAudiorequests(); // Assuming makeAllApiRequests is an asynchronous function
          await Promise.all([makeVideoRequests()]);
          await Promise.all([makeVideoRequests()]);
          setLoading(false);
          setShowForm(true);
          handleLoadingChange();
          
          // Assuming you set the selectedWordBox and selectedGender from some source
          // setSelectedWordBox('bn'); // Replace with actual value
          // setSelectedGender('mal'); // Replace with actual value
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData(); // Call the fetchData function
  
      // Scroll to the top when the component mounts
      window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
      if (showForm) {
        window.scrollTo(0, 0);
      }
    }, [showForm]);
    const generateRadioButtons = () => {
      const key = `${selectedGender}`;
      const options = radioOptions[key];
  
      if (options && options.length > 0) {
        return (
          <div className="model-buttons-row">
            {options.map((option, index) => (
              <div key={index} className="model-button">
                <input
                  type="radio"
                  name="radioOptions"
                  value={option}
                  checked={selectedRadio === option}
                  onChange={() => setSelectedRadio(option)}
                />
                <label className="selctfield">{option}</label>
              </div>
            ))}
          </div>
        );
      }else {
        return <p>Please choose Gender.</p>;
      }
    };

    const handleAudioSubmit = async (e) => {
      e.preventDefault();
  
      // const selectedTab = selectedWordBox;
      const selectedCheckbox = selectedGender;

    try {    
      setLoading(true);
        const data = await callApi(`mr`, selectedCheckbox, selectedRadio, text,setLoading, setAudioUrl, setModalVisible);
        const audioUrl = data.audioUrl;
        setAudioUrl(audioUrl);
        setModalVisible(true);       
    } catch (error) {
      console.error('Error generating audio:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleVideoSubmit = async (e) => {
    e.preventDefault();

    // const selectedTab = selectedWordBox;
    const selectedCheckbox = selectedGender;

  try {
    setLoading(true);
    const data = await callVideoApi(`mr`, selectedCheckbox, selectedRadio, text,setLoading, setVideoUrl, setModalVisible);
    const videoUrl = data.videoUrl;
    setVideoUrl(videoUrl);
    setModalVisible(true);
  } catch (error) {
    console.error('Error generating video:', error);
  } finally {
    setLoading(false);
  }
};


  const closeAudioModal = () => {
    setModalVisible(false);
    setAudioUrl(null);
    // Reset other state variables
    setSelectedGender('');
    setSelectedRadio('');
    setText('');
  };
  
  const closeVideoModal = () => {
    setModalVisible(false);
    setVideoUrl(null);
    // Reset other state variables
    setSelectedGender('');
    setSelectedRadio('');
    setText('');
  };
  
  return (
    <ExternalLayout>
    <RenderPage
        id="tst-Partners"
        className="strip render-page px-0"
        containerType="container-fluid"
    >
     <SEOComponent
            title="Marathi Tools"
            keywords="SYnthesizing SPeech in INdian languages"
            description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
            siteUrl="/Marathi Tools" />
        <Strip
            id="tst-strip-funding"
            className="strip strip-no-padding px-0"
            containerType="container"
        >
       
            <Row className="mx-0  pt-5">  
              <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                  <p className="mb-0 section-title align-left">
                    <Link to={`/toolslang`} className="custom-link-item">{` Tools > `}</Link>
                    <span className="h6-large pl-2 text-capitalize">Marathi TTS Tools </span>
                  </p>
              </Col>
                 {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left"> Marathi TTS Tools</h5> :
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Marathi TTS Tools</h5>}
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
     
  <>
  {loading && renderLoadingComponent() }
  {showForm && (
    <div className="form-container">
      <div className="form-box">
        <form className="form-str" id="customForm"  onSubmit={(e) => e.preventDefault()}>
          <div>
            <fieldset>
            <legend htmlFor="gender">Choose a Gender:</legend>
              <input type="radio" id="male" name="gender" value="mal" checked={selectedGender === 'mal'} onChange={() => setSelectedGender('mal')} />
                <label className="selctfield" htmlFor="male">Male</label>
              <input type="radio" id="female" name="gender" value="fem" checked={selectedGender === 'fem'} onChange={() => setSelectedGender('fem')} />
                <label className="selctfield" htmlFor="female">Female</label>
            </fieldset>
          </div>
              <fieldset id="radioButtons" className={loading ? 'hidden' : ''}>
              <legend htmlFor="models">Choose a Model:</legend>
              {generateRadioButtons()}
              </fieldset>

            <div>
              <input className="textarea_box" type="text" id="text" name="text" value={text}  onChange={(e) => setText(e.target.value)} placeholder="Enter your text here..." />
            </div>

            <div>            
              <button className="submit_button" type="submit" onClick={handleAudioSubmit}>Generate Audio</button>   {/* Button for generating audio */}            
              <button className="submit_button" type="submit" onClick={handleVideoSubmit}>Generate Video</button>   {/* Button for generating video */}          
            </div>
        </form>
      
          {/* <p id="print">{`mr${selectedGender} ${selectedRadio} ${text}`}</p>  */}
          
          {/* Modal for video content */}
          {videoUrl && modalVisible && (
            <>
              <div className={`modal-background ${modalVisible ? 'visible' : ''}`} onClick={closeVideoModal}></div>
              <div className="video-player-modal">
                {/* Add video player code here */}
                <video controls autoPlay>
                  <source src={videoUrl} type="video/mp4" />
                </video>
                <button onClick={closeVideoModal}>Close (X)</button>
              </div>
            </>
          )}

          {/* Modal for audio content */}
          {audioUrl && modalVisible && (
            <>
            <div className={`modal-background ${modalVisible ? 'visible' : ''}`} onClick={closeAudioModal}></div>
            <div className="audio-player-modal">
              <audio controls autoPlay>
                <source src={audioUrl} type="audio/mpeg" />
              </audio>
              <button onClick={closeAudioModal}>Close (X)</button>
            </div>
            </>
          )}
      </div>
    </div>
  )}
  </>


</Col>     
    </Row>
    
    </Strip>
    </RenderPage>
    </ExternalLayout>
    )
    };
    

export default Marathitools;
