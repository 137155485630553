import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import application_contants from '../../contants/application_contants';
import LineColor from '../LineColor/LineColor';
import RenderPage from '../RenderPage/RenderPage';
import Strip from '../Strip/Strip';


const Footer = (props) => {

    const handleNavigate = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        props.history.push('/terms-and-conditions')
    }

   

    return (
        <RenderPage
            id="tst-Footer"
            className="render-no-margin min-height-auto bg-navy-blue-medium px-0 footer-container"
            containerType="container-fluid"
        >
            <Strip
                id="tst-strip-Footer"
                className="strip strip-no-padding px-0"
                containerType="container-fluid"
            >
                <Row className="mx-0 position-relative py-4">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                        <Row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <div className="d-flex pl-3 align-items-center img-mobile--position">
                                    <div className="common-width ">
                                        <img src={application_contants.IISCLOGOIMG} alt="" />
                                    </div>
                                    <div className="pr-4">
                                        <img src={application_contants.DASH} alt="" />
                                    </div>
                                    <div className="common-width ">
                                        <img src={application_contants.SPARELABIMG} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                                <Row className="mx-0 pt-3 footer-mobile-alignment">
                                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <p className="h6-medium section-title  nunitosansLight title-white">
                                        © {new Date().getFullYear()} SYnthesizing SPeech in INdian languages 
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className="px-0">
                                        <p className="p-medium section-title nunitosansLight title-light-blue txt-underline pb-3 cursor-pointer" onClick={() => handleNavigate()}>Terms and Conditions</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="line-bt-container">
                    <LineColor />
                </div>
            </Strip>
        </RenderPage>
    )
};

export default withRouter(Footer);