import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponents/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Strip from "../../../customComponents/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponents/SEO/SEOComponent";
import PropTypes from "prop-types";


// import Male Audio Samples
import audiof1 from "../../../assets/sampleDatasets/TeluguFemale/telugu_general_f.wav";
import audiof2 from "../../../assets/sampleDatasets/TeluguFemale/telugu_edu_f.wav";
import audiof3 from "../../../assets/sampleDatasets/TeluguFemale/telugu_gen_f.wav";


function Telugu_Female(){
    return( 
    <div className="container">
        <table>
            {/* Table Heading */}
            <thead className="headTable">
            <tr>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenRow"><ReactPlayer   
                        url={audiof1}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenRow"><p className="p-medium-line-spacing">తండ్రిలాగానే,తను కూడా,సంస్కృత పండితుణ్ని కావాలనే కోరికతో,ఫిరోజ్,జయపూర్లోని రాష్ట్రీయ సంస్కృత సంస్థాన్ లో,చదువు పూర్తి చేసుకుని, అక్కడ,మూడేళ్లు ఫ్యాకల్టీగా పనిచేశాడు.</p></td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddRow"><ReactPlayer   
                        url={audiof2}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddRow"><p className="p-medium-line-spacing">ప్రస్తుతం చీఫ్ ఇంజినీర్‌లుగా ఉన్న దుర్గా ప్రసాద్ జీవీఎంసీ, ఆంజనేయులు సీఆర్డీఏ, యోహన్ రాజన్ రాజీవ్ సగృహ, మోజెస్కుమార్ అమరావతి డెవలప్మెంట్ కార్పొరేషన్, చంద్రయ్య ఇన్చార్జి ఈఎన్సీ లు,మొదటి ఐదు స్థానాల్లో ఉన్నారు. </p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                    <td className="tableEvenRow"><ReactPlayer   
                        url={audiof3}
                        width="400px"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenRow"><p className="p-medium-line-spacing">కాగా,గత ఏడాది,ఒక భారతీయ కళాకారుడు రూపొందించిన కళాఖండాల్లో,అత్యధిక విలువకు అమ్ముడు పోయిన కళాఖండం,అక్బర్ పదమ్సీ రూపొందించిన కళా ఖండం.</p></td>
            </tr>
            </tbody>

           
            
        </table>


    </div>

    );
}

const Sample_Telugu_Female = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            > 
                <SEOComponent
                    title="SampleTeluguFemale"
                    keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/SampleTeluguFemale" />
                   
                    <Strip
                        id="tst-SampleDatasets"
                        className="strip strip-no-padding px-20"
                        containerType="container"
                    >
                   

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Telugu Female Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Telugu_Female />
                            </div>
                    </div>

</Strip>
</RenderPage>
</ExternalLayout>
);
};

export default Sample_Telugu_Female;