import React from 'react';
import { Row, Col } from 'reactstrap';
import Strip from '../../../customComponents/Strip/Strip';
import { useWindowSize } from "@react-hook/window-size";
import application_contants from '../../../contants/application_contants';


const Information = () => {
    const [width] = useWindowSize();

    return (

        <Strip
            id="tst-strip-home"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row className="mx-0 pb-5">
                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                    <Row className="mx-0">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          {width <= application_contants.MOBILE_BREAKPOINT ?  <h1 className="h6-small section-title title-navy-blue jostRegular align-left py-5">Bringing voice AI to marginalised populations in India</h1> :
                           <h1 className="h3-large align-left  section-title title-navy-blue jostRegular py-5 ">Bringing voice AI to marginalised populations in India</h1>}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                            <p className="p-medium section-title jostLight align-left title-dark-grey lh-30px pb-4">
                                Providing people with information in their own native language is a key driver of economic empowerment and political participation. However, the diversity and lack of technological support for spoken languages in India makes universal access to information and services an ongoing challenge. The advancement of artificial intelligence-driven speech technology is yet to meet the requirements in many of the low-resourced Indian languages that suffer from the unavailability of linguistic expertise and electronic resources. This increases the necessity for the development of corpora and systems for the development of various speech technology solutions to provide different voice-based user-friendly applications.
                            </p>
                            <p className="p-medium section-title jostLight align-left title-dark-grey lh-30px">
                                So here is the SYSPIN to develop and open source a large corpus and models for text-to-speech (TTS) systems in multiple Indian languages. It reduces the main barriers to voice-based technologies and creates a potential market for technical innovators and social entrepreneurs. Nine Indian languages considered for this project are Hindi, Bengali, Marathi, Telugu, Bhojpuri, Kannada, Magadhi, Chhattisgarhi, and Maithili. The development of TTS corpora and AI models potentially benefits 602.1 million speakers of these languages. Overall, the collection of open voice data strengthens the local AI ecosystem for the development of voice technologies.
                            </p>
                        </Col>
                        
                        
                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                            <h1 className="h1-small section-title nunitosansItalic py-5 title-light-blue">More details are coming soon…</h1>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        </Strip>

    )
};

export default Information;