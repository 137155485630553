import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Strip from "../Strip/Strip";

const MessageConfirmation = (props) => {
    return (
        <div>
            <Strip
                id="tst-confirmation"
                className="strip strip-no-padding"
                containerType="container-fluid"
            >
                <Row>
                    <Col xs={10} sm={10} md={12} lg={12} xl={12}>
                        <Modal
                            isOpen={props.isModal}
                            //  toggle={props.toggle}
                            className="modal-container modal-md modal-dialog-centered"
                        >
                            <ModalBody className="confirmation-content__container">
                                <Row className="no-margin">
                                    <Col
                                        xs={12}
                                        ms={12}
                                        md={10}
                                        lg={10}
                                        xl={10}
                                        className="m-auto"
                                    >
                                        <Row className="no-margin">
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={10}
                                                lg={10}
                                                xl={10}
                                                className="m-auto px-0 pb-3"
                                            >
                                                <p className="mb-0 internal-section__title hpSimplified-light title-black fw-800 text-center">
                                                    {props.message}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="no-margin">
                                    <Col
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="m-auto px-0 bg-light-white p-3"
                                        onClick={() => props.handleCancelBtn()}
                                    >
                                        <p className="mb-0 internal-section__title nunitoSans-SemiBold title-danger fw-800 text-center cursor-pointer">
                                            Cancel
                    </p>
                                    </Col>
                                    <Col
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="m-auto px-0 bg-pink p-3"
                                        onClick={() => props.handleConfirmBtn()}
                                    >
                                        <p className="mb-0 internal-section__title nunitoSans-SemiBold  title-black fw-800 text-center cursor-pointer">
                                            Confirm
                    </p>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </Col>
                </Row>
            </Strip>
        </div>
    );
};

export default MessageConfirmation;
