//Action Types
export const UPDATE_PEOPLE = `people/UPDATE_PEOPLE`;

export const initialState = [];

const PeopleReducer = (prevState, action) => {
  switch (action.type) {
    case UPDATE_PEOPLE:
      return action.data;

    default:
      return prevState;
  }
};

export default PeopleReducer;