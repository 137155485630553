import React, { useState } from "react";
import { Col, Row } from "reactstrap";
// import {ApiConfig} from '../../../../api/apiConfig'
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import Slider from "react-slick";
import { useWindowSize } from '@react-hook/window-size';
import { Button, Modal } from 'react-bootstrap';

const StudentsSlider = (props) => {
    const [width] = useWindowSize();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imgurl, setImgurl] = useState([]);
    const [name, setName] = useState([]);
    const [bio, setBio] = useState([]);
    const handleNavigationPopup=() => {
      setShow(true);
      setName(props.item.name)
      setImgurl(props.item.imgUrl)
      setBio(props.item.bio)
      console.log("item.firstClassName",props.item.firstClassName)
  }
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 team-mobile-position ">
        <Row className="mx-0">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-5"
              >
               <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-5"
              >
                <div className="container" onClick={() => handleNavigationPopup()}>   
                  <div variant="primary" >
                      <div className="popup-team-img-container cursor-pointer team">
                        <div className="academics-img-container"> 
                          <img src={`${ApiConfig.url}${props.item.imgUrl}`} alt="" />
                          <div className="second-circle-container" style={{border: `15px solid ${props.item.firstClassName}`,}}>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 ">
                        <div>
                          <h6 className="h6-small cursor-pointer section-title  nunitoSans-Regular align-center pt-3">{props.item.name}</h6>
                        </div>
                      </div>
                  </div>
                </div>
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Body>
                    <div className="container" >   
                  <div variant="secondary" >
                  <div className="popup-team-img-container pt-4 team">
                        <div className="academics-img-container "> 
                          <img src={`${ApiConfig.url}${props.item.imgUrl}`} alt="" />
                          <div className="second-circle-container " style={{border: `15px solid ${props.item.firstClassName}`,}}>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 ">
                        <div>
                          <h6 className="h6-small section-title academics-img-container nunitoSans-Regular align-center pt-3">{props.item.name}</h6>
                          <p className="mb-0 section-title academics-img-container align-center">{bio}</p>
                        </div>
                      </div>
                      {/* <div className="popup-team-img-container team pt-4">
                        <div className="academics-img-container "> 
                          <img src={`${ApiConfig.url}${imgurl}`} alt="" />
                          <div className="second-circle-container" style={{border: `15px solid ${props.item.firstClassName}`,}}>
                          </div>
                        </div>
                      </div>
                      <h6 className="h6-medium mb-0 section-title  academics-img-container align-center pt-4">{name}</h6>
                      <p className="mb-0 section-title academics-img-container align-center">{bio}</p> */}

                    </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal> 
              </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StudentsSlider;