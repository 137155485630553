
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Slider from "react-slick";
import { useWindowSize } from '@react-hook/window-size';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import ContributorService from "../../../api/services/ContributorService";
import debugLogger from '../../Utils/debugLogger';
import { Button, Modal } from 'react-bootstrap';
import { Description } from '@material-ui/icons';


const Experts = (props) => {
const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imageUrl, setImgurl] = useState([]);
    const [name, setName] = useState([]);
    const [description, setDescription] = useState([]);
    const [url, setUrl] = useState([]);
const [designation, setDesignation] = useState([]);

    const handleNavigationPopup=() => {
      setShow(true);
     setName(props.item.name)
      setImgurl(props.item.imageUrl)
      setDescription(props.item.description)
      setDesignation(props.item.designation)
      setDescription(props.item.description)
      setUrl(props.item.url)

      console.log("item.firstClassName",props.item.firstClassName)
  }
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 team-mobile-position mx-5 ">
        <Row className="mx-5">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-5"
              >
              
              <div className="container ">   
                   <div variant="primary" onClick={() => handleNavigationPopup()}>
                    <div className="popup-team-img-container cursor-pointer team ">
                    <div className="academics-img-container "> 
                      <img src={`${ApiConfig.url}${props.item.imageUrl}`} alt="" />
                      <div className="second-circle-container" style={{border: `15px solid ${props.item.firstClassName}`,}}>
                      </div>
                   </div>
                   </div>
                    <h6 className="h6-small section-title  JostRegular popup-team-img-container align-center pt-4">{props.item.name}</h6>
                    <p className="p-small section-title pt-1 JostRegular popup-team-img-container align-center ">{props.item.designation}</p>
                    {/* <div classname='a-tag-container pb-3'>
                      <a href={`${props.item.url}`} rel="noreferrer" target="_blank"   className="p-small section-title custom-reference-links mb-0 JostRegular align-center a-tag">{props.item.url}</a>
                </div> */}
                </div>
                </div>

                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Body>
                    <div className="container" >   
                  <div variant="primary" >
                      <div className="popup-team-img-container  team pt-4">
                        <div className="academics-img-container  "> 
                          <img src={`${ApiConfig.url}${imageUrl}`} alt="" />
                          <div className="second-circle-container" style={{border: `1px solid ${props.item.firstClassName}`,}}>
                          </div>
                        </div>
                      </div>
                      <h6 className="h6-medium mb-0 section-title  academics-img-container align-center pt-4">{name}</h6>
                      <p className="mb-0 section-title academics-img-container pt-1 align-center">{designation}</p>
                      <p className="mb-0 section-title academics-img-container pt-2 align-center">{description}</p>
                      {url.length > 0 ?
                      <a href={`${url}`} rel="noreferrer" target="_blank"   className="p-small academics-img-container team-img-container section-title custom-reference-links mb-0 pt-2 JostLight align-center a-tag">View Profile</a>
                      :null }
                    </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal> 
              </Col>
        </Row>
      </Col>
    </Row>
  );
 };

 export default Experts;
