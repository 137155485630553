import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import { useWindowSize } from '@react-hook/window-size';
import application_contants from '../../contants/application_contants';
import ImageViewer from '../../customComponents/ImageViewer/ImageViewer'
import SEOComponent from '../../customComponents/SEO/SEOComponent';
import FundermeetData from '../../Utils/json/FundermeetData';


const Fundermeet_Gallery = (props) => {
    const [width] = useWindowSize();
    const [isModal, setIsModal] = useState(false);
    const { imgList, playListId, } = props;
    const [data, setData] = useState(FundermeetData);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [imageData, setImageData] = useState([]);
    let imagePOpup = imgList;
    const handleToggle = () => {
        setImageData([...data])
        setIsModal(!data)
    }
    React.useEffect(() => {
        setImageData([...data])
    },data)
    const handleToggleIndex = (index) => {
        let templist = imageData;
        let list =  templist;
         list.splice(0, 0, list.splice(index, 1)[0]);
        // list = list.filter((val) => val != "text");
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Partners"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="GIZ_Meeting_Gallery"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/giz_meeting_gallery" />
                <Strip
                    id="tst-strip-funding"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
               
                    <Row className="mx-0  pt-5">  
                         {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left"> Meet with GIZ Gallery</h5> :
                                <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Meet with GIZ Gallery</h5>}
                          {/* <div >  */}
                          {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                        <h2 className="h5-small section-title align-left pt-2 title-navy-blue-medium">Coming soon ...</h2>
                        </Col> */}
        {width >= application_contants.MOBILE_BREAKPOINT ?
        <Row className="mx-0 mb-5 pb-5">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
                {data.length> 0 && data.map((item, index) =>(
                  <Col xs={12} sm={12} md={6} lg={4} xl={4} key={index} className="pb-5 image">
                      <img src={item} alt="" className="gal-img object-fit_contain cursor-pointer" onClick={() => handleToggleIndex(index,data)}/>
                  </Col>
                ))}
            </Row>
        </Col>
            {isModal ?
                <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} isServerPath={false} />
                : null}
        </Row>
        :
        <Row className="mx-0 pl-3 ">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
                {data.length> 0 && data.map((item, index) =>(
                  <Col xs={10} sm={6} md={10} lg={4} xl={4} key={index} className="pb-4 image">
                      <img src={item} alt="" className="mobile-gal-img object-fit_contain" onClick={() => handleToggleIndex(index,data)}/>
                  </Col>
                ))}
            </Row>
        </Col>
            {isModal ?
                <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} isServerPath={false} />
                : null}
        </Row>
        }
        {/* </div> */}
                    </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default Fundermeet_Gallery;



// import React from 'react';

// const Fundermeet_Gallery = () => {
//   return (
//     <div>
//       <h1>InputFolder Album</h1>
//       <iframe
//         src="https://photos.app.goo.gl/QaYW8UtpYPDo8W1y5"
//         width="100%"
//         height="600"
//         frameBorder="0"
//         scrolling="no"
//         title="Google Photos Album"
//       ></iframe>
//     </div>
//   );
// };

// export default Fundermeet_Gallery;