const PeopleTypeData = [
    {
        label: 'Advisor',
        value: 1
    },
    {
        label: 'Team Member',
        value: 2
    }
];

export default PeopleTypeData;