import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Form, Label, Button, FormGroup, Input } from 'reactstrap';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponents/ConfirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponents/Loader/Loader';
import Strip from '../../../customComponents/Strip/Strip';
import PublicationService from '../../../../api/services/PublicationService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddPubliction = (props) => {

    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [link, setLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setTitle('');
            setAuthor('');
            setDescription('');
            setDate('');
            setLink('');
        } else {
            setId(props.item.id);
            setTitle(props.item.title);
            setAuthor(props.item.author);
            setDescription(props.item.description)
            setDate(props.item.date)
            setLink(props.item.link)
        }
    }, [isAdd, props]);

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleAuthor = (e) => {
        setAuthor(e.target.value)
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleDate = (e) => {
        setDate(e.target.value)
    }
    const handleLink = (e) => {
        setLink(e.target.value)
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            userId: userId,
            id: id,
            title: title,
            author: author,
            description: description,
            date: date,
            link: link,
        }
        await PublicationService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log()
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-opening"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-theme-blue">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD PUBLICATION" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                            <Row>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Publication Year:
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    placeholder="year"
                                                                    id="date"
                                                                    name="date"
                                                                    dateFormat='YYYY'
                                                                    className="form-control common-form-control"
                                                                    value={date}
                                                                    onChange={(event) => handleDate(event)}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Publication Title:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Title"
                                                                    id="title"
                                                                    name="title"
                                                                    className="form-control common-form-control"
                                                                    value={title}
                                                                    onChange={(event) => handleTitle(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Publication Author:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Author"
                                                                    id="author"
                                                                    name="author"
                                                                    className="form-control common-form-control"
                                                                    value={author}
                                                                    onChange={(event) => handleAuthor(event)}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Publication Abstract:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="Text"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={description}
                                                                    onChange={(event) => handleDescription(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Publication Link:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Link"
                                                                    id="link"
                                                                    name="link"
                                                                    className="form-control common-form-control"
                                                                    value={link}
                                                                    onChange={(event) => handleLink(event)}
                                                                />
                                                            </Col>
                                                           
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 3, size: 6 }}
                                                    lg={{ offset: 3, size: 6 }}
                                                    xl={{ offset: 3, size: 6 }}
                                                >
                                                    <Row className="pt-4">
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Strip>
    )
}
export default AddPubliction;