import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import {
    useWindowSize
  } from '@react-hook/window-size';
import application_contants from '../../contants/application_contants';
import ImageViewer from '../../customComponents/ImageViewer/ImageViewer'
import GalleryData from '../../Utils/json/GalleryData';
import SEOComponent from '../../customComponents/SEO/SEOComponent';


const Photos = (props) => {
    const [width] = useWindowSize();


    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Partners"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Photos"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/photos" />
                <Strip
                    id="tst-strip-funding"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
               
                    <Row className="mx-0  pt-5">  
                         {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left"> Gallery</h5> :
                                <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Gallery</h5>}
                          {/* <div >  */}
                          {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                        <h2 className="h5-small section-title align-left pt-2 title-navy-blue-medium">Coming soon ...</h2>
                        </Col> */}
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                        {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/bhashini_gallery`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/bhashinigall.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">Bhashini Gallery</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/giz_meeting_gallery`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/gizgall.php"} rel="noreferrer" target="_blank">
                                                <Row className="mb-4 mx-1">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                        <div>
                                                            <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">Meet With GIZ Gallery</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/giz_meeting_gallery`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/limmits.php"} rel="noreferrer" target="_blank">
                                                <Row className="mb-4 mx-1">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                        <div>
                                                            <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">LIMMITS’23</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/giz_meeting_gallery`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/gizvisit.php"} rel="noreferrer" target="_blank">
                                                <Row className="mb-4 mx-1">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                        <div>
                                                            <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">GIZ Visit in 2023 </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </Col>     
                                        
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                        {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/bhashini_gallery`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/specom23.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">SPECOM'23 Conference</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>  
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/giz_meeting_gallery`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/limmits24.php"} rel="noreferrer" target="_blank">
                                                <Row className="mb-4 mx-1">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                        <div>
                                                            <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">LIMMITS’24</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                        </Col>                    
	                                    <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                        {/* <a href={`/gallery/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/sysgall/miscellaneous.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large section-title jostSemiBold title-black align-center pl-2 pt-3 opening-card-position">Miscellaneous</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>     
                    </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default Photos;
