import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponents/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import ToolsService from '../../../api/services/ToolsService';
import application_contants from '../../contants/application_contants';
import { useWindowSize } from '@react-hook/window-size';
import DatasetsService from '../../../api/services/DatasetsService';
import SEOComponent from '../../customComponents/SEO/SEOComponent';


const Datasets = () => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, []);

    const getList = async () => {
        setIsLoading(true);
        await DatasetsService.getdatasetsList()
            .then(async (res) => {
                console.log("datasets result", res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("before data list", result)
                            if (result.code === 0) {
                                if (result.data) {
                                    // debugLogger(result.data);
                                    const lists = result.data.list.filter((it) => it.status === 1);
                                    setData([...lists])
                                    console.log("data list", ...result.data.list)
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-datasets"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                    title="Datasets"
                    keywords="SYnthesizing SPeech in INdian languages"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/datasets" />
                <Strip
                    id="tst-strip-datasets"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
               
                    {/* <Row className="mx-0  pt-5">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                            <h2 className="h2-small section-title align-left pt-5 title-navy-blue-medium">Coming soon ....</h2>
                        </Col>
                        // comment <Col xs={12} sm={12} md={6} lg={12} xl={width === 1024 ? 5 : 4}


                    </Row> */}
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title jostRegular title-navy-blue pb-4 align-left">Datasets</h5> :
                                        <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Datasets</h3>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-3">
                                {data.length > 0 ?
                                    <Row>
                                        {data.map((item,index) =>{
                                                return <Col xs={12} sm={12} md={6} lg={6} xl={6} key={index} className="datasets-card-br pb-4">
                                                       <a href={`/datasets/${item.title.toLowerCase()}`}>
                                                        <Row className="mb-3 mx-0">
                                                            <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 datasets-card datasets-info-container '>
                                                            <div>
                                                                <div>
                                                                     <p className="p-large section-title jostSemiBold title-black align-center datasets-card-position">{item.title}</p>
                                                                    <p className="p-large section-title jostRegular title-black align-center datasets-card-position">{item.time}</p>
                                                                </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        </a>
                                                </Col>  
                                        })}
                                    </Row> 
                                : 
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                                    <h2 className="h5-small section-title align-left pt-5 title-navy-blue-medium">Coming soon ....</h2>
                                    </Col> 
                                </Row>
                                }
                                </Col>
                               
                            </Row>
                            </Col>
                            </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default Datasets;