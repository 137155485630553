import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ToolsService = {
    getToolsList: async () => {
        const { baseUrl, getToolsList } = ApiConfig;

        const url = baseUrl + getToolsList;
        console.log('url');
        console.log(url)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const ToolsServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_tools",
            true
        );
        console.log("ToolsServicePromise",ToolsServicePromise)
        return ToolsServicePromise;
    },

    insertOrUpdate: async (data) => {
        const { baseUrl, insertORUpdateTools } = ApiConfig;
        console.log("insertORUpdateTools",insertORUpdateTools)
        const url = baseUrl + insertORUpdateTools;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        console.log("url",url)
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: data.title,
            detailTitle: data.detailTitle,
            description: data.description,
            url: data.url,
        });

        const ToolsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_toolsService",
            true
        );
        return ToolsServicePromise;
    },
    enableDisableTools: async (data) => {
        const { baseUrl, enableDisableTools } = ApiConfig;
        const url = baseUrl + enableDisableTools;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableToolsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableTools",
            true
        );
        return enableDisableToolsServicePromise;
    },
}

export default ToolsService;