import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const MediaService = {
    getMediaList: async () => {
        const { baseUrl, getMediaList } = ApiConfig;

        const url = baseUrl + getMediaList;
        console.log('url');
        console.log(url)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const mediaServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_opening",
            true
        );
        console.log("mediaServicePromise",mediaServicePromise)
        return mediaServicePromise;
    },

    insertOrUpdate: async (data) => {
        const { baseUrl, insertORUpdateMedia } = ApiConfig;
        console.log("insertORUpdateMedia",insertORUpdateMedia)
        const url = baseUrl + insertORUpdateMedia;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        console.log("url",url)
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: data.title,
            mediaList: data.mediaList
        });

        const mediaServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_openingService",
            true
        );
        return mediaServicePromise;
    },
    enableDisableMedia: async (data) => {
        const { baseUrl, enableDisableMedia } = ApiConfig;
        const url = baseUrl + enableDisableMedia;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableMediaPromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableMediaPromise",
            true
        );
        return enableDisableMediaPromise;
    },
}

export default MediaService;