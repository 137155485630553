import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const DesignationService = {
    getList: async () => {
        const { baseUrl, getDesignationList } = ApiConfig;

        const url = baseUrl + getDesignationList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const designationServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_designation",
            true
        );
        return designationServicePromise;
    },


};

export default DesignationService;
