import React, { useContext, useState } from "react";
import { Route } from "react-router-dom";
import About from "../customPages/About/About";
import Contact from "../customPages/Contact/Contact";
import Funding from "../customPages/Funding/Funding";
import Home from "../customPages/Home/Home";
//import Partners from "../customPages/Partners/Partners";
import TermsAndConditions from "../customPages/TermsAndConditions/TermsAndConditions";
import Login from "../customPages/Login/Login";
import PublicRoute from "../customComponents/PublicRoute/PublicRoute";
import Authenticated from "../customComponents/Authenticated/Authenticated";
import { AuthContext } from "../context/AuthProvider";
import Openings from "../customPages/Openings/Openings";
// import Advisors from "../customPages/Advisors/Advisors";
import Challenges from "../customPages/Challenges/Challenges";
//import Tools from "../customPages/Tools/Tools";
import Datasets from "../customPages/Datasets/Datasets";
import Publications from "../customPages/Publications/Publications";
// import Teammember from "../customPages/TeamMembers/Teammember";
import Documents from "../customPages/Documents/Documents";
//import Gallery from "../customPages/Gallery/Gallery";
import Media from "../customPages/Media/Media";
import Areas from "../customPages/Areas/Areas";
import Contributors from "../customPages/Contributors/Contributors";
import OpeningsDetail from "../customPages/Openings/OpeningsDetail";
import OpeningList from "../customPages/Admin/Opening/OpeningList";
import MediaList from "../customPages/Admin/Media/MediaList"
import ChallengesDetail from "../customPages/Challenges/ChallengesDetail";
import ChallengesList from "../customPages/Admin/Challenges/ChallengesList";
import PeopleList from "../customPages/Admin/People/PeopleList";
import People from "../customPages/People/People";
import Advisors from "../customPages/People/Advisors";
import TeamMembers from "../customPages/People/TeamMembers";
import PeopleDetails from "../customPages/PeopleDetails/PeopleDetails";
import PublicationList from "../customPages/Admin/Publications/PublicationList";
import ReseachAreaList from "../customPages/Admin/ResearchArea/ResearchAreaList";
import IndustryDetail from "../customPages/Contributors/IndustryDetail";
import AcademicDetail from "../customPages/Contributors/AcademicDetail";
import DocumentsList from "../customPages/Admin/Documents/DocumentsList"
import IndividualList from "../customPages/Admin/Individual/IndividualList"
import AcademicsList from "../customPages/Admin/Academics/AcademicsList"
import IndustryList from "../customPages/Admin/Industry/IndustryList"
import AreasDetail from "../customPages/Areas/AreasDetail";
import PartnerList from '../customPages/Admin/Partners/PartnerList';
import BannerList from '../customPages/Admin/Banner/BannerList';
import ToolsDetail from '../customPages/Tools/ToolsDetail';
import ToolList from "../customPages/Admin/Tools/ToolList";
import DatasetsDetail from "../customPages/Datasets/datasetsDetail";
import DatasetsList from "../customPages/Admin/Datasets/DatasetsList";
import ExpertList from "../customPages/Admin/Experts/ExpertList";

import ToolsLang from "../customPages/ToolsLang/Toolslang";
import Marathitools from "../customPages/ToolsLang/MarathiTools";

import Evolutiontools from "../customPages/Evolutiontools/Evolutiontools";
import Photos from "../customPages/Gallery/Photos";
import Bhashini_Gallery from "../customPages/Gallery/Bhashini";
import Fundermeet_Gallery from "../customPages/Gallery/Fundermeet";
import Miscellaneous from "../customPages/Gallery/Miscellaneous";
import APIrequests from "../customPages/Home/Component/Tools/APIrequests";
import Videorequests from "../customPages/Home/Component/Tools/Videorequests";
import Mediapdf from "../customPages/Admin/Media/temporary/pdfreader";
import Mediaimg_1 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg1";
import Mediaimg_2 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg2";
import Mediaimg_3 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg3";
import Mediaimg_4 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg4";
import Mediaimg_5 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg5";
import Mediaimg_6 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg6";
import Mediaimg_7 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg7";
import Mediaimg_8 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg8";
import Mediaimg_9 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg9";
import Mediaimg_10 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg10";
import Mediaimg_11 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg11";
import Mediaimg_12 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg12";
import Mediaimg_13 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg13";
import Mediaimg_14 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg14";
import Mediaimg_15 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg15";
import MediaAudio1 from "../customPages/Admin/Media/temporary/mediaaudio/mediaaudio1";
import Sample_Marathi_Male from "../customPages/Datasets/TemporarySample/SampleMarathiMale";
import Sample_Marathi_Female from "../customPages/Datasets/TemporarySample/SampleMarathiFemale";
import Sample_Telugu_Female from "../customPages/Datasets/TemporarySample/SampleTeluguFemale";
import Sample_Telugu_Male from "../customPages/Datasets/TemporarySample/SampleTeluguMale";
import Sample_Hindi_Female from "../customPages/Datasets/TemporarySample/SampleHindiFemale";
import Sample_Hindi_Male from "../customPages/Datasets/TemporarySample/SampleHindiMale";
import Sample_Bhojpuri_Female from "../customPages/Datasets/TemporarySample/SampleBhojpuriFemale";
import Sample_Bhojpuri_Male from "../customPages/Datasets/TemporarySample/SampleBhojpuriMale";
import Sample_Chhatt_Male from "../customPages/Datasets/TemporarySample/SampleChhattMale";  
import Sample_Chhatt_Female from "../customPages/Datasets/TemporarySample/SampleChhattFemale";  
import Sample_Bengali_Female from "../customPages/Datasets/TemporarySample/SampleBengaliFemale"; 
import Sample_Bengali_Male from "../customPages/Datasets/TemporarySample/SampleBengaliMale";
import Sample_Kannada_Female from "../customPages/Datasets/TemporarySample/SampleKannadaFemale";
import Sample_Kannada_Male from "../customPages/Datasets/TemporarySample/SampleKannadaMale";
import Sample_Maithili_Female from "../customPages/Datasets/TemporarySample/SampleMaithiliFemale";
import Sample_Maithili_Male from "../customPages/Datasets/TemporarySample/SampleMaithiliMale";
import Sample_Magadhi_Female from "../customPages/Datasets/TemporarySample/SampleMagadhiFemale";
import Sample_Magadhi_Male from "../customPages/Datasets/TemporarySample/SampleMagadhiMale";

const RoutesComponent = (props) => {
    const appContext = useContext(AuthContext);
    const [authenticated, setAuthenticated] = useState(AuthContext.isLogin);

    React.useEffect(() => {
        const handleAuthenticated = async () => {
            await setAuthenticated(appContext ? appContext[0].isLoggedIn : false)
        }
        handleAuthenticated();
    }, [appContext]);

    return (
        <div>
            <Route exact path={'/'} component={Home} {...props} />
            <Route exact path={'/about'} component={About} {...props} />
            <Route exact path={'/funding'} component={Funding} {...props} />
            {/* <Route exact path={'/partners'} component={Partners} {...props} /> */}
            <Route exact path={'/contact-us'} component={Contact} {...props} />
            <Route exact path={'/terms-and-conditions'} component={TermsAndConditions} {...props} />
            <Route exact path={'/Openings'} component={Openings} {...props} />
            {/* <Route exact path={'/Advisors'} component={Advisors} {...props} /> */}
            <Route exact path={'/Challenges'} component={Challenges} {...props} />
            {/* <Route exact path={'/Tools'} component={Tools} {...props} /> */}
            <Route exact path={'/Datasets'} component={Datasets} {...props} />
            <Route exact path={'/Publications'} component={Publications} {...props} />
            {/* <Route exact path={'/Teammembers'} component={Teammember} {...props} /> */}
            <Route exact path={'/Document'} component={Documents} {...props} />
            {/*<Route exact path={'/Gallery'} component={Gallery} {...props} /> */}
            
            <Route exact path={'/Evolution-of-Tools'} component={Evolutiontools} {...props} />
            <Route exact path={'/Gallery'} component={Photos} {...props} />
            <Route exact path={'/Media'} component={Media} {...props} />
            <Route exact path={'/Areas'} component={Areas} {...props} />
            <Route exact path={'/Contributor'} component={Contributors} {...props} />
            <Route exact path='/openings/:name' component={OpeningsDetail}  {...props} />
            <Route exact path='/ChallengesDetails' component={ChallengesDetail}  {...props} />
            <Route exact path="/team" component={People} {...props} />
            <Route exact path="/advisors" component={Advisors} {...props} />
            <Route exact path="/teamMembers" component={TeamMembers} {...props} />
            <Route exact path="/peopleDetails" component={PeopleDetails} {...props} />
            <Route exact path='/Academics/:name' component={AcademicDetail}  {...props} />
            <Route exact path='/Industry/:name' component={IndustryDetail}  {...props} />
            <Route exact path='/Areas/:name' component={AreasDetail}  {...props} />
            <Route exact path='/tools/:name' component={ToolsDetail}  {...props} />
            <Route exact path='/datasets/:name' component={DatasetsDetail}  {...props} />

            <Route exact path={'/gallery/bhashini_gallery'} component={Bhashini_Gallery} />
            <Route exact path={'/gallery/giz_meeting_gallery'} component={Fundermeet_Gallery} />
            
            <Route exact path={'/gallery/miscellaneous'} component={Miscellaneous} />
            <Route exact path={'/Tools'} component={APIrequests} />
            <Route exact path={'/toolslang'} component={ToolsLang} />
            <Route exact path={'/toolslang/marathitools'} component={Marathitools} />


            <Route exact path={'/Video'} component={Videorequests} />
            <Route exact path={'/Mediapdf'} component={Mediapdf} />
            <Route exact path={'/Mediaimg1'} component={Mediaimg_1} />
            <Route exact path={'/Mediaimg2'} component={Mediaimg_2} />
            <Route exact path={'/Mediaimg3'} component={Mediaimg_3} />
            <Route exact path={'/Mediaimg4'} component={Mediaimg_4} />
            <Route exact path={'/Mediaimg5'} component={Mediaimg_5} />
            <Route exact path={'/Mediaimg6'} component={Mediaimg_6} />
            <Route exact path={'/Mediaimg7'} component={Mediaimg_7} />
            <Route exact path={'/Mediaimg8'} component={Mediaimg_8} />
            <Route exact path={'/Mediaimg9'} component={Mediaimg_9} />
            <Route exact path={'/Mediaimg10'} component={Mediaimg_10} />
            <Route exact path={'/Mediaimg11'} component={Mediaimg_11} />
            <Route exact path={'/Mediaimg12'} component={Mediaimg_12} />
            <Route exact path={'/Mediaimg13'} component={Mediaimg_13} />
            <Route exact path={'/Mediaimg14'} component={Mediaimg_14} />
            <Route exact path={'/Mediaimg15'} component={Mediaimg_15} />
            <Route exact path={'/MediaAudio1'} component={MediaAudio1} />
            <Route exact path={'/SampleMarathiMale'} component={Sample_Marathi_Male} />
            <Route exact path={'/SampleMarathiFemale'} component={Sample_Marathi_Female} />
            <Route exact path={'/SampleTeluguFemale'} component={Sample_Telugu_Female} />
            <Route exact path={'/SampleTeluguMale'} component={Sample_Telugu_Male} />
            <Route exact path={'/SampleHindiMale'} component={Sample_Hindi_Male} />
            <Route exact path={'/SampleHindiFemale'} component={Sample_Hindi_Female} />


            <Route exact path={'/SampleBhojpuriFemale'} component={Sample_Bhojpuri_Female} />
            <Route exact path={'/SampleBhojpuriMale'} component={Sample_Bhojpuri_Male} />
            <Route exact path={'/SampleChhattFemale'} component={Sample_Chhatt_Female} />
            <Route exact path={'/SampleChhattMale'} component={Sample_Chhatt_Male} />

            <Route exact path={'/SampleKannadaFemale'} component={Sample_Kannada_Female} />
            <Route exact path={'/SampleKannadaMale'} component={Sample_Kannada_Male} />

            <Route exact path={'/SampleMaithiliFemale'} component={Sample_Maithili_Female} />
            <Route exact path={'/SampleMaithiliMale'} component={Sample_Maithili_Male} />

            <Route exact path={'/SampleMagadhiFemale'} component={Sample_Magadhi_Female} />
            <Route exact path={'/SampleMagadhiMale'} component={Sample_Magadhi_Male} />

            <Route exact path={'/SampleBengaliFemale'} component={Sample_Bengali_Female} />
            <Route exact path={'/SampleBengaliMale'} component={Sample_Bengali_Male} />

            

            <PublicRoute
                exact
                path={'/login'}
                component={Login}
                {...props}
                authenticated={authenticated}
            />
            <Authenticated
                exact
                path={'/opening-list'}
                component={OpeningList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/media-list'}
                component={MediaList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/challenges-list'}
                component={ChallengesList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/people-list'}
                component={PeopleList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/publications-list'}
                component={PublicationList}
                authenticated={authenticated}
                {...props}
            /> 
            <Authenticated
                exact
                path={'/researcharea-list'}
                component={ReseachAreaList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/documents-list'}
                component={DocumentsList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/individuals-list'}
                component={IndividualList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/Expert-list'}
                component={ExpertList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/academics-list'}
                component={AcademicsList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/industry-list'}
                component={IndustryList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/partner-list'}
                component={PartnerList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/banner-list'}
                component={BannerList}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={'/tool-list'}
                component={ToolList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/datasets-list'}
                component={DatasetsList}
                authenticated={authenticated}
                {...props}
            />
        </div>
    );
};

export default RoutesComponent;
