export const ApiConfig = {
 
    login: "login",
    contactUs: 'contact-us',
    insertOrUpdateOpening: 'insertOrUpdateOpening',
    getOpeningList: 'getOpeningList',
    enableDisableOpening: 'enableDisableOpening',
    getOpeningsList: 'getOpeningsList',
    getMediaList: 'getMediaList',
    insertORUpdateMedia: 'insertORUpdateMedia',
    enableDisableMedia: 'enableDisableMedia',
    getDesignationList: 'getDesignationList',
    insertOrUpdatePeople: 'insertOrUpdatePeople',
    getPeopleList: 'getPeopleList',
    enableDisableUser: 'enableDisableUser',
    getPublicationList: 'getPublicationList',
    insertORUpdatePublication: 'insertORUpdatePublication',
    enableDisablePublication: 'enableDisablePublication',
    insertORUpdateChallengers : 'insertORUpdateChallengers',
    getChallengersList : 'getChallengersList',
    getChallengersById : 'getChallengersById',
    enableDisableChallenges : 'enableDisableChallenges',
    getResearchAreaList: 'getResearchAreaList',
    getResearchAreasList: 'getResearchAreasList',
    insertOrUpdateResearchAreas: 'insertOrUpdateResearchAreas',
    enableDisableResearchAreas: 'enableDisableResearchAreas',
    getContributorsList:'getContributorsList',
    enableDisableContributorsAcademics:'enableDisableContributorsAcademics',
    insertORUpdateContributorsAcademics :'insertORUpdateContributorsAcademics',
    insertORUpdateContributorsIndividual: 'insertORUpdateContributorsIndividual',
    insertORUpdateContributorsExperts: 'insertORUpdateContributorsExperts',
    insertORUpdateContributorsIndustry:'insertORUpdateContributorsIndustry',
    enableDisableContributorsIndividual:'enableDisableContributorsIndividual',
    enableDisableContributorsIndustry:'enableDisableContributorsIndustry',
    enableDisableContributorsExperts: 'enableDisableContributorsExperts',
    getDocumentsList: 'getDocumentsList',
    insertORUpdateDocuments: 'insertORUpdateDocuments',
    enableDisableDocuments: 'enableDisableDocuments',
    enableDisablePartner:'enableDisablePartner',
    getPartnerList:'getPartnerList',
    insertORUpdatePartner:'insertORUpdatePartner',
    insertOrUpdateBanner: 'insertOrUpdateBanner',
    getBannerList: 'getBannerList',
    enableDisableBanner: 'enableDisableBanner',
    getToolsList: 'getToolsList',
     insertORUpdateTools: 'insertORUpdateTools',
      enableDisableTools: 'enableDisableTools',
      getdatasetsList: 'getdatasetsList',
      insertORUpdatedatasets: 'insertORUpdatedatasets',
       enableDisabledatasets: 'enableDisabledatasets',
      
      
    
    // ============URLs================
    //Prod Server
       baseUrl: "https://syspin.iisc.ac.in/api/v1/",
       url: 'https://syspin.iisc.ac.in',

    //Test Server
   // baseUrl: 'http://13.127.205.13:5005/v1/',
   // url: 'http://13.127.205.13',

};

export default ApiConfig;
