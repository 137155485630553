import React, { useReducer, useMemo, useEffect, useContext, useState } from "react";
import PropTypes from 'prop-types';
import AuthReducer, {
    initialState,
    LOGGED_IN,
    LOGGED_OUT,
} from '../reducers/AuthReducer';
import { asyncStoreClear, asyncStoreGet, asyncStoreSave } from "../Utils/asyncStorage";
import debugLogger from "../Utils/debugLogger";
import UserService from "../../api/services/UserService";

export const LOGIN_KEY = 'is_login';
export const USER_ID = "userId";
export const keys = [LOGIN_KEY];

const initialContext = initialState;

const AuthContext = React.createContext(initialContext);
console.log("^^",AuthContext)
export function AuthProvider(props) {
  //  const [isLogin, setIsLogin] = useState(false);
    const [authState, dispatch] = useReducer(AuthReducer, initialState || {});

    useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            try {
                //GET TOKEN && USER
                let isAuth = await asyncStoreGet(LOGIN_KEY);
                debugLogger('AuthProvider: bootstrapAsync token:', isAuth);
                //&& user !== null
                if (isAuth !== null) {
                    await login({ isAuth, initialiseUser: true });
                } else await logout();
            } catch (err) {
                throw err.error;
            }
        };

        bootstrapAsync();
    }, []);


    // Handle Login
    const login = async data => {
        let isAuth = '';
        try {
            let response = '';
            //STORE DATA
            if (data.initialiseUser) {
                debugLogger('sas', props);
            } else {
                response = await UserService.login(data);
                console.log("res test",response)
                if (response) {
                    response.text().then(async (res) => {
                        let result = JSON.parse(res);
                        if (result.code === 0) {
                            if (result.data) {
                                await asyncStoreSave(LOGIN_KEY, true);
                                await asyncStoreSave(USER_ID, result.data.userId);
                            }
                        }
                    })
                } else {
                    return response;
                }
            }
            //DISPATCH TO REDUCER
            dispatch({ type: LOGGED_IN });
            return response;
        } catch (err) {
            debugLogger('login error: ', err);
            await logout();
            throw err.error;
        }
    };


    const logout = async () => {
        try {
            //REMOVE DATA
            await asyncStoreClear();
            //DISPATCH TO REDUCER
            dispatch({ type: LOGGED_OUT });
        } catch (err) {
            debugLogger('logout error: ', err);
            throw err.error;
        }
    };


    const value = useMemo(
        () => [
            authState,
            {
                login,
                logout,
            },
        ],
        [authState],
    );

    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    );
}

const useAuth = () => useContext(AuthContext);

export { AuthContext, useAuth };

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};


// export const AuthContext = React.createContext({});

// export const AuthProvider = ({ children }) => {
//     const [isLogin, setIsLogin] = useState(false);

//     React.useEffect(() => {
//         handleAuthenticated();
//     }, []);

//     const handleAuthenticated = async () => {

//         const isAuthenticated = await localStorage.getItem("isLogin");
//         if (isAuthenticated === "true") {
//             setIsLogin(true);
//         } else {
//             await localStorage.setItem("isLogin", false);
//             setIsLogin(false);
//         }
//     };

//     return (
//         <AuthContext.Provider
//             value={{
//                 isLogin,
//                 handleAuthenticated: handleAuthenticated,
//             }}
//         >
//             {children}
//         </AuthContext.Provider>
//     );
// };
