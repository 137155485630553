import bgPath from '../assets/bg-path.png';
import circlePath from '../assets/circle-path.png';
import iiscLogo from '../assets/iisc-logo.png';
import spareLab from '../assets/spare-lab.png';
import mobilePath from '../assets/mobile-path.png';
import logo from '../assets/logo.png';
import PartnerImg from '../assets/partnerImg.png';
import FFLogo from '../assets/FF-Logo.png';
import GGICImg from '../assets/Giz-German-India-Cooperation.png';
import EmailImg from '../assets/email.png';
import LocationImg from '../assets/location.png';
import DownArrow from '../assets/down-arrow.png';
import UpArrow from '../assets/up-arrow.png';
import MenuImg from '../assets/menu.png';
import CloseImg from '../assets/close.png';
import Menulogo from '../assets/Menulogo.png';
import Syspin from '../assets/Syspin.png';
import Dash from '../assets/Dash.png';
import IISCImg from '../assets/IISC.png';
import rightImg from '../assets/right-arrow.png'; 
import rightarrow from '../assets/right-arrow.png';
// import galleryImg1 from '../assets/Gallery/gallery1.png'
// import galleryImg2 from '../assets/Gallery/gallery2.png'
// import galleryImg3 from '../assets/Gallery/gallery3.png'
// import galleryImg4 from '../assets/Gallery/gallery4.png'
// import galleryImg5 from '../assets/Gallery/gallery5.png'
// import galleryImg6 from '../assets/Gallery/gallery6.png'
// import galleryImg7 from '../assets/Gallery/gallery7.png'
// import galleryImg8 from '../assets/Gallery/gallery8.png'
// import galleryImg9 from '../assets/Gallery/gallery9.png'
// import galleryImg10 from '../assets/Gallery/gallery10.png'
// import galleryImg11 from '../assets/Gallery/gallery11.png'
// import galleryImg12 from '../assets/Gallery/gallery12.png'
// import galleryImg13 from '../assets/Gallery/gallery13.png'
// import galleryImg14 from '../assets/Gallery/gallery14.png'
// import galleryImg15 from '../assets/Gallery/gallery15.png'
    // import video  from '../assets/Gallery/Video/Syspin_video.mp4'
// import bhashiniimg1 from '../assets/Gallery/Bhashini/Bhashini_1.jpg'
// import bhashiniimg2 from '../assets/Gallery/Bhashini/Bhashini_2.jpg'
// import bhashiniimg3 from '../assets/Gallery/Bhashini/Bhashini_Office.jpg'
// import gizmeeting_1 from '../assets/Gallery/FunderMeeting/Funding_team.jpg'

import spiremap from '../assets/staticmap.png'

const application_contants = {
    BGPATHIMG: bgPath,
    CIRCLEPATHIMG: circlePath,
    IISCLOGOIMG: iiscLogo,
    SPARELABIMG: spareLab,
    MOBILEPATH: mobilePath,
    LOGOIMG: logo,
    PARTNERIMG: PartnerImg,
    FFLOGO:FFLogo,
    GGICIMG: GGICImg,
    EMAILIMG: EmailImg,
    LOCATIONIMG: LocationImg,
    DOWNARROW: DownArrow,
    UPARROW: UpArrow,
    MENUIMG: MenuImg,
    CLOSEIMG: CloseImg,
    MENULOGO: Menulogo,
    SYSPIN: Syspin,
    DASH: Dash,
    IISCImg: IISCImg,
    rightImg: rightImg,
    RIGHTARROW: rightarrow,
  //   galleryImg1 : galleryImg1,
  // galleryImg2 : galleryImg2,
  // galleryImg3 : galleryImg3,
  // galleryImg4 : galleryImg4,
  // galleryImg5 : galleryImg5,
  // galleryImg6 : galleryImg6,
  // galleryImg7 : galleryImg7,
  //  galleryImg8 : galleryImg8,
  //  galleryImg9 : galleryImg9,
  //  galleryImg10 : galleryImg10,
  //  galleryImg11 : galleryImg11,
  //  galleryImg12 : galleryImg12,
  //  galleryImg13 : galleryImg13,
  //  galleryImg14 : galleryImg14,
  //  galleryImg15 : galleryImg15,
  //  bhashiniimg1 : bhashiniimg1,
  //  bhashiniimg2 : bhashiniimg2,
  //  bhashiniimg3 : bhashiniimg3,
  //  gizmeeting_1 : gizmeeting_1,
        //  video : video, 

  spiremap : spiremap,
  
    LANDING: '/',
    LOGIN: '/login',
    ABOUTUS: '/aboutUs',
    PEOPLES: '/people-list',
    PARTNERLIST: '/partner-list',
    BANNERLIST: '/banner-list',
    OPENINGLIST: '/opening-list',
    CHALLENGESLIST: '/challenges-list',
    REASEARCHAREA : '/researcharea-list',
    DOCUMENTS : '/documents-list',
    MEDIA : '/Media-list',
    CONTRIBUTORS : '/Contributors-list',
    PUBLICATION : '/publications-list',
    INDIVIDUALS : '/individuals-list',
    EXPERTS : '/Expert-List',
    ACADEMICS : '/academics-list',
    INDUSTRY : '/industry-list',
    BANNER : '/banner-list',
    DATASETS: '/datasets-list',
    TOOLLIST: '/tool-list',
    MOBILE_BREAKPOINT: 1023,
};

export default application_contants;