const ColorLineData = [
    {
        code: '#E50000',
    },
    {
        code: '#C02900',
    },
    {
        code: '#F78058',
    },
    {
        code: '#00B8BD',
    },
    {
        code: '#00EBE9',
    },
    {
        code: '#23B6FF',
    },
    {
        code: '#4C96E9',
    },
    {
        code: '#002690',
    },
    {
        code: '#000C65',
    },
];

export default ColorLineData;