import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";


const PublicRoute = ({
    authenticated,
    afterLoginPath,
    component,
    path,
    exact,
    ...rest
}) => {
    return (
        <Route
            path={path}
            exact={exact}
            render={(props) =>
                !authenticated ? (
                    React.createElement(component, {
                        ...props,
                        ...rest,
                        authenticated,
                    })
                ) : (
                    <Redirect to={afterLoginPath || "/opening-list"} />
                )
            }
        />


    )
}

PublicRoute.defaultProps = {
    path: "",
    exact: false,
    afterLoginPath: null,
    authenticated: false,
};

PublicRoute.propTypes = {
    authenticated: PropTypes.bool,
    component: PropTypes.func.isRequired,
    afterLoginPath: PropTypes.string,
    path: PropTypes.string,
    exact: PropTypes.bool,
};

export default PublicRoute;
