import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import MenuData from "../../Utils/json/menuData";
import MegaMenuData from '../../Utils/json/MegaMenuData'
import application_contants from "../../contants/application_contants";
import LineColor from "../LineColor/LineColor";
import { HashLink } from 'react-router-hash-link';


const ExternalHeader = (props) => {
    const [width] = useWindowSize();
    const [isToggle, setIsToggle] = useState(false);
    const [isMegaMenu, setIsMegaMenu] = useState(true);
    const [isAnimate, setIsAnimate] = useState(false);

    const controlHeader = () => {
        // if (window.scrollY > 100) {
        //     setIsAnimate(false)
        // }
        // else {
        //     setIsAnimate(true)
        // }
    }

    const handleToggle = () => {
        setIsToggle(!isToggle);
    };
    const handleLink = async (link, name) => {
        props.history.push(`${link}`);
        setIsToggle(!isToggle);
    }
    const handleMegaMenuLink = async () => {
        setIsMegaMenu(!isMegaMenu)
        setIsAnimate(!isAnimate);
    }
    const handleOnMouseLeave = () => {
        setIsMegaMenu(!isMegaMenu)
        setIsAnimate(!isAnimate);
    }
    let menuref = useRef()
    React.useEffect(() => {
        let handler = (event) => {
            if (!menuref.current.contains(event.target)) {
                setIsAnimate(isAnimate);

            }
        }
        window.addEventListener('scroll',
            controlHeader)

        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener('mousedown', handler)
            window.removeEventListener('scroll',
                controlHeader)
            setIsAnimate()
        }


    }, [])

    return (
        <div className=''>
            <div className="line-bt-container">
                <LineColor />
            </div>
            <div className={`header-container `}>
                {width >= application_contants.MOBILE_BREAKPOINT ?
                    <div className="d-flex justify-content-between menu-container">
                        <div className="syspin-logo">
                            <Link to={'/'}>
                                <img src={application_contants.SYSPIN} alt="" className="cursor-pointer syspin-logo align-center" />
                            </Link>
                        </div>
                        <div className="d-flex align-items-center mt-2 ml-2 position">
                            <div>
                                <Link to={'/'}>
                                    <img src={application_contants.MENULOGO} alt="" className="cursor-pointer logo-icon" />
                                </Link>
                            </div>
                        </div>


                        <div onClick={() => handleMegaMenuLink()}>
                            <img src={application_contants.MENUIMG} alt="" className="cursor-pointer menu-icon " />
                        </div>

                        {/* <div className="d-flex align-items-end"> */}
                        {/* {MegaMenuData.map(({ id, selected, link, name, isLink }) => (
                            <div className="custom-link--position" key={`menu-${id}`}>
                                <div key={id} onMouseOver={() => handleOnMouseEnter()} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                    className={`custom-link ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                    {name}
                                </div>
                            </div>
                        ))} */}
                        {/* </div> */}
                        {/* {true ? */}
                        <div ref={menuref} className={`mega-menu-container d-flex ${isAnimate ? `animate__animated animate__fadeInDown mega-menu--show` : 'animate__animated animate__fadeInUp mega-menu--hide'} `} >
                            <div>
                                <div>
                                    <Link to={'/'}>
                                        <img src={application_contants.MENULOGO} alt="" className="cursor-pointer logo-icon" />
                                    </Link>
                                </div>
                                <div className="">
                                    <Link to={'/'}>
                                        <img src={application_contants.SYSPIN} alt="" className="cursor-pointer syspin-logo1 d-flex" />
                                    </Link>
                                </div>

                            </div>

                            <div className="d-flex pt-2">
                                {MegaMenuData.map(({ id, selected, link, name, isLink, menu, isHashlink }) => (
                                    <div className="mega-menu-link--position" key={`menu-${id}`}>
                                        {isLink ?
                                            <div>

                                                <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                    className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                    {name}
                                                </div>
                                                {menu.length > 0 && menu.map((it, index) => (
                                                    <div onClick={() => handleLink(it.link, it.name)} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                        className={`mega-menu-link  ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>
                                                        {it.name}
                                                    </div>
                                                ))}
                                            </div> :
                                            <div>
                                                <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                    className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                    {name}
                                                </div>
                                                {menu.length > 0 && menu.map((it, index) => (
                                                    // <div onClick={() => handleLink(it.link, it.name)} key={index}>
                                                    //     {it.name}
                                                    // </div>
                                                    <HashLink smooth to={it.link} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                        className={`mega-menu-link  ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>{it.name}</HashLink>
                                                ))}
                                            </div>
                                        }

                                    </div>
                                ))}
                            {/* <div className="line-bt-container1"> 
                                   <LineColor /> 
                                </div>  */}
                            </div>
                            <div className="close-icon--container pt-1">
                                <div onClick={() => handleOnMouseLeave()}>
                                    <img src={application_contants.CLOSEIMG} alt="" className="cursor-pointer close-icon" />
                                </div>

                            </div>

                        </div>
                        {/* : null} */}
                    </div>
                    :
                    <div ref={menuref} className="pt-3 d-flex justify-content-between full-width align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <Link to={'/'}>
                                        <img src={application_contants.MENULOGO} alt="" className=" cursor-pointer logo-icon" />
                                    </Link>
                                </div>
                            </div>
                            <div className="syspin-logo">
                                <Link to={'/'}>
                                    <img src={application_contants.SYSPIN} alt="" className="cursor-pointer syspin-logo align-center" />
                                </Link>
                            </div>
                        </div>
                        <div onClick={() => handleToggle()}>
                            <img src={application_contants.MENUIMG} alt="" className="cursor-pointer  menu-icon" />
                        </div>
                        {isToggle ?
                            <div className="mega-menu-container">
                                <div>
                                    <div>
                                        <Link to={'/'}>
                                            <img src={application_contants.MENULOGO} alt="" className="cursor-pointer logo-icon mega-menu-logo" />
                                        </Link>
                                    </div>
                                    <div className="syspin-logo">
                                        <Link to={'/'}>
                                            <img src={application_contants.SYSPIN} alt="" className="cursor-pointer syspin-logo align-center" />
                                        </Link>
                                    </div>
                                    {/* <Link to={'/'}>
                                <div className="pt-3">
                                    <p className="p-large section-title title-white nunitoSans-Bold align-left fw-800">REcognizing SPeech in INdian languages</p>
                                </div>
                                </Link> */}
                                </div>
                                <div className="pt-5 pb-3">
                                    {MegaMenuData.map(({ id, selected, link, name, isLink, menu, isHashlink }) => (
                                        <div className="mega-menu-link--position" key={`menu-${id}`}>
                                            {isLink ?
                                                <div className="pb-2">
                                                    <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                        className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                        {name}
                                                    </div>
                                                    {menu.length > 0 && menu.map((it, index) => (
                                                        <div onClick={() => handleLink(it.link, it.name)} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                            className={`mega-menu-link pl-5 ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>
                                                            {it.name}
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                // null
                                                <div className="pb-2">
                                                    <div key={id} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                        className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                        {name}
                                                    </div>
                                                    {menu.length > 0 && menu.map((it, index) => (
                                                        <HashLink smooth to={it.link} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                            className={`mega-menu-link pl-5 ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>{it.name}</HashLink>
                                                    ))}
                                                </div>
                                            }

                                        </div>
                                    ))}
                                    <div className="line-bt-container">
                                        <LineColor />
                                    </div>
                                </div>
                                <div className="close-icon--container">
                                    <div onClick={() => handleToggle()}>
                                        <img src={application_contants.CLOSEIMG} alt="" className="cursor-pointer close-icon" />
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>}
            </div>
        </div>
    )
}

export default withRouter(ExternalHeader);