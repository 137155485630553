import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const PeopleService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdatePeople } = ApiConfig;

        const url = baseUrl + insertOrUpdatePeople;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            peopleId : data.peopleId,
            fullName : `${data.name}`,
            photoURL : `${data.photo}`,
            designationId : data.designationId,
            description : `${data.description}`,
            link: `${data.link}`,
            typeId: data.typeId,
        });

        const peopleServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_peopleService",
            true
        );
        return peopleServicePromise;
    },

    getPeopleList: async () => {
        const { baseUrl, getPeopleList } = ApiConfig;

        const url = baseUrl + getPeopleList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const peopleServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_people",
            true
        );
        return peopleServicePromise;
    },

    enableDisableUser: async (data) => {
        const { baseUrl, enableDisableUser } = ApiConfig;

        const url = baseUrl + enableDisableUser;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            peopleId : data.peopleId,
            status : `${data.status}`,
        });

        const enableDisableUserServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableUser",
            true
        );
        return enableDisableUserServicePromise;
    },

};

export default PeopleService;
