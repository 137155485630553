import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const ChallengersService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertORUpdateChallengers } = ApiConfig;

        const url = baseUrl + insertORUpdateChallengers;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId : data.userId,
            title: data.title,
            id : data.id,
            detailTitle : data.detailTitle,
            url : data.url,
            toDate : data.toDate,
            fromDate : data.fromDate,
            description : `${data.description}`,
            imagesUrl : `${data.imagesUrl}`,
            date: `${data.date}`,
            keyTakeAways: `${data.keyTakeAways}`,
            websiteSource: `${data.websiteSource}`,
        });

        const challengersServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_challengersService",
            true
        );
        return  challengersServicePromise;
    },

    getChallengersList: async () => {
         const { baseUrl, getChallengersList } = ApiConfig;

        const url = baseUrl + getChallengersList;
 
        const headers = new Headers({
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
        });

         const challengersServicePromise = await Fetch(
            url,
            "GET",
            headers 
        );
        return challengersServicePromise;
    },

    enableDisableChallenges: async (data) => {
        const { baseUrl, enableDisableChallenges } = ApiConfig;

        const url = baseUrl + enableDisableChallenges;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id : data.id,
            status : `${data.status}`,
        });

        const enableDisableChallengesServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableChallengers",
            true
        );
        return enableDisableChallengesServicePromise;
    },

};

export default ChallengersService;
