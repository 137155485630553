// apiCaller.js

async function callApi(selectedTab, selectedCheckbox, selectedRadio, text, setLoading, setAudioUrl, setModalVisible) {
    setLoading(true); // Show loading indicator
    const url = `https://xckszodvuk.execute-api.ap-south-1.amazonaws.com/api/${selectedTab}-${selectedCheckbox}-${selectedRadio}-audio`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: text }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
    const audioUrl = data.audioUrl;
    setAudioUrl(audioUrl);
  } catch (error) {
    console.error('Error generating audio:', error);
  } finally {
    setLoading(false); // Hide loading indicator on success or error
    setModalVisible(true);
  }
}  
  export default callApi;
  

